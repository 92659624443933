import React from 'react'
import { Button } from 'reactstrap'
import Draggable from 'react-draggable';

export default function DailogNew(props) {
    return (
        props.isOpen ?
            <Draggable>
                <div
                    className={`${props.modalStyleType} modal-dialog modal-confirm modal-sm custom-modal sessionPopup drag-box`}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            {props.header}
                        </div>
                        <div className="modal-body pb-3 pt-0">
                            {props.content}
                        </div>
                        <div className="modal-footer">
                            {
                                props.config ?
                                    <>
                                        <Button className='btn-outline-primary modal-btn' onClick={props.decline}>No</Button>{' '}
                                        <Button className='modal-right-btn modal-btn' onClick={() => { props.accept() }}>Extend</Button>
                                    </>
                                    :
                                    <Button className='btn-outline-primary modal-btn' onClick={props.decline}>OK</Button>
                            }
                        </div>
                    </div>
                </div>
            </Draggable>
            :
            <></>
    )
}


