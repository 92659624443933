import React, { useState } from "react";

const CalendarHeader = ({
  calendarHeaderDate,
  activeView,
  searchText,
  searchTextResult,
  changeCalendarView,
}) => {
  const [activateSearchBar, setActivateSearchBar] = useState(false);

  return (
    <div className="calendar-header">
      <div className="calendar-header-left">
        <div className="d-flex mt-1 ml-2">
          <button className="prev-btn" id="calendar-prev-btn">
            <span
              style={{ color: "#fff" }}
              className="material-icons-outlined icon-fs-20"
            >
              chevron_left
            </span>
          </button>
          <button className="next-btn ml-3" id="calendar-next-btn">
            <span
              style={{ color: "#fff" }}
              className="material-icons-outlined icon-fs-20"
            >
              chevron_right
            </span>
          </button>
        </div>
        <p id="calendar-title">{calendarHeaderDate}</p>
      </div>

      <div className="calendar-middle-button calendar-header-middle">
        <button
          className={
            activeView === "timeGridDay"
              ? "calendar-btn-selected"
              : "calendar-btn"
          }
          onClick={() => changeCalendarView("timeGridDay")}
        >
          Day
        </button>
        <button
          className={
            activeView === "timeGridWeek"
              ? "calendar-btn-selected"
              : "calendar-btn"
          }
          onClick={() => changeCalendarView("timeGridWeek")}
        >
          Week
        </button>
        <button
          className={
            activeView === "dayGridMonth"
              ? "calendar-btn-selected"
              : "calendar-btn"
          }
          onClick={() => changeCalendarView("dayGridMonth")}
        >
          Month
        </button>
      </div>

      <div className="calendar-header-right d-flex">
        <div
          className={
            activateSearchBar ? "activate-search-bar" : "calendar-search-bar"
          }
        >
          <input
            className="search-input"
            type="search"
            placeholder="Search events..."
            value={searchText}
            onChange={(e) => searchTextResult(e)}
          />
        </div>
        <div
          onClick={() => setActivateSearchBar(!activateSearchBar)}
          className="calendar-custom-button"
        >
          <span
            style={{ color: "#fff" }}
            className="material-icons-outlined icon-fs-20"
          >
            search
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalendarHeader;
