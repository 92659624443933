import React, { Component } from "react";
import EcosystemDetailsCategories from "../Containers/EcosystemDetailsCategories";
import EcosystemDetailsContent from "../Containers/EcosystemDetailsContent";
import { EcosystemServices } from "../Services/Ecosystem";
import { connect } from "react-redux";
import {
  categories,
  ecosystemDetails,
  ecosystemContent,
} from "../Store/Actions/EcosystemDetails";
import constants from "../Utils/constants";
import { activeMenuTab } from "../Store/Actions/User";
import { BoardServices } from "../Services/Board";
import { boards } from "../Store/Actions/Board";
import PinToBoard from "../Containers/PinToBoard";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import HelpModal from "./HelpModal";
import Slugify from "../Utils/Slugify";
import DocumentTitle from "../Utils/DocumentTitle";
import DailogNew from "../Components/DailogNew";
import { CompanyServices } from "../Services/Company";
import ExportProjectDashboardFileFormatter from "../Utils/ExportProjectDashboardFileFormatter";
import Copy from "../Utils/Copy";
import Toast from "../Utils/Toast";
import { formatRoute } from "react-router-named-routes";
import gaEvent from "../Utils/GAEvents";
import AclAction from "../Components/ACL/AclAction";
import CompanySegmentFiles from "../Containers/CompanySegmentFiles";
import AuthorsPopover from "../Components/AuthorsPopover";

class EcosystemDetails extends Component {
  state = {
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    dailogModalStyleType: "",
    pinning_sub_category_id: "",
    ecosystemInnerSidebarOpen: true,
    category_id: null,
    sub_category_id: null,
    documentModal: false,
    pinning_sub_category_name: "",
  };

  toggleCustomerStudyInnerSidebar = () => {
    this.setState({
      ecosystemInnerSidebarOpen: !this.state.ecosystemInnerSidebarOpen,
    });
  };

  componentWillMount() {
    let search = String(this.props.location.search);
    this.setState({
      content_id: Number(
        search.substring(search.indexOf("=") + 1, search.length)
      ),
    });
  }

  componentDidMount() {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE);
    let ecosystemId = this.props.match.params.id;
    this.initContent(ecosystemId);
    this.addMostView(ecosystemId);
    this.callRecentlyView(ecosystemId);
    gaEvent.init({
      moduleName: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
      id: ecosystemId,
      page_url:
        this.props.history.location.pathname +
        this.props.history.location.search,
    });
  }

  addMostView = async (ecosystemId) => {
    await CompanyServices.addMostViewsOfReportsforModules(
      "ecosystem",
      ecosystemId
    ).catch((error) => {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  callRecentlyView = async (ecosystemId) => {
    let body = {
      table_name: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
      table_id: parseInt(ecosystemId),
    };
    await EcosystemServices.addRecentlyViewDetails(body).catch((error) => {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  initContent = async (ecosystemId) => {
    let contents = [];
    await EcosystemServices.getEcosystemContent(ecosystemId)
      .then((res) => {
        let response = res && res.data && res.data.data;

        let responseMeta = response?.meta ? JSON.parse(response.meta) : null;
        let responseCategories =
          response?.categories
            ?.map((res) => {
              let category = null;
              if (responseMeta) {
                category = responseMeta.find((k) => k.categoryId === res.id);
              }

              res.is_selected = true;
              res.is_open = true;
              if (res.subcategories) {
                res.subcategories = res.subcategories
                  .map((res_sub) => {
                    let subCategoryData = null;
                    if (category) {
                      subCategoryData = category.subCategories.find(
                        (k) => k.subCategoryId === res_sub.id
                      );
                    }

                    res_sub.is_selected = true;
                    res_sub.show_actions = false;
                    if (res_sub.contents) {
                      let c = res_sub.contents.map((c) => ({
                        ...c,
                        category_id: res.id,
                      }));
                      contents.push(...c);
                    }
                    if (category && subCategoryData) {
                      return { ...res_sub, ...subCategoryData };
                    } else {
                      return { ...res_sub };
                    }
                  })
                  .sort((a, b) => a.subCategoryIndex - b.subCategoryIndex);
              }
              if (category) {
                delete category.subCategories;
                return { ...res, ...category };
              } else {
                return { ...res };
              }
            })
            .sort((a, b) => a.categoryIndex - b.categoryIndex) ?? [];

        this.props.categories(responseCategories);
        this.props.ecosystemContent(contents);
      })
      .catch((error) => {
        this.props.categories([]);
        this.props.ecosystemContent([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
    await EcosystemServices.getEcosystemDetails(ecosystemId)
      .then((res) => {
        let response = res && res.data;
        // let segmentId = response.ecosystem.segment_id;
        // localStorage.setItem("segmentId", JSON.stringify(segmentId));
        // localStorage.setItem("isBenchmark", true);
        this.props.ecosystemDetails(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  componentWillUnmount = () => {
    this.props.categories([]);
    this.props.ecosystemDetails({});
    this.props.ecosystemContent([]);
    this.props.boards([]);
    gaEvent.trigger();
    this.setState({
      category_id: null,
      sub_category_id: null,
    });
  };

  getTotalBoards = async (sub_category_id, type) => {
    const mapping_id =
      this.props.ecosystem_details.ecosystemDetails.ecosystem.id;
    await BoardServices.getTotalBoards(mapping_id, sub_category_id, type)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let finalresponse = [];
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        finalresponse.push({ id: "other", name: "+ Create New Board" });
        finalresponse.push(...response);
        finalresponse.forEach((res) => {
          res.is_selected = res.id === sub_category_id ? true : false;
        });
        this.props.boards(finalresponse);
      })
      .catch((error) => {
        this.props.boards([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  togglePinBoardActions = (category_id, sub_category_id) => {
    let original_categories = [...this.props.ecosystem_details.categories];
    original_categories.forEach((res) => {
      if (res.id === category_id) {
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (res_sub.id === sub_category_id)
              res_sub.show_actions = !res_sub.show_actions;
            else res_sub.show_actions = false;
          });
        }
      }
    });
    this.props.categories(original_categories);
  };

  addToBoardHandleSubCategory = async (
    category_id,
    sub_category_id,
    sub_category_name
  ) => {
    await this.getTotalBoards(sub_category_id, "ecosystem");
    let boards = [...this.props.board.boards];
    boards = boards.map((b) => ({ ...b, is_selected: false }));
    this.props.boards(boards);
    this.setState({
      pinning_category_id: category_id,
      pinning_sub_category_id: sub_category_id,
      pinning_sub_category_name: sub_category_name,
    });
  };

  closeBoardModal = () => {
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  successAddToBoard = () => {
    this.togglePinBoardActions(
      this.state.pinning_category_id,
      this.state.pinning_sub_category_id
    );
    Toast(constants.MY_BOARDS.PIN.SUCCESS, "success");
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  handleDownload = async () => {
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? this.props.ecosystem_details.ecosystemDetails.ecosystem
        : [];
    let original_categories = [...this.props.ecosystem_details.categories];
    let selected_subcategories = [];
    original_categories.forEach((c) => {
      c.subcategories.forEach((s) => {
        if (s.is_selected === true) selected_subcategories.push(s.id);
      });
    });
    let request = {
      mappingId: ecosystemDetails.id,
      type: "ecosystem",
      source: "content",
      ext: "pdf",
      subcategoryIds: selected_subcategories,
      financialMetrics: {},
    };
    await CompanyServices.downloadContent(request)
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], { type: "application/pdf" });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let fileName = ExportProjectDashboardFileFormatter(
              ecosystemDetails.name
            );
            link.setAttribute("download", `${fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW, {
              fileName: fileName,
              fileFormat: `pdf`,
              moduleName: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
              id: this.props.match?.params?.id,
              pageUrl:
                this.props.history.location.pathname +
                this.props.history.location.search,
            });
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  copyHandler = (author) => {
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? JSON.parse(
            JSON.stringify(this.props.ecosystem_details.ecosystemDetails)
          )
        : {};
    let authors = ecosystemDetails.ecosystem.authors;
    let authorIndex = authors.findIndex((a) => a.id === author.id);
    ecosystemDetails.ecosystem.authors[authorIndex].copied = true;
    this.props.ecosystemDetails(ecosystemDetails);
    Copy(author.email);
    setTimeout(() => {
      let cd = {
        ecosystem: JSON.parse(JSON.stringify(ecosystemDetails.ecosystem)),
      };
      cd.ecosystem.authors[authorIndex].copied = false;
      this.props.ecosystemDetails(cd);
    }, 2000);
  };

  goToCompanyDashboard = (access, data) => {
    if (access) {
      this.props.history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, {
          id: data.id,
        }),
      });
    } else {
      let dailogModalHeader = (
        <>
          <h3 className="text-darkblue2 font-weight-bold">
            {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
          </h3>
        </>
      );
      let dailogModalContent = (
        <>
          <div className="noaccess-body">
            <h2 className="font-weight-semibold mb-0">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
            </h2>
          </div>
        </>
      );
      this.setState({
        dailogModal: true,
        dailogModalHeader: dailogModalHeader,
        dailogModalContent: dailogModalContent,
        dailogModalConfig: { type: false, id: null },
        dailogModalStyleType: "noaccess-modal",
      });
    }
  };

  breadcrumbs = () => {
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? this.props.ecosystem_details.ecosystemDetails.ecosystem
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: ecosystemDetails?.id
          ? ecosystemDetails.name
          : constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  documentModalHandler = () => {
    this.setState({ documentModal: !this.state.documentModal });
  };

  documentModalTrue = () => {
    this.setState({ documentModal: true });
  };

  render() {
    let ecosystemContent =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemContent
        ? this.props.ecosystem_details.ecosystemContent
        : [];
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? this.props.ecosystem_details.ecosystemDetails.ecosystem
        : {};
    if (ecosystemDetails?.id) DocumentTitle(`${ecosystemDetails.name}`);
    let reportName = ecosystemDetails?.name;
    return (
      <div
        className={`${
          this.state.ecosystemInnerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center marketlandscape-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>
            <div className="ml-auto">
              <AuthorsPopover
                authorsData={ecosystemDetails}
                copyHandler={this.copyHandler}
              />
            </div>
            <div>
              <HelpModal category="Ecosystems Details" />
            </div>
            <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.ECOSYSTEM_RESOURCE.TITLE}
              action={constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.DOWNLOAD}
            >
              <aside
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                className="text-left"
              >
                <button
                  id="PopoverLegacy7"
                  className={`icon-btn-primary icon-btn d-flex align-items-center justify-content-center ml-3 ${
                    ecosystemContent.length
                      ? "company_details_download"
                      : "disabled"
                  }`}
                >
                  <span className="material-icons-round icon">more_vert</span>
                </button>
              </aside>
            </AclAction>
          </div>

          <EcosystemDetailsCategories
            toggleEcosystemInnerSidebar={this.toggleEcosystemInnerSidebar}
          />
          <EcosystemDetailsContent
            copyHandler={this.copyHandler}
            handleDownload={this.handleDownload}
            isRelatedInsightsVisible={false}
            togglePinBoardActions={this.togglePinBoardActions}
            addToBoard={this.addToBoardHandleSubCategory}
            goToCompanyDashboard={this.goToCompanyDashboard}
            aclActionVisible={this.props.aclActionVisible ? false : true} //add this for jest testing
            categoryId={this.state.category_id}
            subCategoryId={this.state.sub_category_id}
            contentId={this.state.content_id}
            documentModalTrue={this.documentModalTrue}
          />
          <DailogNew
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
            modalStyleType={this.state.dailogModalStyleType}
          />

          {this.state.pinning_sub_category_id &&
            this.state.pinning_sub_category_id !== null && (
              <PinToBoard
                type={`ecosystem`}
                closeBoardModal={this.closeBoardModal}
                getTotalBoards={this.getTotalBoards}
                pinning_sub_category_id={this.state.pinning_sub_category_id}
                successAddToBoard={this.successAddToBoard}
                subCategoryName={this.state.pinning_sub_category_name}

              />
            )}

          {this.state.documentModal && (
            <CompanySegmentFiles
              reportName={reportName}
              reportType="ecosystem"
              reportId={
                this.props.ecosystem_details.ecosystemDetails.ecosystem.id
              }
              reportSegment={
                this.props.ecosystem_details.ecosystemDetails.ecosystem
                  .segment_id
              }
              documentModal={this.state.documentModal}
              documentModalHandler={this.documentModalHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ecosystem_details: state.ecosystem_details,
    home: state.home,
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    ecosystemDetails: (payload) => {
      dispatch(ecosystemDetails(payload));
    },
    ecosystemContent: (payload) => {
      dispatch(ecosystemContent(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    boards: (payload) => {
      dispatch(boards(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EcosystemDetails);
