import { customer_study_details as DETAILS } from '../Reducers/actionConstants'

const customerStudyCategories = (payload) => {
  return {
    type: DETAILS.CUSTOMERSTUDY_CATEGORIES,
    payload,
  }
}


const customerStudyDetails = (payload) => {
  return {
    type: DETAILS.CUSTOMERSTUDY_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const customerStudyContent = (payload) => {
  return {
    type: DETAILS.CUSTOMERSTUDY_CONTENT,
    payload,
  }
}


export {
  customerStudyCategories,
  customerStudyDetails,
  categories,
  customerStudyContent
}
