import React, { useState } from "react";
import { DropdownList } from "react-widgets";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./VisualizationPreview.scss";
import DownloadIcon from "./DownloadIcon.svg";
const { createSliderWithTooltip } = Slider;

const Range = createSliderWithTooltip(Slider.Range);
function ChartFilters({
  setChartPreview,
  view,
  setView,
  setQuarterToggle,
  quarterToggle,
  setSelectedPeriod,
  selectedPeriod,
  handleChartPeriod,
  selectedRangeValue,
  open,
  setOpen,
  marks,
  chartRange,
  handleRangeChange,
  handleRange,
  selectedChart,
  setSelectedChart,
  handleDownload,
  setShowDropdown,
  showDropdown,
}) {
  const filteredMarks = Object.keys(marks).reduce((acc, key, index) => {
    let length = quarterToggle ? 4 : 1;
    if (index % length === 0 || index === Object.keys(marks).length - 1) {
      acc[key] = marks[key];
    }
    return acc;
  }, {});

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const items = ["PNG", "JPEG"];

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          className='material-icons-outlined add-icon'
          style={{
            color: "#2E6AD2",
            width: "17px",
            fontSize: "17px",
            cursor: "pointer",
          }}
          onClick={(e) => setChartPreview((prev) => !prev)}
        >
          arrow_backward
        </span>
        <span
          style={{
            color: "#2E6AD2",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            marginLeft: "10px",
          }}
        >
          Back to Insight Lens
        </span>
        <div className='view-toggle'>
          <button
            type='button'
            className={`btn ${
              view === 1 ? "btn-primary active-btn" : "not-active-btn"
            }`}
            onClick={() => {
              setView(1);
              setShowDropdown(false);
              setOpen(false);
            }}
          >
            Chart View
          </button>
          <button
            type='button'
            className={`btn ${
              view === 2 ? "btn-primary active-btn" : "not-active-btn"
            }`}
            onClick={() => {
              setView(2);
              setShowDropdown(false);
              setOpen(false);
            }}
          >
            Table View
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          gap: "20px",
        }}
      >
        <div className='back-forth'>
          <div className='mt-2 d-flex align-items-center justify-content-between'>
            <h3
              style={{
                marginRight: "10px",
                color: "#232D42",
                fontSize: "16px",
                fontWeight: "400",
              }}
              className='toggle-unselect'
            >
              Quarterly
            </h3>
            <label className='toggle-switch'>
              <input
                type='checkbox'
                onClick={(e) => {
                  setQuarterToggle(!quarterToggle);
                  setSelectedPeriod(0);
                  setShowDropdown(false);
                  setOpen(false);
                }}
                checked={quarterToggle}
              />
              <span
                className={quarterToggle ? "visalization-slider" : "slider"}
              ></span>
            </label>
            <h3
              style={{
                marginLeft: "10px",
                color: "#232D42",
                fontSize: "16px",
                fontWeight: "400",
              }}
              className='toggle-select'
            >
              Annual
            </h3>
          </div>
        </div>
        <DropdownList
          data={[
            { value: 2, label: "Last 2 year" },
            { value: 1, label: "Last 1 year" },
            { value: 0, label: "All" },
          ]}
          valueField='value'
          textField='label'
          value={selectedPeriod}
          onClick={() => {
            setShowDropdown(false);
            setOpen(false);
          }}
          onChange={(e) => {
            setShowDropdown(false);
            setOpen(false);
            handleChartPeriod(e.value);
          }}
          placeholder='Select Period'
          className='chart-dropdown'
        />

        <button
          onClick={() => {
            setShowDropdown(false);
            setOpen(!open);
          }}
          className='range-button'
        >
          {selectedRangeValue}
        </button>
        {open && (
          <div className='custom-slider'>
            <div className='slider-div'>
              <Range
                min={0}
                max={Object.keys(marks).length - 1}
                marks={filteredMarks}
                value={chartRange}
                onChange={handleRangeChange}
                tipFormatter={(value) => marks[value]}
              />
            </div>
            <button onClick={handleRange} className='slider-save-btn'>
              Save
            </button>
          </div>
        )}
        {view === 1 && (
          <DropdownList
            data={[
              { id: 1, name: "Line Chart", value: "line" },
              { id: 2, name: "Bar Chart", value: "bar" },
              { id: 3, name: "Bubble Chart", value: "bubble" },
              { id: 4, name: "Area Line Chart", value: "area_line" },
              { id: 5, name: "Doughnut Chart", value: "doughnut" },
            ]}
            valueField='value'
            textField='name'
            value={selectedChart}
            onClick={() => {
              setShowDropdown(false);
              setOpen(false);
            }}
            onChange={(e) => {
              setSelectedChart(e.value);
            }}
            placeholder='Select Chart Type'
            className='chart-dropdown'
          />
        )}
        {view === 1 && (
          <img
            src={DownloadIcon}
            alt='download-icon'
            style={{ cursor: "pointer" }}
            onClick={() => {
              toggleDropdown();
              setOpen(false);
            }}
          />
        )}

        {showDropdown && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              right: 0,
              zIndex: "15",
              width: "60px",
            }}
            className='downlaod-dropdown'
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "10px",
              }}
            >
              {selectedChart === "doughnut" ? (
                <span
                  onClick={() => handleDownload("pdf")}
                  className='download-format-btn'
                >
                  PDF
                </span>
              ) : (
                items.map((item) => (
                  <span
                    key={item}
                    onClick={() => handleDownload(item)}
                    className='download-format-btn'
                  >
                    {item}
                  </span>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ChartFilters;
