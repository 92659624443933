import { company_details as DETAILS } from '../Reducers/actionConstants'

const companyCategories = (payload) => {
  return {
    type: DETAILS.COMPANY_CATEGORIES,
    payload,
  }
}

const companySegments = (payload) => {
  return {
    type: DETAILS.COMPANY_SEGMENTS,
    payload,
  }
}

const companyDetails = (payload) => {
  return {
    type: DETAILS.COMPANY_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const companyContent = (payload) => {
  return {
    type: DETAILS.COMPANY_CONTENT,
    payload,
  }
}

const companyFinancialDetails = (payload) => {
  return {
    type: DETAILS.COMPANY_FINANCIAL_DETAILS,
    payload,
  }
}

const companyFinancialRadarChartDetails = (payload) => {
  return {
    type: DETAILS.COMPANY_FINANCIAL_RADAR_CHART_DETAILS,
    payload,
  }
}

export {
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companyFinancialDetails,
  companyFinancialRadarChartDetails
}
