import { Axios } from '../lib/axios'
export const MarketLandscapeServices = {
  getMarketLandscapes,
  getCategories,
  getMarketLandscapeDetails,
  getMarketLandscapeContent
}
function getMarketLandscapes(url) {
  return Axios.get(url)
}
function getCategories(url) {
  return Axios.get('https://jsonblob.com/api/4fc0ad9c-77d8-11ea-aec7-fb5b61093b79')
}
function getMarketLandscapeDetails(marketlandscapeId) {
  return Axios.get(`marketlandscape/${marketlandscapeId}`)
}
function getMarketLandscapeContent(marketlandscapeId) {
  return Axios.get(`marketlandscape/${marketlandscapeId}/content`)
}

