import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const StackedBarChart = (props) => {
  const grid = {
    left: 100,
    right: 100,
    top: 50,
    bottom: 50,
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current, null, {
      renderer: "svg",
    });

    myChart.setOption({
      legend: {
        selectedMode: true,
      },
      grid,
      yAxis: {
        type: "value",
      },
      xAxis: {
        type: "category",
        data: props.data.xAxis,
      },
      series: props.data.series,
    });

    return () => {
      myChart.dispose();
    };
  }, [props]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
};

export default StackedBarChart;
