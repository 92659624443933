import React, { useEffect, useState } from "react";
import DynamicTableComponent from "../../../Components/DynamicTableComponent";
import CustomReactTooltip from "../../../Components/CustomReactTooltip";

function TableView({ data }) {
  const tableHeader = [
    {
      id: "company_name",
      title: "Company",
      // sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textWrap: "nowrap",
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
    {
      id: "segment_name",
      title: "Segment",
      // sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textWrap: "nowrap",
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
    {
      id: "metric_name",
      title: "Metric",
      // sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textWrap: "nowrap",
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
    {
      id: "geo",
      title: "Geo",
      // sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textWrap: "nowrap",
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
    {
      id: "vertical",
      title: "Industry",
      // sortable: true,
      headerStyle: { textTransform: "capitalize", textAlign: "left" },
      rowStyle: {
        textWrap: "nowrap",
        textTransform: "capitalize",
        textAlign: "left",
      },
      visibility: true,
    },
  ];
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const breakWords = (dataArray) =>
    dataArray.toString()?.length ? (
      dataArray.toString()?.length > 15 ? (
        <label
          className='text rct-title mb-0'
          key={dataArray}
          data-for={`tooltip-${dataArray}-active`}
          data-tip={dataArray}
        >
          {dataArray.toString().substring(0, 15) + "..."}
          <CustomReactTooltip
            id={`tooltip-${dataArray}-active`}
            multiline={true}
          />
        </label>
      ) : (
        <label
          className='text rct-title mb-0'
          key={dataArray}
          // data-for={`tooltip-${dataArray}-active`}
          // data-tip={dataArray}
        >
          {dataArray.toString()}
        </label>
      )
    ) : (
      "N/A"
    );

  const handleTableData = () => {
    let tempColumns = [...tableHeader];
    let tempTableData = [];

    for (let i in data) {
      let { company_name, segment_name, metric_name, geo, vertical } = data[i];
      let tempObj = {
        company_name: breakWords(company_name),
        segment_name: breakWords(segment_name),
        metric_name: breakWords(metric_name),
        geo: breakWords(geo),
        vertical: breakWords(vertical),
      };

      data[i].data.map((item) => {
        tempObj[item.qy] = item.value || "N/A";

        if (i === "0") {
          tempColumns.push({
            id: item.qy,
            title: item.qy,
            // sortable: true,
            headerStyle: { textTransform: "capitalize", textAlign: "left" },
            rowStyle: { textTransform: "capitalize", textAlign: "left" },
            visibility: true,
          });
        }
      });
      tempTableData.push(tempObj);
    }
    setColumns(tempColumns);
    setTableData(tempTableData);
  };

  useEffect(() => {
    handleTableData();
  }, [data]);
  
  return (
    <div
      className='w-100 custom-table-block custom-table-block-2 marketforecast-list-table-block sort-table-block th-nowrap center-th-align'
      style={{ marginTop: "20px" }}
    >
      <DynamicTableComponent
        columnData={columns}
        rowData={tableData}
        count={tableData.length}
        paginationClass={"center-pagination custom-pagination-new"}
      />
    </div>
  );
}

export default TableView;
