import React from "react";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import { NavLink } from "reactstrap";
import HelpModal from "../Pages/HelpModal";
import CustomReactTooltip from "./CustomReactTooltip";
const CustomBreadcrumb = (props) => {
  return props.breadcrumbs && props.breadcrumbs.length > 0 ? (
    <div>
      <Breadcrumb className="new-breadcrumb">
        {props.breadcrumbs.map((b) => (
          <BreadcrumbItem
            active={b.is_active ? b.is_active : false}
            key={Math.random()}
          >
            {b.link ? (
              <NavLink tag={RRNavLink} exact to={b.link}>
                {b.title}
              </NavLink>
            ) : b?.title && b?.title?.length > 80 ? (
              <>
                <span data-for={`tooltip-${b.title}`} data-tip={b.title}>
                  {b.title.slice(0, 80)}...
                </span>
                <CustomReactTooltip
                  id={`tooltip-${b.title}`}
                  multiline={true}
                />
              </>
            ) : (
              b?.title
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  ) : null;
};

export default CustomBreadcrumb;
