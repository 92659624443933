import { benchmark_details as DETAILS } from '../Reducers/actionConstants'

const benchmarkCategories = (payload) => {
  return {
    type: DETAILS.BENCHMARK_CATEGORIES,
    payload,
  }
}


const benchmarkDetails = (payload) => {
  return {
    type: DETAILS.BENCHMARK_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const benchmarkContent = (payload) => {
  return {
    type: DETAILS.BENCHMARK_CONTENT,
    payload,
  }
}


export {
  benchmarkCategories,
  benchmarkDetails,
  categories,
  benchmarkContent
}
