import { Axios } from "../lib/axios";

export const EcosystemServices = {
  getEcosystem,
  getEcosystemDetails,
  getEcosystemContent,
  downloadFile,
  addRecentlyViewDetails,
};

function getEcosystem(url) {
  return Axios.get(url);
}

function getEcosystemDetails(id) {
  return Axios.get(`ecosystem/${id}`);
}

function downloadFile(customerStudyId) {
  return Axios.get(`/ecosystem/file/${customerStudyId}`);
}

function getEcosystemContent(ecosystemId) {
  return Axios.get(`ecosystem/${ecosystemId}/content`)
}

function addRecentlyViewDetails(body) {
  return Axios.put(`updateRecentlyView`, body);
}
