import ifvisible from 'ifvisible.js';

class CalculateTimeOnSection {
    constructor() {
        this.start = 0;
        this.end = 0;
        this.timerRef = null;
        this.beforeUnloadRef = null;
        ifvisible.setIdleDuration(7200);   //2hrs idle time
    }

    initializeTimer = () => {
        this.timerRef = setInterval(() => {
            if (ifvisible.now()) {
                this.start++;
            }
        }, 1000);

        // if browser closes or refreshes
        window.onbeforeunload = () => {
            // trigger the react ga event here...
            // globalEventsOnPage(window.location.pathname)
            // change this when coding for section wise events
        };
    }

    getTime = () => {
        const time = this.start;
        this.start = 0;
        clearInterval(this.timerRef);
        return time;
    }

}

const calculateTimeOnSection = new CalculateTimeOnSection();

export default calculateTimeOnSection