import { Axios } from "../lib/axios";
export const HeaderNotificationService = {
  getNotificationList,
  markReadAll,
};
function getNotificationList() {
  return Axios.get("client-platform/notification");
}

function markReadAll(input) {
  return Axios.post("client-platform/markAsRead", input);
}
