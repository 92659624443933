import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/companyDetailsReference'

const companyCategories = DETAILS_REF.companyCategories
const companySegments = DETAILS_REF.companySegments
const companyDetails = DETAILS_REF.companyDetails
const categories = DETAILS_REF.categories
const companyContent = DETAILS_REF.companyContent
const companyFinancialDetails = DETAILS_REF.companyFinancialDetails
const companyFinancialRadarChartDetails = DETAILS_REF.companyFinancialRadarChartDetails

const companyDetailsReducer = combineReducers({
  companyCategories,
  companySegments,
  companyDetails,
  categories,
  companyContent,
  companyFinancialDetails,
  companyFinancialRadarChartDetails
})

export default companyDetailsReducer
