import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { withRouter } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import CancelIcon from "../../../Pages/V2/Visualization/CancelIcon.svg";
import CompanyTab from "../../../Pages/V2/Visualization/CompanyTab";
import SegmentTab from "../../../Pages/V2/Visualization/SegmentTab";
import MetricsTab from "../../../Pages/V2/Visualization/MetricsTab";
import checkPermission from "../../../Utils/Checkpermission";
import gaEvent from "../../../Utils/GAEvents";
import promptFunction from "../../../Utils/PromptFunction";
import HelpModal from "../../../Pages/HelpModal";
import CustomBreadcrumb from "../../../Components/CustomBreadcrumb";
import { VisualizationServicesV2 } from "../../../Services/VisualizationV2";
import { SpecialReportServices } from "../../../Services/SpecialReports";
import constants from "../../../Utils/constants";
import "./VisualizationPreview.scss";
import ChartView from "../../../Pages/V2/Visualization/ChartView";
import { useHistory } from "react-router-dom";
import Toast from "../../../Utils/Toast";
import { downloadFile } from "../../../Helpers/Helper";
import isTBRIUser from "../../../Utils/isTBRIUser";
import DocumentTitle from "../../../Utils/DocumentTitle";

export function useHover() {
  const [hovering, setHovering] = useState(false);
  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };
  return [hovering, onHoverProps];
}

function VisualizationPreview(props) {
  const [chartPreview, setChartPreview] = useState(false);
  const [chartData, setChartData] = useState({});
  const [quarterToggle, setQuarterToggle] = useState(true);
  const history = useHistory();
  const [selectedChart, setSelectedChart] = useState();

  const [category, setCategory] = useState([
    { id: 0, name: "Segments", is_selected: false },
    { id: 1, name: "Companies", is_selected: false },
    { id: 2, name: "Metrics", is_selected: false },
  ]);

  const [selectedNode, setSelectedNode] = useState([]);

  let paramId = props.match?.params?.id;
  const handleCatgoryChange = useCallback((item) => {
    let newCategory = category.map((data) => {
      data.is_selected = data.id === item.id;
      return data;
    });

    setCategory(newCategory);
  });
  /**
   * After rendering the component for the first time, if we are not in preview mode and paramId is empty
   * we need to select company category by default, below useEffect does that.
   */
  useEffect(() => {
    // if (!chartPreview && !paramId) {
    //   handleCatgoryChange({ id: 1 });
    // }
    if (paramId) {
      setChartPreview(true);
      callRecentlyView(paramId);
    }
  }, []);

  const breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.NAME,
        link: formatRoute(
          constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE,
          {}
        ),
        is_active: false,
      },
      paramId
        ? {
            title: chartData.name,
            link: null,
            is_active: true,
          }
        : {
            title: constants.APPLICATION_ROUTE.VISUALIZATION_MAIN.CREATE.NAME,
            link: null,
            is_active: true,
          },
    ];
    return breadcrumbs;
  };

  const handleExportData = async () => {
    try {
      let input = { type: "quarterly", data: selectedNode };
      let res = await VisualizationServicesV2.fetchExportData(input);
      if (res.data.file) {
        downloadFile(res.data.file);
        Toast("Data Exported Successfully", "success");
      }
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
    // setChartPreview(!chartPreview);
  };

  const handlePreview = () => {
    setChartPreview(!chartPreview);
  };

  const [chartName, setChartName] = useState("");

  const saveChart = async (is_published) => {
    if (chartName) {
      try {
        let input = {
          id: paramId,
          name: chartName,
          chart_type: selectedChart,
          time_period: quarterToggle ? "quarterly" : "yearly",
          // start_date: "04/01/2022",
          // end_date: "04/01/2024",
          companies: [...new Set(selectedNode.map((item) => item.company_id))],
          segments: [...new Set(selectedNode.map((item) => item.segment_id))],
          metrics: [...new Set(selectedNode.map((item) => item.metric_id))],
          verticals: [...new Set(selectedNode.map((item) => item.vertical_id))],
          geos: [...new Set(selectedNode.map((item) => item.geo_id))],
          category: category.filter((item) => item.is_selected)[0]?.name,
          is_published: !chartData.is_published
            ? isTBRIUser()
              ? is_published
                ? 2
                : 0
              : is_published
              ? 2
              : 0
            : 0,
        };

        if (is_published) {
          input["published_at"] = new Date();
        }
        await VisualizationServicesV2.saveOrPublishChart(input);
        if (is_published) {
          Toast(
            `Chart ${
              chartData?.is_published ? "unpublished" : "published"
            } successfully`,
            "success"
          );
        } else {
          Toast("Chart updated successfully", "success");
        }
        history.push("/data-visualization");
      } catch (error) {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      }
    }
  };

  const callRecentlyView = async (visualizationId) => {
    let body = {
      table_name: constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.NAME,
      table_id: parseInt(visualizationId),
    };
    await SpecialReportServices.addRecentlyViewDetails(body).catch((error) => {
      this.props.categories([]);
      this.props.specialreportContent([]);
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  const getChartData = async () => {
    try {
      let res = await VisualizationServicesV2.getChartDataByid(paramId);
      setQuarterToggle(
        res.data.chart.time_period === "quarterly" ? true : false
      );
      setSelectedNode(res.data.results);
      setChartData(res.data.chart);
      setChartName(res.data.chart.name);
      setSelectedChart(res.data.chart.chart_type);
      let tempCategories = [...category];
      tempCategories.map((i) => {
        if (i.name === res.data.chart.category) {
          i.is_selected = true;
        }
      });
      setCategory(tempCategories);
      if (paramId) DocumentTitle(`${res.data.chart.name}`);
      gaEvent.init({
        moduleName: constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.NAME,
        id: paramId,
        page_url:
          props.history.location.pathname + props.history.location.search,
        isPublished: res.data.chart.is_published,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getChartData();
    return () => {
      window.removeEventListener("beforeunload", promptFunction);
      gaEvent.trigger();
    };
  }, []);
  return (
    <aside className="main_content create_chart">
      {isTBRIUser() ? (
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section-chart breadcrumb-mb-0 pt-3">
            <div className="beta-label">Beta</div>
            <CustomBreadcrumb breadcrumbs={breadcrumbs()} />
            <div className="ml-auto">
              <HelpModal category="Data Visualization Details" />
            </div>
          </div>
          {chartPreview ? (
            <ChartView
              setChartName={setChartName}
              saveChart={saveChart}
              selectedNodes={selectedNode}
              setChartPreview={setChartPreview}
              chartName={chartName}
              quarterToggle={quarterToggle}
              setQuarterToggle={setQuarterToggle}
              paramId={paramId}
              chartData={chartData}
              setSelectedChart={setSelectedChart}
              selectedChart={selectedChart}
            />
          ) : (
            <div
              className={`${
                chartPreview ? "opened-chartPreview" : "closed-chartPreview"
              } selectelements-chartpreview-wrapper d-flex mb-3`}
            >
              <div className="selectelements-card-wrapper">
                {/* <div className="card selectelements-card h-50"> */}
                <div className="card-body">
                  <div className="selectelements-content flex-column">
                    <h3
                      className="text-black2 font-weight-semibold mb-1"
                      data-testid="chartHeading"
                    >
                      Insights Lens
                    </h3>
                    <p className="text-grey">
                      Select segments and companies to generate the chart
                      preview
                    </p>
                    <div
                      className="btn-block category-btn-block d-flex"
                      style={{ gap: "15px" }}
                    >
                      <button
                        className={`btn normal-btn`}
                        style={{
                          background:
                            category.find((item) => item.is_selected)?.name ===
                            "Companies"
                              ? "#004DC2"
                              : "#DCEDFF",
                          color:
                            category.find((item) => item.is_selected)?.name ===
                            "Companies"
                              ? "#FFFFFF"
                              : "#004DC2",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        type="button"
                        onClick={() => handleCatgoryChange({ id: 1 })}
                        disabled={
                          !checkPermission(
                            constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE,
                            constants.ACL.MY_VISUALIZATION_RESOURCE.ACTIONS
                              .CREATE
                          )
                        }
                      >
                        Companies
                      </button>
                      <button
                        className={`btn normal-btn`}
                        style={{
                          background:
                            category.find((item) => item.is_selected)?.name ===
                            "Segments"
                              ? "#004DC2"
                              : "#DCEDFF",
                          color:
                            category.find((item) => item.is_selected)?.name ===
                            "Segments"
                              ? "#FFFFFF"
                              : "#004DC2",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        type="button"
                        onClick={() => handleCatgoryChange({ id: 0 })}
                        disabled={
                          !checkPermission(
                            constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE,
                            constants.ACL.MY_VISUALIZATION_RESOURCE.ACTIONS
                              .CREATE
                          )
                        }
                      >
                        Segments
                      </button>
                      <button
                        className={`btn normal-btn`}
                        style={{
                          background:
                            category.find((item) => item.is_selected)?.name ===
                            "Metrics"
                              ? "#004DC2"
                              : "#DCEDFF",
                          color:
                            category.find((item) => item.is_selected)?.name ===
                            "Metrics"
                              ? "#FFFFFF"
                              : "#004DC2",
                          fontSize: 16,
                          fontWeight: 600,
                        }}
                        type="button"
                        onClick={() => handleCatgoryChange({ id: 2 })}
                        disabled={
                          !checkPermission(
                            constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE,
                            constants.ACL.MY_VISUALIZATION_RESOURCE.ACTIONS
                              .CREATE
                          )
                        }
                      >
                        Metrics
                      </button>
                    </div>
                    <div className="row elements-block-wrapper-new">
                      <div className="col-md-8">
                        <div className="row first-section blocktitle">
                          {category.find((item) => item.is_selected) &&
                          category.filter((item) => item.is_selected)[0]
                            ?.name === "Companies" ? (
                            <CompanyTab
                              paramId={paramId}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                            />
                          ) : category.filter((item) => item.is_selected)[0]
                              ?.name === "Segments" ? (
                            <SegmentTab
                              paramId={paramId}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                            />
                          ) : category.filter((item) => item.is_selected)[0]
                              ?.name === "Metrics" ? (
                            <MetricsTab
                              paramId={paramId}
                              selectedNode={selectedNode}
                              setSelectedNode={setSelectedNode}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="selected-items-block element-block">
                          <h6 className="text-grey block-title font-weight-normal mb-0">
                            Selected Items
                          </h6>
                          <div className="selected-items-wrapper rule-div">
                            {selectedNode.map((item, index) => {
                              return (
                                <div
                                  className="rules-item-div"
                                  key={index}
                                  style={{
                                    background:
                                      index > 25
                                        ? "#808080"
                                        : "var(--bright_blue, #2e6ad2)",
                                  }}
                                >
                                  <span>
                                    {item.company_name} {` > `}{" "}
                                    {item.segment_name} {` > `}{" "}
                                    {item.metric_name} {`>`}{" "}
                                    {item.geo_name ?? "Global"} {`>`}{" "}
                                    {item.vertical_name ?? "All Industries"}
                                  </span>
                                  <img
                                    src={CancelIcon}
                                    alt="cancel-icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      setSelectedNode(
                                        selectedNode.filter(
                                          (_, key) => key !== index
                                        )
                                      )
                                    }
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="btn-block"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <div className="two-btn-bottom">
                        <button
                          className="btn btn-primary dark-blue-btn btn-width-height mr-2"
                          type="button"
                          onClick={handleExportData}
                          disabled={selectedNode.length ? false : true}
                        >
                          Export data
                        </button>
                        <button
                          className="btn btn-primary dark-blue-btn btn-width-height"
                          type="button"
                          onClick={handlePreview}
                          disabled={selectedNode.length ? false : true}
                        >
                          Draw Chart
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>Page not found</div>
      )}
    </aside>
  );
}

export default withRouter(VisualizationPreview);
