import React from 'react';
import ReactTooltip from 'react-tooltip';

const CustomReactTooltip = ({ className, ...props }) => {
  // Concatenate the default class and any additional classes passed if not then it will be blank
  const tooltipClassName = `tooltip-custom-theme ${className || ''}`;

  return (
    <ReactTooltip {...props} effect="solid" className={tooltipClassName} />
  );
};

export default CustomReactTooltip;
