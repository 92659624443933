import ReactGA from "react-ga4";

let is_initialised = false;
export default function React_GA_Intialise(userId) {
    if(!is_initialised){
        ReactGA.initialize([{
            trackingId: process.env.REACT_APP_MEASUREMENT_ID,
            gaOptions: {
                userId
            },
            gtagOptions: {
                send_page_view: false
            }
        }]);
        ReactGA.set({
            crm_id: userId
        })
        is_initialised = true;
    }
}