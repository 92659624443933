import React, { useEffect, useState } from "react";
import CustomCheckBox from "./CustomCheckBox";
import CustomReactTooltip from "../../../Components/CustomReactTooltip";
import { VisualizationServicesV2 } from "../../../Services/VisualizationV2";
import { DropdownList } from "react-widgets";
import "./index.scss";
import { map, isUndefined, get, orderBy } from "lodash";
import Toast from "../../../Utils/Toast";
import { CommonFunctions } from "../../../Containers/V2/VisalizationV2/CommonFunction";

function SegmentTab({ paramId, selectedNode, setSelectedNode }) {
  const [companyList, setCompanyList] = useState([]);
  const [backupCompayList, setBackupCompayList] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [expandedSegments, setExpandedSegments] = useState([]);
  const [id, setId] = useState(0);
  const [disableSegmentBox, setDisableSegmentBox] = useState(true);
  const [metricsTypeId, setMetricsTypeId] = useState();
  const [metricTypeList, setMetricTypeList] = useState([]);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [geoList, setGeoList] = useState([]);
  const [verticalList, setVerticalList] = useState([]);
  const [showGeo, setShowGeo] = useState(false);
  const [expandedGeos, setExpandedGeos] = useState([]);
  const [selectedGeo, setSelectedGeo] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState([]);
  const [expandedVarticals, setExpandedVarticals] = useState([]);
  const [disableMetricsBox, setDisableMetricsBox] = useState(false);
  const [disableGroVerticalBox, setDisableGroVerticalBox] = useState(false);
  const [disabledCompany, setDisabledCompanyBox] = useState(false);

  const handleSearch = (e) => {
    const regularExp = new RegExp(e.target.value, "i");

    let tempNodes = JSON.parse(JSON.stringify(backupCompayList));
    let result = [];
    result = tempNodes.filter((x) => regularExp.test(x.label));
    setCompanyList(result);
  };

  const handleByCompanyTrigger = (checked) => {
    setSelectedCompanies(checked);
  };

  const handleBySegmentTrigger = (checked) => {
    setSelectedSegments(checked);
  };

  const childrenStructure = (nodes, name) => {
    return map(nodes, (node) => {
      const addField = {
        ...node,
        value: node.id,
        label: node[name],
      };

      if (isUndefined(node.is_data_available)) {
        const hasData =
          node.total_geos ||
          node.total_quarters ||
          node.total_verticals ||
          node.total_years ||
          node[name] === "global" ||
          node[name] === "all industries";

        addField.is_data_available = hasData ? "true" : "false";
      }

      if (get(addField, "children.length", 0) > 0) {
        addField.children = childrenStructure(addField.children, name);
      }

      return addField;
    });
  };

  const addCompanies = async (value) => {
    // setId(value);

    let input = { segment_ids: selectedSegments };
    let companyRes = await VisualizationServicesV2.getCompanyBySegment(input);
    let data = companyRes.data.data.map((i) => ({
      ...i,
      value: i.id,
      label: i.name,
    }));

    setCompanyList(
      orderBy(data, [(d) => d.is_data_available === "true"], ["desc"])
    );
    setBackupCompayList(
      orderBy(data, [(d) => d.is_data_available === "true"], ["desc"])
    );
    if (!paramId) {
      setSelectedCompanies([]);
    }
    setDisableSegmentBox(false);
    setDisabledCompanyBox(true);
  };

  const addMetrics = async () => {
    let input = {
      company_ids: selectedCompanies,
      segment_ids: selectedSegments,
    };

    let metricsRes =
      await VisualizationServicesV2.getMetricsBycompanyAndSegment(input);
    let data = metricsRes.data.data.map((i) => ({
      ...i,
      id: i.metric_type_id,
      label: i.metric_type_name,
      metrics: i.metrics.map((el) => {
        if (
          el?.total_geos ||
          el?.total_quarters ||
          el?.total_verticals ||
          el?.total_years
        ) {
          el.is_data_available = "true";
        } else {
          el.is_data_available = "false";
        }
        return el;
      }),
    }));

    // Function to check if any data has is_data_available set to true
    const hasDataAvailable = (data) =>
      data.metrics.some((d) => d.is_data_available === "true");

    // Sort the data array within each object
    const sortedInnerData = data.map((item) => ({
      ...item,
      metrics: orderBy(
        item.metrics,
        [(d) => d.is_data_available === "true"],
        ["desc"]
      ),
    }));

    // Sort the array with true values first
    const sortedData = orderBy(sortedInnerData, [hasDataAvailable], ["desc"]);

    setMetricTypeList(sortedData);
    setMetricsTypeId(sortedData[0].metric_type_id);
    // setDisableSegmentBox(false);
    setDisableMetricsBox(true);
    setDisabledCompanyBox(false);
  };

  const handleMetricsType = (e) => {
    setMetricsTypeId(e.id);
  };

  const handleByMetricsTrigger = (e) => {
    setSelectedMetrics(e);
  };

  const handleGeoAndVertical = async (e) => {
    let input = {
      company_ids: selectedCompanies,
      segment_ids: selectedSegments,
      metric_ids: selectedMetrics,
    };
    let geoRes = await VisualizationServicesV2.getGeoByCompanySegmentAndMetrics(
      input
    );
    let goeData = childrenStructure(geoRes.data.data.geos, "geo_name");
    setGeoList(goeData);
    setSelectedGeo([goeData.filter((i) => i.geo_name === "global")?.[0]?.id]);

    let verticalRes =
      await VisualizationServicesV2.getVerticalByCompanySegmentAndMetrics(
        input
      );
    let verticalData = childrenStructure(
      verticalRes.data.data.verticals,
      "vertical_name"
    );
    setVerticalList(verticalData);

    setSelectedVertical([
      verticalData.filter((i) => i.vertical_name === "all industries")?.[0]?.id,
    ]);
    setDisableMetricsBox(false);
    setDisableGroVerticalBox(true);
  };

  const createRules = async () => {
    let input = {
      company_ids: selectedCompanies,
      segment_ids: selectedSegments,
      metric_ids: selectedMetrics,
      geo_ids: selectedGeo,
      vertical_ids: selectedVertical,
    };

    let rulesRes = await VisualizationServicesV2.fetchChartRules(input);
    if (!rulesRes.data.data.length) {
      Toast("Data not available", "error");
    }
    let uniqueObjects = [...selectedNode, ...rulesRes.data.data].filter(
      (obj, index, self) =>
        index ===
        self.findIndex((t) => JSON.stringify(t) === JSON.stringify(obj))
    );
    setSelectedNode(uniqueObjects);
    setDisableGroVerticalBox(false);
    setDisableSegmentBox(true);
    setDisableMetricsBox(false);

    setGeoList([]);
    setVerticalList([]);
    setMetricTypeList([]);
    setMetricsTypeId();
    setCompanyList([]);
    setShowGeo(false);
    setId(0);
    if (!paramId) {
      setSelectedMetrics([]);
      setSelectedCompanies([]);
      setSelectedSegments([]);
      setSelectedGeo([]);
      setSelectedVertical([]);
    }
  };

  const fetchSegments = async () => {
    try {
      let segmentRes = await VisualizationServicesV2.getSegments();
      let data = childrenStructure(segmentRes.data.data, "segment_name");
      setSegmentList(data);
      setExpandedSegments(CommonFunctions.collectIdsForAllNodes(data));
      // setSegmentList(childrenStructure(segmentRes.data.data, "segment_name"));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchSegments();
  }, []);

  const setSelectedData = async () => {
    setSelectedCompanies([...new Set(selectedNode.map((i) => i.company_id))]);

    setSelectedSegments([...new Set(selectedNode.map((i) => i.segment_id))]);
    setExpandedSegments([...new Set(selectedNode.map((i) => i.segment_id))]);
    setSelectedMetrics([...new Set(selectedNode.map((i) => i.metric_id))]);
    setSelectedGeo([...new Set(selectedNode.map((i) => i.geo_id))]);
    setExpandedGeos([...new Set(selectedNode.map((i) => i.geo_id))]);
    setSelectedVertical([...new Set(selectedNode.map((i) => i.vertical_id))]);
    setExpandedVarticals([...new Set(selectedNode.map((i) => i.vertical_id))]);
  };

  useEffect(() => {
    if (paramId) {
      setSelectedData();
    }
  }, [selectedNode]);

  return (
    <>
      {/* Segment Section */}
      <div
        className="col-md-3 add-segments-block"
        style={{
          backgroundColor: disableSegmentBox
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(243, 243, 243, 0.5)",
          cursor: disableSegmentBox ? "pointer" : "disabled",
        }}
      >
        <div
          className={`text-grey block-title font-weight-normal mb-0 d-flex justify-between align-items-center ${
            paramId ? "block-title-icon" : ""
          }`}
        >
          <h6 className="text-grey font-weight-normal mb-0">Select Segments</h6>
        </div>
        <div className="segments-tree custom-checkbox-tree">
          <CustomCheckBox
            nodes={segmentList}
            checked={selectedSegments}
            expanded={expandedSegments}
            onExpand={(expanded) => setExpandedSegments(expanded)}
            onCheck={(checked) => handleBySegmentTrigger(checked)}
            key={"Segments"}
            // handleButtonClick={(value) => addCompanies(value)}
            disabled={!disableSegmentBox}
            is_data={true}
            showArrow={false}
            is_tree={true}
            width={"380px"}
          />
        </div>
        {disableSegmentBox && (
          <div
            style={{
              // position: "absolute",
              bottom: "0%",
              height: "30px",
              width: "100%",
              backgroundColor: "#DCEDFF",
              display: "flex",
              alignItems: "center",
              padding: "5px",
              justifyContent: "space-between",
              cursor: selectedSegments.length ? "pointer" : "not-allowed",
            }}
            onClick={() => {
              selectedSegments.length && addCompanies();
            }}
          >
            <span
              style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
            >
              Next
            </span>
            <span
              className="material-icons-outlined add-icon"
              style={{ color: "#2E6AD2", fontSize: "18px" }}
            >
              arrow_forward
            </span>
          </div>
        )}
      </div>

      {/* Company Section */}
      <div
        className="col-md-3 select-companies-block"
        style={{
          backgroundColor: disabledCompany
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(243, 243, 243, 0.5)",
          cursor: disabledCompany ? "pointer" : "not-allowed",
        }}
      >
        <h6 className="text-grey block-title font-weight-normal mb-0">
          Select Companies
        </h6>
        <div className="plain-checkbox-list-wrapper">
          <div className="sticky-section-search">
            <div className="select_companies_search">
              <input
                className="form-control text-grey visalization-input"
                type="text"
                name="name"
                onChange={(e) => handleSearch(e)}
                placeholder={`Search Company`}
                disabled={disabledCompany ? false : true}
                style={{ borderRadius: "0px" }}
              />
              <span className="material-icons-outlined text-grey">search</span>
            </div>
            <hr className="m-0" />
          </div>
          <CustomCheckBox
            nodes={companyList}
            checked={selectedCompanies}
            onCheck={(checked) => handleByCompanyTrigger(checked)}
            key={"Companies"}
            disabled={disabledCompany ? false : true}
          />
        </div>
        {disabledCompany && (
          <div
            style={{
              // position: "absolute",
              bottom: "0%",
              height: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 10 0 10",
              justifyContent: "space-between",
              gap: "10px",
              background: "#ffffff",
            }}
          >
            <div
              style={{
                width: "50%",
                backgroundColor: "#FBFBFB",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setDisableSegmentBox(true);
                setDisabledCompanyBox(false);
                if (!paramId) {
                  setSelectedCompanies([]);
                }
                setCompanyList([]);
              }}
            >
              <span
                className="material-icons-outlined add-icon"
                // data-testid={`checkbox-arrow-segment`}
                style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
              >
                arrow_backward
              </span>
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#606B77" }}
              >
                Back
              </span>
            </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#DCEDFF",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: selectedCompanies.length ? "pointer" : "not-allowed",
              }}
              onClick={() => {
                selectedCompanies.length && addMetrics();
              }}
            >
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
              >
                Next
              </span>
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#2E6AD2", fontSize: "18px" }}
              >
                arrow_forward
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Metrics Section */}

      <div
        className="col-md-3 add-segments-block"
        style={{
          backgroundColor: disableMetricsBox
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(243, 243, 243, 0.5)",
          cursor: disableMetricsBox ? "pointer" : "not-allowed",
        }}
      >
        <h6 className="text-grey block-title font-weight-normal mb-0">
          Select Metrics
        </h6>
        <div className="plain-checkbox-list-wrapper">
          <div
            className="select_companies_search metrics_dropdown"
            style={{
              // backgroundColor: disableMetricsBox
              //   ? "rgba(255, 255, 255, 0.5)"
              //   : "rgba(243, 243, 243, 0.5)",
              cursor: disableMetricsBox ? "pointer" : "disabled",
            }}
          >
            <DropdownList
              data={metricTypeList}
              valueField="id"
              textField="label"
              value={metricsTypeId}
              onChange={handleMetricsType}
              placeholder="Select Metrics Type"
              disabled={!disableMetricsBox}
              style={{ border: "none" }}
              className="metrics-dropdown-list"
            />
          </div>
          <hr className="m-0" />
          {/* </div> */}
          <CustomCheckBox
            nodes={metricTypeList
              .find((i) => i.id === metricsTypeId)
              ?.metrics.map((i) => ({ ...i, value: i.id, label: i.name }))}
            checked={selectedMetrics}
            is_data={true}
            showArrow={false}
            onCheck={(checked) => handleByMetricsTrigger(checked)}
            key={"Metrics"}
            disabled={!disableMetricsBox}
          />
        </div>
        {disableMetricsBox && (
          <div
            style={{
              // position: "absolute",
              bottom: "0%",
              height: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 10 0 10",
              justifyContent: "space-between",
              gap: "10px",
              background: "#ffffff",
            }}
          >
            <div
              style={{
                width: "50%",
                backgroundColor: "#FBFBFB",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setDisabledCompanyBox(true);
                setDisableMetricsBox(false);
                if (!paramId) {
                  setSelectedMetrics([]);
                }
                setMetricTypeList([]);
                setMetricsTypeId(null);
              }}
            >
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
              >
                arrow_backward
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#606B77",
                }}
              >
                Back
              </span>
            </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#DCEDFF",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: selectedMetrics.length > 0 ? "pointer" : "not-allowed",
              }}
              onClick={(e) =>
                selectedMetrics.length > 0 && handleGeoAndVertical(e)
              }
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#2E6AD2",
                }}
              >
                Next
              </span>
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#2E6AD2", fontSize: "18px" }}
              >
                arrow_forward
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Geo and Vertical Section */}
      <div
        className="col-md-3 select-metric-block"
        style={{
          backgroundColor: disableGroVerticalBox
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(243, 243, 243, 0.5)",
          cursor: disableGroVerticalBox ? "pointer" : "disabled",
          // opacity: disableGroVerticalBox ? 1 : 0.5,
        }}
      >
        <ul className="block-title metric-ul">
          <li>
            <span
              style={{ cursor: "pointer" }}
              data-tip="Select a Industry"
              data-for="Industry"
              className={`${
                !showGeo ? "active" : "text-black"
              } material-icons-outlined btn-metric`}
              onClick={() => {
                setShowGeo(false);
              }}
            >
              domain
            </span>
            <CustomReactTooltip id="Industry"></CustomReactTooltip>
          </li>
          <li>
            <span
              style={{ cursor: "pointer" }}
              data-tip="Select a Geo Location"
              data-for="Geo Location"
              className={`${
                showGeo ? "active" : "text-black"
              } material-icons-outlined btn-metric`}
              onClick={() => {
                setShowGeo(true);
              }}
            >
              language
            </span>
            <CustomReactTooltip id="Geo Location"></CustomReactTooltip>
          </li>
        </ul>
        {!showGeo ? (
          <div className="segments-tree custom-checkbox-tree">
            <CustomCheckBox
              nodes={verticalList}
              checked={selectedVertical}
              is_data={true}
              onCheck={(checked) => setSelectedVertical(checked)}
              key={"Vertical"}
              expanded={expandedVarticals}
              onExpand={(expanded) => setExpandedVarticals(expanded)}
              disabled={!disableGroVerticalBox}
              width={"270px"}
            />
          </div>
        ) : (
          <div className="segments-tree custom-checkbox-tree">
            <CustomCheckBox
              nodes={geoList}
              checked={selectedGeo}
              is_data={true}
              onCheck={(checked) => setSelectedGeo(checked)}
              key={"Geos"}
              expanded={expandedGeos}
              onExpand={(expanded) => setExpandedGeos(expanded)}
              disabled={!disableGroVerticalBox}
              width={"220px"}
            />
          </div>
        )}
        {disableGroVerticalBox && (
          <div
            style={{
              // position: "absolute",
              bottom: "0%",
              height: "30px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: "0 10 0 10",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "50%",
                backgroundColor: "#FBFBFB",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (showGeo) {
                  setShowGeo(false);
                  if (!paramId) {
                    setExpandedGeos([]);
                    setSelectedGeo([]);
                  }
                  setGeoList([]);
                } else {
                  if (!paramId) {
                    setExpandedVarticals([]);
                    setSelectedVertical([]);
                  }
                  setDisableGroVerticalBox(false);
                  setDisableMetricsBox(true);
                  setVerticalList([]);
                }
              }}
            >
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
              >
                arrow_backward
              </span>
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#606B77" }}
              >
                Back
              </span>
            </div>
            <div
              style={{
                width: "50%",
                backgroundColor: "#DCEDFF",
                height: "inherit",
                display: "flex",
                alignItems: "center",
                padding: "0px 10px",
                gap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                showGeo ? createRules() : setShowGeo(true);
              }}
            >
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
              >
                {!showGeo ? "Next" : "Add"}
              </span>
              <span
                className="material-icons-outlined add-icon"
                style={{ color: "#2E6AD2", fontSize: "18px" }}
              >
                arrow_forward
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SegmentTab;
