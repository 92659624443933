import { Axios } from "../lib/axios";
export const VisualizationServices = {
  getSegments,
  getCompanies,
  getCompaniesBySegments,
  getSegmentsByCompanies,
  getChartData,
  SaveChartTemplate,
  getTemplates,
  editChartTemplate,
  checkTemplateName,
  delteTemplate,
  publishTemplate,
  shareTemplate,
  getShareModes,
  getTemplateSharedUsers,
};
function getChartData(requestBody) {
  return Axios.post("/visualization/displaychart", requestBody);
}
function getSegments() {
  return Axios.get("/visualization/?type=segment");
}
function getCompanies() {
  return Axios.get("/visualization/?type=company");
}

function getCompaniesBySegments(ids) {
  return Axios.get(`/visualization/getcompanies/?segmentIds=${ids}`);
}
function getSegmentsByCompanies(ids) {
  return Axios.get(`/visualization/getsegments/?companyIds=${ids}`);
}

function SaveChartTemplate(requestBody) {
  return Axios.post("/visualization/saveChartData", requestBody);
}

function getTemplates(url) {
  return Axios.get(url);
}

function editChartTemplate(id) {
  return Axios.get(`/visualization/chart/${id}`);
}

function checkTemplateName(requestBody) {
  return Axios.post("/visualization/checkTemplateName", requestBody);
}

function delteTemplate(id) {
  return Axios.delete(`/visualization_chart/delete/${id}`);
}
function publishTemplate(url, body) {
  return Axios.post(url, body);
}
function shareTemplate(templateId, body) {
  return Axios.post(`visualization_chart/${templateId}/share`, body);
}
function getShareModes() {
  return Axios.get(`modes?type=template`);
}
function getTemplateSharedUsers(boardId) {
  return Axios.get(
    `https://jsonblob.com/api/3e56beed-9e61-11ea-84c9-07d7e2bca042`
  );
}
