import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import Dailog from '../Components/Dailog'
import Notification from '../Components/Notification'
import { ClientServices } from '../Services/Client'
import { clientRoles, clients } from '../Store/Actions/Client'
import { activeMenuTab } from '../Store/Actions/User'
import constants from '../Utils/constants'
import DocumentTitle from '../Utils/DocumentTitle'
import Toast from '../Utils/Toast'

class ManageClientUser extends Component {
  state = {
    clientId: '',
    userFirstName: '',
    userLastName: '',
    userEmail: '',
    userContactNumber: '',
    userAddress: '',
    userRoleId: null,
    formErrors: {

    },
    dailogModal: false,
    dailogModalContent: '',
    dailogModalHeader: '',
    dailogModalConfig: {},
    isFormSubmitted: false,
    apiError: null
  }
  changeRoute = () => {
    let clientId = this.props.match.params.clientId ? this.props.match.params.clientId : null
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, {  clientId: clientId }),
      search: '?page=1&page_size=10' 
    })
  }
  async componentDidMount() {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE)
    let clientId = this.props.match.params.clientId ? this.props.match.params.clientId : null
    let userId = this.props.match.params.id ? this.props.match.params.id : null
    if (userId !== null)
      this.prepopulateData(userId)
    this.setState({
      clientId: clientId
    })
    await ClientServices.getClientUserRoles()
      .then((res) => {
        let response = res && res.data
        response.forEach(r => {
          r.is_selected = false
        })
        this.props.setClientRoles(response)
      })
      .catch((error) => {
        
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
  }
  prepopulateData = (userId) => {
    ClientServices.getClientUserDetails(userId)
      .then((res) => {
        let response = res.data
        this.setState({
          userFirstName: response.first_name ? response.first_name : '',
          userLastName: response.last_name ? response.last_name : '',
          userEmail: response.email ? response.email : '',
          userContactNumber: response.mobile ? response.mobile : '',
          userAddress: response.address ? response.address : '',
          userRoleId: response.role.id ? response.role.id : '',
          isFormSubmitted: false,
          apiError: null,
          formErrors: {

          },
        }, () => {
        })
      })
      .catch((error) => {
        
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
  }

  handleInputChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.id]: e.target.value
    }, () => {
      if (this.state.isFormSubmitted === true)
        this.isFormValid()
    })
  }

  isFormValid = () => {
    let formError = false
    let userFirstNameError = false
    let userLastNameError = false
    let userEmailError = false
    let userContactNumberError = false
    if (this.state.userFirstName.trim() === '') {
      userFirstNameError = true
      formError = true
    }
    if (this.state.userLastName.trim() === '') {
      userLastNameError = true
      formError = true
    }
    if (this.state.userEmail.trim() === '') {
      userEmailError = true
      formError = true
    }
    else {
      var re = constants.REG_EX.EMAIL_REG_EX
      userEmailError = !re.test(String(this.state.userEmail).toLowerCase())
      if (userEmailError === true) {
        formError = true
      }
    }
    if (this.state.userContactNumber !== '') {
      var re_phone = constants.REG_EX.PHONE_REG_EX
      userContactNumberError = !re_phone.test(this.state.userContactNumber)
      if (userContactNumberError === true) {
        formError = true
      }
    }
    this.setState({
      ...this.state,
      formErrors: {
        ...this.state.formErrors,
        userFirstNameError: userFirstNameError,
        userLastNameError: userLastNameError,
        userEmailError: userEmailError,
        userContactNumberError: userContactNumberError
      },
      apiError: null
    })
    return formError
  }
  handleSubmit = () => {
    this.setState({
      isFormSubmitted: true
    }, () => {
      if (!this.isFormValid()) {
        let userId = this.props.match.params.id ? this.props.match.params.id : null
        let roles = (this.props.client && this.props.client.clientRoles) ? this.props.client.clientRoles : []
        let userRoleId = roles.find((r) => r.short_name === constants.ROLES.CLIENT_USER.MEMBER) ? roles.find((r) => r.short_name === constants.ROLES.CLIENT_USER.MEMBER).id : null
        let clientId = this.state.clientId
        let prepareObject = {
          first_name: this.state.userFirstName,
          last_name: this.state.userLastName,
          email: this.state.userEmail,
          mobile: this.state.userContactNumber,
          address: this.state.userAddress,
          role_id: this.state.userRoleId !== null ? this.state.userRoleId : userRoleId,
        }
        if (userId === null) {
          //create        
          ClientServices.addClientUser(prepareObject)
            .then((res) => {
              if (res.data.error !== undefined) {
               
                this.setState({
                  apiError: res && res.data && res.data.error ? res.data.error : constants.ERROR.SOMETHING_WENT_WRONG
                })
              }
              else {
                this.saveClientUserSuccess()
              }
            })
            .catch((error) => {
              
              this.setState({
                apiError: error && error.data && error.data.message ? error.data.message : constants.ERROR.SOMETHING_WENT_WRONG
              })
            })
        }
        else {
          ClientServices.editClientUser(userId, prepareObject)
            .then((res) => {
              this.saveClientUserSuccess()
            })
            .catch((error) => {
              Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
              this.setState({
                apiError: error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG
              })
            })
        }
      }
    })
  }
  saveClientUserSuccess = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null
    
    Toast(userId === null ? constants.CLIENT_USER.CREATE.SUCCESS : constants.CLIENT_USER.EDIT.SUCCESS, 'success')
    setTimeout(() =>
      this.props.history.push({
        pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, { clientId: this.state.clientId })
      })
      , 1500)
  }
  dailogModalAccept = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: '',
      dailogModalContent: '',
      dailogModalConfig: {}
    })
  }
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: '',
      dailogModalContent: '',
      dailogModalConfig: {}
    })
  }
  resetForm = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null
    if (userId !== null)
      this.prepopulateData(userId)

    else {
      this.setState({
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userContactNumber: '',
        userAddress: '',
        userRoleId: '',
        formErrors: {

        },
        dailogModal: false,
        dailogModalContent: '',
        dailogModalHeader: '',
        dailogModalConfig: {},
        isFormSubmitted: false,
        apiError: null
      })
    }
  }
  breadcrumbs = () => {
    let userId = this.props.match.params.id ? this.props.match.params.id : null
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, {}),
        is_active: false
      },
      {
        title: userId ? constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.NAME : constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.NAME,
        link: null,
        is_active: true
      }
    ]
    return breadcrumbs
  }
  render() {
    let userId = this.props.match.params.id ? this.props.match.params.id : null
    DocumentTitle(userId === null ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.HEADER_TITLE : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.HEADER_TITLE)
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height'>
        
        <div className="manage-client">
          <div className="">
            <div className={'add-user-card'}>
              <form className={' form-wrapper'}>
                
                <div className="card border-0">
                  <div className="card-header title-cancel-header d-flex align-items-center" >
                    <h3 className="text-darkblue2 mb-0 font-weight-bold">{userId === null ? constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.HEADER_TITLE : constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.HEADER_TITLE}</h3>
                    <span className="material-icons-round text-darkblue2 ml-auto cursor-pointer" onClick={this.changeRoute} >
                      close
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="card-body-header">
                      <h5 className="mb-0 font-weight-semibold">Personal Information</h5>
                    </div>
                    {this.state.apiError !== null && 
                      <div className="container-fluid mt-2">
                        <Notification color={'danger'} message={this.state.apiError} />
                      </div>
                    }
                    <div className="form-field-wrapper">
                      <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                          <div className={'row'}>
                            <div className="col-sm-6 form-group form-inline position-relative">
                              <label htmlFor="userFirstName">First Name <span className={'mandatory'}>*</span></label>
                              <input type="text" className={`form-control ${(this.state.formErrors.userFirstNameError === true ? 'is-invalid' : '')} ${(this.state.formErrors.userFirstNameError === false ? '' : '')}`} id="userFirstName" placeholder="First Name" value={this.state.userFirstName} onChange={this.handleInputChange} />
                              {this.state.formErrors.userFirstNameError && <div className="invalid-tooltip">{constants.FORM.MANDATORY_FIELD}</div>}
                            </div>
                            <div className="col-sm-6 form-group form-inline position-relative">
                              <label htmlFor="userLastName">Last Name <span className={'mandatory'}>*</span></label>
                              <input type="text" className={`form-control ${(this.state.formErrors.userLastNameError === true ? 'is-invalid' : '')} ${(this.state.formErrors.userLastNameError === false ? '' : '')}`} id="userLastName" placeholder="Last Name" value={this.state.userLastName} onChange={this.handleInputChange} />
                              {this.state.formErrors.userLastNameError && <div className="invalid-tooltip">{constants.FORM.MANDATORY_FIELD}</div>}
                            </div>
                          </div>
                          <div className={'row'}>
                            <div className="col-sm-6 form-group form-inline position-relative">
                              <label for="userEmail">Email <span className={'mandatory'}>*</span></label>
                              <input type="text" className={`form-control ${(this.state.formErrors.userEmailError === true ? 'is-invalid' : '')} ${(this.state.formErrors.userEmailError === false ? '' : '')}`} id="userEmail" placeholder="Email (example@domain.com)" value={this.state.userEmail} onChange={this.handleInputChange} />
                              {this.state.formErrors.userEmailError && <div className="invalid-tooltip">{constants.FORM.INVALID_EMAIL}</div>}
                            </div>
                            <div className="col-sm-6 form-group form-inline position-relative">
                              <label for="userContactNumber">Contact No.</label>
                              <input type="text" className={`form-control ${(this.state.formErrors.userContactNumberError === true ? 'is-invalid' : '')} ${(this.state.formErrors.userContactNumberError === false ? '' : '')}`} id="userContactNumber" placeholder="Contact No. (99999999)" value={this.state.userContactNumber} onChange={this.handleInputChange} />
                              {this.state.formErrors.userContactNumberError && <div className="invalid-tooltip">{constants.FORM.INVALID_CONTACT_NUMBER}</div>}
                            </div>
                          </div>
                          <div className={'row'}>
                            <div className="col-sm-12 form-group align-top form-inline position-relative">
                              <label for="address">Address</label>
                              <textarea className={`form-control ${(this.state.formErrors.addressError === true ? 'is-invalid' : '')} ${(this.state.formErrors.addressError === false ? '' : '')}`} id="userAddress" placeholder="Address" value={this.state.userAddress} onChange={this.handleInputChange} />
                              {this.state.formErrors.addressError && <div className="invalid-tooltip">{constants.FORM.MANDATORY_FIELD}</div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
                        <div className="text-center form-buttons">
                          <button type="button" className="btn btn-outline-primary modal-btn" onClick={this.resetForm}>Reset</button>
                          <button type="button" className="btn btn-primary modal-btn" onClick={this.handleSubmit}>{userId ? "Update" : "Save"}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
        <Dailog isOpen={this.state.dailogModal} accept={this.dailogModalAccept} decline={this.dailogModalDecline} header={this.state.dailogModalHeader} content={this.state.dailogModalContent} config={this.state.dailogModalConfig} />
      </aside>
    )
  }
}
const mapStateToProps = state => {
  return {
    home: state.home,
    client: state.client
  }
}
const mapDispatchToProps = dispatch => {
  return {
    setClients: (allClients) => {
      dispatch(clients(allClients))
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles))
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageClientUser)