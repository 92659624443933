import React, { Component } from "react";
import { Collapse } from "reactstrap";
import BoardDetailsComment from "../Components/BoardDetailsComment";
import AddBoardDetailsComment from "../Components/AddBoardDetailsComment";
import constants from "../Utils/constants";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Slugify from "../Utils/Slugify";

class BoardDetailsComments extends Component {
  state = {
    comments: this.props.comments,
    openCommentsFlag: this.props.openCommentsFlag,
  };

  toggleComments = () => {
    this.setState(
      {
        openCommentsFlag: !this.state.openCommentsFlag,
      },
      () => {
        this.props.toggleCommentsHandler(this.props.id);
      }
    );
  };

  toggleWriteCommentsHandler = () => {
    this.setState(
      {
        openCommentsFlag: !this.state.openCommentsFlag,
      },
      () => {
        this.props.toggleWriteCommentsHandler(this.props.id);
      }
    );
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.openCommentsFlag !== this.props.openCommentsFlag &&
      this.props.openCommentsFlag === true
    ) {
      this.setState({
        openCommentsFlag: this.props.openCommentsFlag,
      });
    }
  };

  render() {
    let minimum_size = 3;
    let comments = this.props.comments.sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    if (this.props.showMore) comments = comments.slice(0, minimum_size);
    return (
      <div className=" bootstrap snippets comment-widget">
        <div className="comment-wrapper">
          {this.props.type !== "general" && (
            <h2 className={`comments-group`}>
              <span
                className="comments-count-info"
                onClick={this.toggleComments}
              >
                Comments
                {this.props.totalCount > 0 && (
                  <>({this.props.totalCount})</>
                )}{" "}
                {this.state.openCommentsFlag && (
                  <span className="material-icons-round top-icon">
                    arrow_drop_up
                  </span>
                )}
                {!this.state.openCommentsFlag && (
                  <span className="material-icons-round top-icon">
                    arrow_drop_down
                  </span>
                )}
              </span>
              {this.props.writeComments && this.props.isCommentAddAllowed && (
                <span
                  className="comments-add-btn"
                  onClick={() => {
                    this.toggleWriteCommentsHandler(this.props.id);
                  }}
                  data-for={`tooltip-${Slugify(
                    constants.MY_BOARDS.COMMENT.ADD_BUTTON_TOOLTIP
                  )}-${this.props.id}`}
                  data-tip={constants.MY_BOARDS.COMMENT.ADD_BUTTON_TOOLTIP}
                  data-iscapture="true"
                >
                  <span className="material-icons-round add-icon top-icon">
                    add
                  </span>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.MY_BOARDS.COMMENT.ADD_BUTTON_TOOLTIP
                    )}-${this.props.id}`}
                    multiline={true}
                  />
                </span>
              )}
            </h2>
          )}
          <Collapse isOpen={this.state.openCommentsFlag}>
            <div className="comments-list-group">
              {this.props.comments.length > 0 && (
                <>
                  {this.state.openCommentsFlag && (
                    <ul className={`list-group ${this.props.type}`}>
                      {comments.map((comment, key) => (
                        <BoardDetailsComment
                          id={this.props.id}
                          key={comment.id}
                          comment={comment}
                          toggleCommentTypeDropdown={
                            this.props.toggleCommentTypeDropdown
                          }
                          toggleCommentType={this.props.toggleCommentType}
                          isCommentViewAllowed={this.props.isCommentViewAllowed}
                          isCommentAddAllowed={this.props.isCommentAddAllowed}
                          isCommentEditAllowed={this.props.isCommentEditAllowed}
                          isCommentDeleteAllowed={
                            this.props.isCommentDeleteAllowed
                          }
                          isCommentStatusChangeAllowed={
                            this.props.isCommentStatusChangeAllowed
                          }
                          handleOpenEditComment={
                            this.props.handleOpenEditComment
                          }
                          handleCommentChange={this.props.handleCommentChange}
                          handleDeleteComment={this.props.handleDeleteComment}
                          handleCloseEditComment={
                            this.props.handleCloseEditComment
                          }
                          handleSaveEditComment={
                            this.props.handleSaveEditComment
                          }
                        />
                      ))}
                    </ul>
                  )}
                  {this.state.openCommentsFlag &&
                    this.props.showMore &&
                    this.props.totalCount > minimum_size && (
                      <div className="row">
                        <div className="col-lg-12 text-center mt-1 mb-3">
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-xs d-block m-auto btn-show-more-comments d-inline-flex align-items-center"
                            onClick={() => {
                              this.props.showMoreHandler(this.props.id);
                            }}
                          >
                            <div className="d-inline-flex align-items-center">
                              <span class="material-icons-outlined icon-fs-14 mr-1">
                                refresh
                              </span>
                              View More
                            </div>
                          </button>
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
            {this.state.openCommentsFlag && (
              <div>
                {this.props.writeComments &&
                  this.props.writeCommentsFlag &&
                  this.props.isCommentAddAllowed && (
                    <AddBoardDetailsComment
                      id={this.props.id}
                      comment={this.props.comment}
                      handleCommentChangeHandler={
                        this.props.handleCommentChangeHandler
                      }
                      handleCommentTypeChangeHandler={
                        this.props.handleCommentTypeChangeHandler
                      }
                      is_public={this.props.is_public}
                      handleCommentSaveHandler={
                        this.props.handleCommentSaveHandler
                      }
                    />
                  )}
              </div>
            )}
          </Collapse>
        </div>
      </div>
    );
  }
}

export default BoardDetailsComments;
