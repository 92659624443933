import decodeJWT from "../lib/decodeJWT";
import constants from "../Utils/constants";
function checkPermission(resource, actions) {
    let getPermission = decodeJWT(localStorage.getItem("token")).permissions;
    let permissionArray;

    switch (resource) {
        case constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE:
            permissionArray = getPermission[constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE]===undefined? 0: getPermission[constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE];
            break;
        default:
            return false;
    }
    return permissionArray===0? false :permissionArray.includes(actions)
}

export default checkPermission;