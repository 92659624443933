import decodeJWT from "../lib/decodeJWT";
import constants from "../Utils/constants";
function isRoleMember() {
    let roleDetails= decodeJWT(localStorage.getItem("token")).role;
    if(roleDetails)
    {
       return (roleDetails.short_name===constants.ROLES.CLIENT_USER.MEMBER)
    }
    return false
}

export default isRoleMember;