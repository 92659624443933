import React from "react";
import CustomCheckBox from "../CustomCheckBox";

function SegmentSection({
  disableSegmentBox,
  segmentList,
  selectedSegments,
  expandedSegments,
  setExpandedSegments,
  handleBySegmentTrigger,
  addMetrics,
  setDisableSegmentBox,
  setSelectedSegments,
  setSegmentList,
  setDisabledCompanyBox,
  paramId
}) {
  return (
    <div
      className="col-md-3 add-segments-block"
      style={{
        backgroundColor: disableSegmentBox
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(243, 243, 243, 0.5)",
        cursor: disableSegmentBox ? "pointer" : "not-allowed",
        // opacity: disableSegmentBox ? 1 : 0.5
      }}
    >
      <div
        className={`text-grey block-title font-weight-normal mb-0 d-flex justify-between align-items-center`}
      >
        <h6 className="text-grey font-weight-normal mb-0">Select Segments</h6>
      </div>
      <div className="segments-tree custom-checkbox-tree">
        <CustomCheckBox
          nodes={segmentList}
          checked={selectedSegments}
          expanded={expandedSegments}
          onExpand={(expanded) => setExpandedSegments(expanded)}
          onCheck={(checked) => handleBySegmentTrigger(checked)}
          key={"Segments"}
          disabled={!disableSegmentBox}
          is_tree={true}
          width={"380px"}
        />
      </div>

      {disableSegmentBox && (
        <div
          style={{
            // position: "absolute",
            bottom: "0%",
            height: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 10 0 10",
            justifyContent: "space-between",
            gap: "10px",
            background: "#ffffff",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "#FBFBFB",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setDisableSegmentBox(false);
              setDisabledCompanyBox(true);
              if (!paramId) {
                setSelectedSegments([]);
              }
              setSegmentList([]);
            }}
          >
            <span
              className="material-icons-outlined add-icon"
              // data-testid={`checkbox-arrow-segment`}
              style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
            >
              arrow_backward
            </span>
            <span
              style={{ fontSize: "14px", fontWeight: 400, color: "#606B77" }}
            >
              Back
            </span>
          </div>
          <div
            style={{
              width: "50%",
              backgroundColor: "#DCEDFF",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
              cursor: selectedSegments.length ? "pointer" : "not-allowed",
            }}
          onClick={(e) => selectedSegments.length > 0 && addMetrics(e)}
          >
            <span
              style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
            >
              Next
            </span>
            <span
              className="material-icons-outlined add-icon"
              style={{ color: "#2E6AD2", fontSize: "18px" }}
            >
              arrow_forward
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default SegmentSection;
