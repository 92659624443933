import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/ecosystemDetailsReference'

const ecosystemCategories = DETAILS_REF.ecosystemCategories
const ecosystemDetails = DETAILS_REF.ecosystemDetails
const categories = DETAILS_REF.categories
const ecosystemContent = DETAILS_REF.ecosystemContent


const ecosystemDetailsReducer = combineReducers({
    ecosystemCategories,
    ecosystemDetails,
    categories,
    ecosystemContent
})

export default ecosystemDetailsReducer
