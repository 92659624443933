import { ecosystem_details as DETAILS } from '../Reducers/actionConstants'

const ecosystemCategories = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_CATEGORIES,
    payload,
  }
}


const ecosystemDetails = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const ecosystemContent = (payload) => {
  return {
    type: DETAILS.ECOSYSTEM_CONTENT,
    payload,
  }
}


export {
  ecosystemCategories,
  ecosystemDetails,
  categories,
  ecosystemContent
}
