import React, { useState, useRef, useEffect } from 'react';
import decodeJWT from '../lib/decodeJWT.js';
import SessionModalPopup from './SessionModalPopup';
import { UserServices } from '../Services/User';
import Toast from '../Utils/Toast';
import constants from '../Utils/constants';

const defaultModalState = {
    dailogModal: false,
    dailogModalHeader: <h4 className="font-weight-bold mb-1 text-darkblue2">Your session is about to expire, would you like to extend?</h4>,
    dailogModalContent: <h4 className="font-weight-bold mb-1 text-darkblue2">...</h4>,
    dailogModalStyleType: 'success-modal',
    config: true
}

export default function SessionPopup() {
    const [dailog, setDailog] = useState(defaultModalState);
    const timerSetTimeoutRef = useRef(null);
    const countTimerSetTimeoutRef = useRef(null);
    const refreshTokenExpiresAtRef = useRef(null);

    useEffect(() => {
        setTokenPopupTimer();
        window.addEventListener(constants.SESSION_POP_UP.EVENT, () => {
            setDailog(defaultModalState);
            setTokenPopupTimer();
        })
        return () => {
            clearTimers();
        }
    }, [])

    useEffect(() => {
        if (dailog.dailogModal) {
            start_timer();
        }
        else {
            clearInterval(refreshTokenExpiresAtRef.current);
        }
    }, [dailog.dailogModal])

    const start_timer = () => {
        countTimerSetTimeoutRef.current = setTimeout(() => {
            const present_epoch_time = parseInt(new Date().getTime() / 1000);
            const refresh_token_expires_at = refreshTokenExpiresAtRef.current;
            const time = refresh_token_expires_at - present_epoch_time;
            if (time < 0) {
                setSessionExpired();
            }
            else {
                setModalContent(time);
                start_timer();
            }
        }, 1000)
    }

    const setModalContent = (time) => {
        let {
            min,
            sec
        } = getMinAndSec(time);
        setDailog({
            ...dailog,
            dailogModalContent: <div>
                <h2 className="font-family-1 mb-0 font-weight-semibold text-black2 text-blue">
                    <span>{min}</span>
                    :
                    <span>{sec}</span>
                </h2>
            </div>
        })
    }

    const getMinAndSec = (time) => {
        let min = parseInt(time / 60);
        let sec = time % 60;
        if (sec < 10) {
            sec = `0${sec}`;
        }
        return {
            min,
            sec
        }
    }

    const setSessionExpired = () => {
        setDailog({
            ...dailog,
            dailogModal: true,
            dailogModalHeader: <h3 className="text-darkblue2 font-weight-bold">Your session is expired.</h3>,
            dailogModalContent: '',
            config: false
        })
        clearTimers();
    }

    const dailogModalAccept = () => {
        UserServices.getTokenFromRefreshToken()
            .then(res => {
                localStorage.setItem('token', res.data.token.access_token)
                localStorage.setItem('refresh_token', res.data.token.refresh_token);
                setDailog(defaultModalState);
                setTokenPopupTimer();
                Toast(constants.SESSION_POP_UP.RENEWED_SUCCESS, 'success')
            })
            .catch(error => {
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })
    }

    const dailogModalDecline = () => {
        setDailog(defaultModalState);
        clearTimers();
    }

    const clearTimers = () => {
        clearTimeout(countTimerSetTimeoutRef.current);
        clearTimeout(timerSetTimeoutRef.current);
    }

    const setTokenPopupTimer = () => {
        clearTimers();
        let refresh_token = localStorage.getItem('refresh_token');
        if (refresh_token) {
            let decoded_refresh_token = decodeJWT(refresh_token);
            if (Object.keys(decoded_refresh_token).length) {
                let refresh_token_expires_at = decoded_refresh_token.exp - 60;
                refreshTokenExpiresAtRef.current = refresh_token_expires_at;
                let current_date = new Date();
                let current_epoch = parseInt(current_date.getTime() / 1000);
                let show_pop_at_this_epoch_time = refresh_token_expires_at - constants.SESSION_POP_UP.OPEN_POP_UP_BEFORE_THESE_SEC;
                let remaining_seconds = refresh_token_expires_at - current_epoch;
                let timeout_seconds = show_pop_at_this_epoch_time - current_epoch;

                if (remaining_seconds && remaining_seconds > 0) {
                    if (current_epoch < show_pop_at_this_epoch_time) {
                        timerSetTimeoutRef.current = setTimeout(() => {
                            setDailog({
                                ...dailog,
                                dailogModal: true
                            })
                        }, timeout_seconds * 1000)
                    }
                    else {
                        setDailog({
                            ...dailog,
                            dailogModal: true
                        })
                    }
                }
            }
        }
        window.removeEventListener(constants.SESSION_POP_UP.EVENT, setTokenPopupTimer)
    }

    return (
        <SessionModalPopup
            isOpen={dailog.dailogModal}
            accept={dailogModalAccept}
            decline={dailogModalDecline}
            header={dailog.dailogModalHeader}
            content={dailog.dailogModalContent}
            modalStyleType={dailog.dailogModalStyleType}
            config={dailog.config}
        />
    )
}