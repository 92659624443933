import ifvisible from 'ifvisible.js';
import constants from './constants';
import gaEvent from '../Utils/GAEvents';

class CalculateTimeOnPage {
    constructor() {
        this.start = 0;
        this.end = 0;
        this.timerRef = null;
        this.beforeUnloadRef = null;
        ifvisible.setIdleDuration(7200);   //2hrs idle time
    }

    init = (customData) => {
        this.timerRef = setInterval(() => {
            if (ifvisible.now()) {
                this.start++;
            }
        }, 1000);
     
    }

    getTimeOnPage = () => {
        const time = this.start;
        this.start = 0;
        clearInterval(this.timerRef);
        return time;
    }

    sendTimeOnPageEvent = (customData) => {
        customData.value= this.getTimeOnPage();
        if(customData.value) {
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.USER_ENGAGEMENT_TIME, customData);
        }
    }

}

const calculateTimeOnPage = new CalculateTimeOnPage();

export default calculateTimeOnPage