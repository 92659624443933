import { board as DETAILS } from '../actionConstants'

const boards = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BOARDS:
      return action.payload
    default:
      return state
  }
}

const boardDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BOARD_DETAILS:
      return action.payload
    default:
      return state
  }
}

const boardContent = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BOARD_CONTENT:
      return action.payload
    default:
      return state
  }
}

const boardContentDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.BOARD_CONTENT_DETAILS:
      return action.payload
    default:
      return state
  }
}

const boardModes = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BOARD_MODES:
      return action.payload
    default:
      return state
  }
}

const boardContentComments = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BOARD_CONTENT_COMMENTS:
      return action.payload
    default:
      return state
  }
}

const boardGeneralComments = (state = [], action) => {
  switch (action.type) {
    case DETAILS.BOARD_GENERAL_COMMENTS:
      return action.payload
    default:
      return state
  }
}

const boardType=(state='',action)=>{
   switch(action.type)
   {
     case DETAILS.BOARD_TYPE:
       return action.payload
     default: 
       return state
  }
}

export {
  boards,
  boardDetails,
  boardContent,
  boardModes,
  boardContentComments,
  boardGeneralComments,
  boardContentDetails,
  boardType
}
