import React, { Component } from "react";
import ProjectDetailsCategories from "../Containers/projectDetailsCategories";
import ProjectDetailsContent from "../Containers/projectDetailsContent";
import { ProjectServices } from "../Services/Project";
import { connect } from "react-redux";
import {
  categories,
  projectDetails,
  projectContent,
} from "../Store/Actions/ProjectDetails";
import constants from "../Utils/constants";
import { activeMenuTab } from "../Store/Actions/User";
import { BoardServices } from "../Services/Board";
import { boards } from "../Store/Actions/Board";
import PinToBoard from "../Containers/PinToBoard";
import CustomBreadcrumb from "../Components/CustomBreadcrumb";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import HelpModal from "../Pages/HelpModal";
import Slugify from "../Utils/Slugify";
import DocumentTitle from "../Utils/DocumentTitle";
import Dailog from "../Components/Dailog";
import { CompanyServices } from "../Services/Company";
import ExportProjectDashboardFileFormatter from "../Utils/ExportProjectDashboardFileFormatter";
import Copy from "../Utils/Copy";
import Toast from "../Utils/Toast";
import { formatRoute } from "react-router-named-routes";
import gaEvent from "../Utils/GAEvents";
import AclAction from "../Components/ACL/AclAction";
import { SpecialReportServices } from "../Services/SpecialReports";

class ProjectDetails extends Component {
  state = {
    dailogModal: false,
    dailogModalContent: "",
    dailogModalHeader: "",
    dailogModalConfig: {},
    pinning_sub_category_id: "",
    projectInnerSidebarOpen: true,
    pinning_sub_category_name: "",
  };

  toggleProjectInnerSidebar = () => {
    this.setState({
      projectInnerSidebarOpen: !this.state.projectInnerSidebarOpen,
    });
  };

  componentDidMount() {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE);
    let projectId = this.props.match.params.id;
    this.initContent(projectId);
    this.callRecentlyView(projectId);
    gaEvent.init({
      moduleName: constants.APPLICATION_ROUTE.PROJECT.LIST.NAME,
      id: projectId,
      page_url:
        this.props.history.location.pathname +
        this.props.history.location.search,
    });
  }

  callRecentlyView = async (projectId) => {
    let body = {
      table_name: constants.APPLICATION_ROUTE.PROJECT.LIST.NAME,
      table_id: parseInt(projectId),
    };
    await SpecialReportServices.addRecentlyViewDetails(body).catch((error) => {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    });
  };

  initContent = (projectId) => {
    let contents = [];
    ProjectServices.getProjectContent(projectId)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let responseMeta = response?.meta ? JSON.parse(response.meta) : null;
        let responseCategories =
          response?.categories
            ?.map((res) => {
              let category = null;
              if (responseMeta) {
                category = responseMeta.find((k) => k.categoryId === res.id);
              }
              res.is_selected = true;
              res.is_open = true;
              if (res.subcategories) {
                res.subcategories = res.subcategories
                  .map((res_sub) => {
                    let subCategoryData = null;
                    if (category) {
                      subCategoryData = category.subCategories.find(
                        (k) => k.subCategoryId === res_sub.id
                      );
                    }

                    res_sub.is_selected = true;
                    res_sub.show_actions = false;
                    if (res_sub.contents) {
                      let c = res_sub.contents.map((c) => ({
                        ...c,
                        category_id: res.id,
                      }));
                      contents.push(...c);
                    }
                    if (category && subCategoryData) {
                      return { ...res_sub, ...subCategoryData };
                    } else {
                      return { ...res_sub };
                    }
                  })
                  .sort((a, b) => a.subCategoryIndex - b.subCategoryIndex);
              }
              if (category) {
                delete category.subCategories;
                return { ...res, ...category };
              } else {
                return { ...res };
              }
            })
            .sort((a, b) => a.categoryIndex - b.categoryIndex) ?? [];
        this.props.categories(responseCategories);
        this.props.projectContent(contents);
      })
      .catch((error) => {
        this.props.categories([]);
        this.props.projectContent([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });

    ProjectServices.getProjectDetails(projectId)
      .then((res) => {
        let response = res && res.data;
        this.props.projectDetails(response);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  componentWillUnmount = () => {
    this.props.categories([]);
    this.props.projectDetails({});
    this.props.projectContent([]);
    this.props.boards([]);
    gaEvent.trigger();
  };

  getTotalBoards = (sub_category_id, type) => {
    const mapping_id = this.props.project_details.projectDetails.project.id;
    BoardServices.getTotalBoards(mapping_id, sub_category_id, type)
      .then((res) => {
        let response = res && res.data && res.data.data;
        let finalresponse = [];
        response = response.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase()
            ? 1
            : b.name.toLowerCase() > a.name.toLowerCase()
            ? -1
            : 0
        );
        finalresponse.push({ id: "other", name: "+ Create New Board" });
        finalresponse.push(...response);
        finalresponse.forEach((res) => {
          res.is_selected = res.id === sub_category_id ? true : false;
        });
        this.props.boards(finalresponse);
      })
      .catch((error) => {
        this.props.boards([]);

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  togglePinBoardActions = (category_id, sub_category_id) => {
    let original_categories = [...this.props.project_details.categories];
    original_categories.forEach((res) => {
      if (res.id === category_id) {
        if (res.subcategories) {
          res.subcategories.forEach((res_sub) => {
            if (res_sub.id === sub_category_id)
              res_sub.show_actions = !res_sub.show_actions;
            else res_sub.show_actions = false;
          });
        }
      }
    });
    this.props.categories(original_categories);
  };

  addToBoardHandleSubCategory = async (
    category_id,
    sub_category_id,
    sub_category_name
  ) => {
    await this.getTotalBoards(sub_category_id, "project");
    let boards = [...this.props.board.boards];
    boards = boards.map((b) => ({ ...b, is_selected: false }));
    this.props.boards(boards);
    this.setState({
      pinning_category_id: category_id,
      pinning_sub_category_id: sub_category_id,
      pinning_sub_category_name: sub_category_name,
    });
  };

  closeBoardModal = () => {
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  successAddToBoard = () => {
    this.togglePinBoardActions(
      this.state.pinning_category_id,
      this.state.pinning_sub_category_id
    );
    Toast(constants.MY_BOARDS.PIN.SUCCESS, "success");
    this.setState({
      pinning_category_id: null,
      pinning_sub_category_id: null,
    });
  };

  handleDownload = () => {
    let projectDetails =
      this.props.project_details && this.props.project_details.projectDetails
        ? this.props.project_details.projectDetails.project
        : [];
    let original_categories = [...this.props.project_details.categories];
    let selected_subcategories = [];
    original_categories.forEach((c) => {
      c.subcategories.forEach((s) => {
        if (s.is_selected === true) selected_subcategories.push(s.id);
      });
    });
    let request = {
      mappingId: projectDetails.id,
      type: "project",
      source: "content",
      ext: "pdf",
      subcategoryIds: selected_subcategories,
      financialMetrics: {},
    };
    CompanyServices.downloadContent(request)
      .then((response) => {
        if (response.status === 200) {
          if (navigator.userAgent.match("CriOS")) {
            var reader = new FileReader();
            var out = new Blob([response.data], { type: "application/pdf" });
            reader.onload = function (e) {
              window.location.href = reader.result;
            };
            reader.readAsDataURL(out);
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            let fileName = ExportProjectDashboardFileFormatter(
              projectDetails.name
            );
            link.setAttribute("download", `${fileName}.pdf`);
            document.body.appendChild(link);
            link.click();
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW, {
              fileName: fileName,
              fileFormat: `pdf`,
              moduleName: constants.APPLICATION_ROUTE.PROJECT.LIST.NAME,
              id: this.props.match?.params?.id,
              pageUrl:
                this.props.history.location.pathname +
                this.props.history.location.search,
            });
          }
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  copyHandler = (author) => {
    let projectDetails =
      this.props.project_details && this.props.project_details.projectDetails
        ? JSON.parse(JSON.stringify(this.props.project_details.projectDetails))
        : {};
    projectDetails.project.project_manager.copied = true;
    this.props.projectDetails(projectDetails);
    Copy(author.email);
    setTimeout(() => {
      let pd = { project: JSON.parse(JSON.stringify(projectDetails.project)) };
      pd.project.project_manager.copied = false;
      this.props.projectDetails(pd);
    }, 2000);
  };

  breadcrumbs = () => {
    let projectDetails =
      this.props.project_details && this.props.project_details.projectDetails
        ? this.props.project_details.projectDetails.project
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.PROJECT.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: projectDetails?.id
          ? projectDetails.name
          : constants.APPLICATION_ROUTE.PROJECT.VIEW.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    let projectContent =
      this.props.project_details && this.props.project_details.projectContent
        ? this.props.project_details.projectContent
        : [];
    let projectDetails =
      this.props.project_details && this.props.project_details.projectDetails
        ? this.props.project_details.projectDetails.project
        : {};
    if (projectDetails?.id) DocumentTitle(`${projectDetails.name}`);

    return (
      <div
        className={`${
          this.state.projectInnerSidebarOpen
            ? "opened-innersidebar"
            : "closed-innersidebar"
        } main_content fixed-title-main-content`}
      >
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center project-details-page-title-sec fixed-title-section">
            <div className="breadcrumb-mb-0">
              <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
            </div>

            <div className="ml-auto">
              {projectDetails?.project_manager && (
                <div className="author_sec d-flex align-items-center">
                  <ul>
                    <li>
                      <span className="material-icons-outlined icon">
                        account_circle
                      </span>
                      <em>Project Manager</em>
                      <p>
                        <>
                          <a
                            href={`mailto:${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.email
                                : ""
                            }?subject=${projectDetails.name} Inquiry`}
                            data-for={`tooltip-${Slugify(
                              projectDetails.project_manager
                                ? projectDetails.project_manager.email
                                : ""
                            )}-details`}
                            data-tip={`${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.first_name
                                : ""
                            } ${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.last_name
                                : ""
                            }, ${
                              projectDetails.project_manager.designation ===
                              null
                                ? projectDetails.project_manager.role.name
                                : projectDetails.project_manager.designation
                            } <br/> (${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.email
                                : ""
                            })`}
                            data-iscapture="true"
                          >
                            {`${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.first_name
                                : ""
                            } ${
                              projectDetails.project_manager
                                ? projectDetails.project_manager.last_name
                                : ""
                            }, ${
                              projectDetails.project_manager.designation ===
                              null
                                ? projectDetails.project_manager.role.name
                                : projectDetails.project_manager.designation
                            }`}
                          </a>
                          <CustomReactTooltip
                            id={`tooltip-${Slugify(
                              projectDetails.project_manager
                                ? projectDetails.project_manager.email
                                : ""
                            )}-details`}
                            multiline={true}
                          />
                        </>
                        <>
                          {projectDetails.project_manager.copied && (
                            <>
                              <span
                                className="material-icons-outlined copy-icon"
                                data-for={`tooltip-${Slugify(
                                  projectDetails.project_manager.email
                                )}-copied`}
                                data-tip={`Copied`}
                                data-iscapture="true"
                              >
                                done
                              </span>
                              <CustomReactTooltip
                                id={`tooltip-${Slugify(
                                  projectDetails.project_manager.email
                                )}-copied`}
                                multiline={true}
                              />
                            </>
                          )}
                          {!projectDetails.project_manager.copied && (
                            <>
                              <span
                                className="material-icons-outlined copy-icon"
                                aria-hidden="true"
                                onClick={() => {
                                  this.copyHandler(
                                    projectDetails.project_manager
                                  );
                                }}
                                data-for={`tooltip-${Slugify(
                                  projectDetails.project_manager.email
                                )}-copy`}
                                data-tip={`Copy Email`}
                                data-iscapture="true"
                              >
                                content_copy
                              </span>
                              <CustomReactTooltip
                                id={`tooltip-${Slugify(
                                  projectDetails.project_manager.email
                                )}-copy`}
                                multiline={true}
                              />
                            </>
                          )}
                        </>
                      </p>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div>
              <HelpModal category="Project Details" />
            </div>
            {/* <AclAction
              type={constants.ACL.PERMISSION_KEY}
              entity={constants.ACL.PROJECT_RESOURCE.TITLE}
              action={constants.ACL.PROJECT_RESOURCE.ACTIONS.DOWNLOAD}
            >
              <aside
                tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                className="text-left"
              >
                <button
                  className={`icon-btn-primary icon-btn d-flex align-items-center justify-content-center ml-3 ${
                    projectContent.length
                      ? "company_details_download"
                      : "disabled"
                  }`}
                  data-for={`tooltip-${Slugify(
                    constants.PROJECT.VIEW.DOWNLOAD_TOOLTIP
                  )}`}
                  data-tip={constants.PROJECT.VIEW.DOWNLOAD_TOOLTIP}
                  data-iscapture="true"
                  onClick={
                    projectContent.length ? this.handleDownload : () => {}
                  }
                >
                  <span className="material-icons-round icon">download</span>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(
                      constants.PROJECT.VIEW.DOWNLOAD_TOOLTIP
                    )}`}
                    multiline={true}
                  />
                </button>
              </aside>
            </AclAction> */}
          </div>
          <div className="project_details">
            <ProjectDetailsCategories
              toggleProjectInnerSidebar={this.toggleProjectInnerSidebar}
            />
            <ProjectDetailsContent
              copyHandler={this.copyHandler}
              handleDownload={this.handleDownload}
              isRelatedInsightsVisible={false}
              togglePinBoardActions={this.togglePinBoardActions}
              addToBoard={this.addToBoardHandleSubCategory}
              aclActionVisible={this.props.aclActionVisible ? false : true} //add this for jest testing
            />
            <Dailog
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
            />
            {this.state.pinning_sub_category_id &&
              this.state.pinning_sub_category_id !== null && (
                <PinToBoard
                  type={`project`}
                  closeBoardModal={this.closeBoardModal}
                  getTotalBoards={this.getTotalBoards}
                  pinning_sub_category_id={this.state.pinning_sub_category_id}
                  successAddToBoard={this.successAddToBoard}
                subCategoryName={this.state.pinning_sub_category_name}

                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    project_details: state.project_details,
    home: state.home,
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: (payload) => {
      dispatch(categories(payload));
    },
    projectDetails: (payload) => {
      dispatch(projectDetails(payload));
    },
    projectContent: (payload) => {
      dispatch(projectContent(payload));
    },
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    boards: (payload) => {
      dispatch(boards(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
