import axios from "axios";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(process.env.REACT_APP_SSO_MS_GRAPH_API_URI, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function acquireTokenByCode({ code }) {
  const clientId = process.env.REACT_APP_SSO_CLIENT_ID;
  const tenantId = process.env.REACT_APP_SSO_TENANT_ID;
  const redirectUri = process.env.REACT_APP_SSO_REDIRECT_URI;
  const tokenEndpoint = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/token`;

  const codeVerifier = sessionStorage.getItem("code_verifier"); // Retrieve stored code_verifier

  const params = new URLSearchParams();
  params.append("client_id", clientId);
  params.append("scope", "https://graph.microsoft.com/.default");
  params.append("code", code);
  params.append("redirect_uri", redirectUri);
  params.append("grant_type", "authorization_code");
  params.append("code_verifier", codeVerifier); // Include the code_verifier

  try {
    const response = await axios.post(tokenEndpoint, params);
    return response.data;
  } catch (error) {
    console.error(
      "Error acquiring token by code:",
      error.response ? error.response.data : error
    );
    throw error;
  }
}
