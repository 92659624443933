import { notification as NOTIFICATION } from "../Reducers/actionConstants";

const notifications = (payload) => {
  return {
    type: NOTIFICATION.NOTIFICATION_LIST,
    payload,
  };
};

export { notifications };
