import { Axios } from "../lib/axios";

function getSegmentObjects(report_type, report_id) {
  return Axios.get(`historical-file/${report_type}/segments/${report_id}`);
}

function getHistoricalFileSegmentObjects(
  report_type,
  report_id,
  segment_type,
  segment_id
) {
  return Axios.get(
    `historical-file/${report_type}/segments/${report_id}/${segment_type}/${segment_id}`
  );
}

function downloadHistoricalFile(uuid) {
  return Axios.get(`/historical-file/download/${uuid}`, {
    responseType: 'blob',
  });
}
export const HistoricalFileService = {
  getSegmentObjects,
  getHistoricalFileSegmentObjects,
  downloadHistoricalFile
};
