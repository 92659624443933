import { Axios } from '../lib/axios'
export const SpecialReportServices = {
  getSpecialReport,
  getSpecialReportDetails,
  getSpecialReportContent,
  addRecentlyViewDetails
}
function getSpecialReport(url) {
  return Axios.get(url)
}

function getSpecialReportDetails(specialreportId) {
  return Axios.get(`specialreport/${specialreportId}`)
}
function getSpecialReportContent(specialreportId) {
  return Axios.get(`specialreport/${specialreportId}/content`)
}
function addRecentlyViewDetails(body) {
  return Axios.put(`updateRecentlyView`, body);
}
function getSpecialReports(url)
{
  return Axios.get(url)
}
export const SpecialReportsServices = {
  getSpecialReports,
}

