import React from 'react'

class AccessDenied extends React.Component {
  render() {
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height'>
        <p>Access Denied</p>
        </div>
      </aside>
    )
  }
}

export default AccessDenied
