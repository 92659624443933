import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/visualization'

const chartType = DETAILS_REF.chartType;
const chartData = DETAILS_REF.chartData;
const selectedElements = DETAILS_REF.selectedElements;
const chartAxisDetails = DETAILS_REF.chartAxisDetails;
const storeSeriesNames = DETAILS_REF.storeSeriesNames;

const visualizationReducer = combineReducers({
    chartType,
    chartData,
    selectedElements,
    chartAxisDetails,
    storeSeriesNames
})

export default visualizationReducer
