import React from "react";
import profile_image from "../Images/profile-image.png";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import {
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from "react-redux";
import CustomReactTooltip from "../Components/CustomReactTooltip";

const BoardDetailsComment = (props) => {
  let comment = props.comment;
  let loggedInUserId = props.home.loginUserSuccess.id;
  let boardSharedType = props.board.boardDetails.type;
  let actionAllowed =
    boardSharedType === "owner" || loggedInUserId === comment.created_by;
  let commentOwner = loggedInUserId === comment.created_by;
  let commentOwnerName = comment.created_by_user
    ? `${comment.created_by_user.first_name} ${comment.created_by_user.last_name}`
    : "";
  return (
    <li
      style={{ marginTop: "-15px" }}
      className="list-group-item"
      key={comment.id}
    >
      <div className="row mb-0">
        <div className="col-lg-12 col-sm-12 col-md-12 pl-2 col-12">
          <div className="profile_image">
            <img
              src={profile_image}
              className="img-circle img-responsive"
              alt=""
              data-for={`tooltip-${comment.id}-profile-info`}
              data-tip={commentOwnerName}
              data-iscapture="true"
            />
            <CustomReactTooltip
              id={`tooltip-${comment.id}-profile-info`}
              multiline={true}
            />
          </div>
          <div className="comment_content">
            <span className="text-muted comment-date small">
              {comment.created_at
                ? ComapnyDetailsDateTimeFormatter(comment.created_at)
                : ComapnyDetailsDateTimeFormatter(comment.created_at)}
            </span>
            <ButtonGroup>
              <ButtonDropdown
                disabled={
                  !(
                    actionAllowed &&
                    props.isCommentStatusChangeAllowed &&
                    commentOwner
                  )
                }
                isOpen={
                  comment.isOpenCommentTypeDropdown
                    ? comment.isOpenCommentTypeDropdown
                    : false
                }
                toggle={(e) => {
                  props.toggleCommentTypeDropdown(e, props.id, comment.id);
                }}
              >
                <DropdownToggle
                  caret
                  className="btn-xs d-inline-flex align-items-center"
                  color="secondary-outline"
                >
                  {comment.is_public === 1 && (
                    <span className="material-icons icon text-black2 d-inline-flex fs-12">
                      public
                    </span>
                  )}
                  {comment.is_public === 0 && (
                    <span className="material-icons icon text-black2 d-inline-flex fs-12">
                      lock
                    </span>
                  )}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    disabled={
                      !(
                        actionAllowed &&
                        props.isCommentStatusChangeAllowed &&
                        commentOwner
                      )
                    }
                    className="btn-xs d-inline-flex align-items-center"
                    active={comment.is_public === 0 ? false : true}
                    onClick={(e) => {
                      props.toggleCommentType(e, props.id, comment.id, 1);
                    }}
                  >
                    <span className="material-icons icon d-inline-flex fs-12">
                      public
                    </span>{" "}
                    Public
                  </DropdownItem>
                  <DropdownItem
                    disabled={
                      !(
                        actionAllowed &&
                        props.isCommentStatusChangeAllowed &&
                        commentOwner
                      )
                    }
                    className="btn-xs d-inline-flex align-items-center"
                    active={comment.is_public === 0 ? true : false}
                    onClick={(e) => {
                      props.toggleCommentType(e, props.id, comment.id, 0);
                    }}
                  >
                    <span className="material-icons icon d-inline-flex fs-12">
                      lock
                    </span>{" "}
                    Only me
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </ButtonGroup>
            {comment.updated_at && (
              <span className="text-muted comment-date small ml-1 text-underline">
                Edited
              </span>
            )}
            <div className="align-items-center btn-group d-flex float-right">
              {actionAllowed &&
                props.isCommentEditAllowed &&
                commentOwner &&
                !comment.editToggle && (
                  <span
                    className="material-icons-round text-muted edit-action icon-fs-16"
                    onClick={(e) => {
                      props.handleOpenEditComment(
                        e,
                        props.id,
                        comment.id,
                        comment.type
                      );
                    }}
                    data-for={`tooltip-${comment.id}-edit-comment`}
                    data-tip={"Edit"}
                    data-iscapture="true"
                  >
                    edit
                  </span>
                )}
              {actionAllowed &&
                props.isCommentDeleteAllowed &&
                !comment.editToggle && (
                  <span
                    className="material-icons-round ml-2 text-muted delete-action icon-fs-18"
                    onClick={(e) => {
                      props.handleDeleteComment(
                        e,
                        props.id,
                        comment.id,
                        comment.type
                      );
                    }}
                    data-for={`tooltip-${comment.id}-delete-comment`}
                    data-tip={"Delete"}
                    data-iscapture="true"
                  >
                    close
                  </span>
                )}
              <CustomReactTooltip
                id={`tooltip-${comment.id}-delete-comment`}
                multiline={true}
              />
              <CustomReactTooltip
                id={`tooltip-${comment.id}-edit-comment`}
                multiline={true}
              />
            </div>
            <div className="comment-text">
              {!comment.editToggle && (
                <span
                  onClick={(e) => {
                    props.handleOpenEditComment(
                      e,
                      props.id,
                      comment.id,
                      comment.type
                    );
                  }}
                >
                  {" "}
                  {comment.comment}
                </span>
              )}
              {actionAllowed &&
                props.isCommentEditAllowed &&
                commentOwner &&
                comment.editToggle === true && (
                  <>
                    <textarea
                      rows={2}
                      className={"form-control txtComments"}
                      value={comment.txtComment}
                      onChange={(e) => {
                        props.handleCommentChange(
                          e,
                          props.id,
                          comment.id,
                          comment.type
                        );
                      }}
                    ></textarea>
                    <div className="btn-group float-right mt-2">
                      <span
                        className="material-icons-round text-muted cancel-edit-action icon-fs-16"
                        onClick={(e) => {
                          props.handleCloseEditComment(
                            e,
                            props.id,
                            comment.id,
                            comment.type
                          );
                        }}
                        data-for={`tooltip-${comment.id}-cancel-save`}
                        data-tip={"Cancel"}
                        data-iscapture="true"
                      >
                        close
                      </span>

                      <span
                        className="material-icons-round text-muted save-edit-action icon-fs-16 mx-1"
                        onClick={(e) => {
                          props.handleSaveEditComment(
                            e,
                            props.id,
                            comment.id,
                            comment.type
                          );
                        }}
                        data-for={`tooltip-${comment.id}-save-update`}
                        data-tip={"Save"}
                        data-iscapture="true"
                      >
                        done
                      </span>
                      <CustomReactTooltip
                        id={`tooltip-${comment.id}-cancel-save`}
                        multiline={true}
                      />
                      <CustomReactTooltip
                        id={`tooltip-${comment.id}-save-update`}
                        multiline={true}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    board: state.board,
  };
};

export default connect(mapStateToProps, null)(BoardDetailsComment);
