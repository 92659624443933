import { combineReducers } from "redux";
import * as HOME_REF from "./reducerReference/homeReference";

const loginUserSuccess = HOME_REF.loginUserSuccess;
const loginUserFailure = HOME_REF.loginUserFailure;
const segments = HOME_REF.segments;
const activeMenuTab = HOME_REF.activeMenuTab;
const openSearchBar = HOME_REF.openSearchBar;
const getSearchText = HOME_REF.getSearchText;
const displayName = HOME_REF.displayName;

const homeReducer = combineReducers({
  loginUserSuccess,
  loginUserFailure,
  segments,
  activeMenuTab,
  openSearchBar,
  getSearchText,
  displayName
});

export default homeReducer;
