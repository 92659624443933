import { Axios } from "../lib/axios";

export const FeedBackService = {
  addFeedBack,
  showFeedBack,
  askMeLater
};

function addFeedBack(body) {
  return Axios.post(`feedback`, body);
}

function showFeedBack() {
  return Axios.get('showFeedbackForm')

}

function askMeLater() {
  return Axios.post("askMeLater")
}
