import { Axios } from '../lib/axios'
export const MarketForecastServices = {
  getMarketForecasts,
  getCategories,
  getMarketForecastDetails,
  getMarketForecastContent
}
function getMarketForecasts(url) {
  return Axios.get(url)
}
function getCategories(url) {
  return Axios.get('https://jsonblob.com/api/4fc0ad9c-77d8-11ea-aec7-fb5b61093b79')
}
function getMarketForecastDetails(marketforecastId) {
  return Axios.get(`marketforecast/${marketforecastId}`)
}
function getMarketForecastContent(marketforecastId) {
  return Axios.get(`marketforecast/${marketforecastId}/content`)
}

