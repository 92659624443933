import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import ScrollToTopBtn from "../Components/ScrollToTop";
import TemplateParser from "../Components/TemplateParser";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Slugify from "../Utils/Slugify";
import Collapsible from "react-collapsible";
import { categories } from "../Store/Actions/EcosystemDetails";
import { debounce } from "lodash";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import permissionCheck from "../lib/permissionCheck";
import CustomTitle from "../Components/CustomTitle";
import { withRouter } from "react-router-dom";
import BoardLoadEventRedirect from "../Utils/BoardLoadEventRedirect";

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () =>
    elRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  return { executeScroll, elRef };
};

class EcosystemDetailsContent extends Component {
  state = {
    showMore: false,
  };
  loadCompleted = debounce(() => {
    if (this.elScroll.elRef.current != null) {
      this.elScroll.executeScroll();
    }
  }, 1000);

  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.showMoreRef = React.createRef();
    this.elScroll = utilizeScroll();
  }

  componentDidMount() {
    this.loadCompleted();
    const { location } = this.props;
    setTimeout(() => {
      BoardLoadEventRedirect(location);
    }, 2200);
  }

  componentDidUpdate() {
    this.handleOverFlow();
    this.loadCompleted();
  }

  handleShowMore = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  handleOverFlow = () => {
    let element = this.divRef;
    if (element.current && this.showMoreRef.current) {
      if (
        element.current.offsetHeight < element.current.scrollHeight &&
        !this.state.showMore
      ) {
        this.showMoreRef.current.classList.remove("d-none");
      } else {
        this.showMoreRef.current.classList.add("d-none");
      }
    }
  };

  breadcrumbs = () => {
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? this.props.ecosystem_details.ecosystemDetails
        : {};
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.NAME,
        link: formatRoute(constants.APPLICATION_ROUTE.ECOSYSTEM.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: ecosystemDetails.id
          ? ecosystemDetails.name
          : constants.APPLICATION_ROUTE.ECOSYSTEM.VIEW.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };

  render() {
    let ecosystemContent =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemContent
        ? this.props.ecosystem_details.ecosystemContent
        : [];
    let categories =
      this.props.ecosystem_details && this.props.ecosystem_details.categories
        ? this.props.ecosystem_details.categories
        : [];
    let ecosystemDetails =
      this.props.ecosystem_details &&
      this.props.ecosystem_details.ecosystemDetails
        ? this.props.ecosystem_details.ecosystemDetails.ecosystem
        : {};

    if (ecosystemDetails?.id) DocumentTitle(`${ecosystemDetails.name}`);
    let isDownloadAccess = permissionCheck(
      constants.ACL.PERMISSION_KEY,
      constants.ACL.ECOSYSTEM_RESOURCE.TITLE,
      constants.ACL.ECOSYSTEM_RESOURCE.ACTIONS.DOWNLOAD
    );
    return (
      <aside className="dash_board_content inner-sidebar-right-content">
        <ScrollToTopBtn />
        {isDownloadAccess && (
          <UncontrolledPopover
            placement="bottom"
            target="PopoverLegacy7"
            trigger="legacy"
            className="custom-popover-2"
          >
            <PopoverBody>
              <div className="d-flex flex-column company_details_more_options">
                <span onClick={this.props.documentModalTrue}>
                  <span className="material-icons-outlined mr-2 icon">
                    file_copy
                  </span>
                  <span>Files</span>
                </span>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        )}
        {ecosystemContent && (
          <div className="">
            <div className="row company_details">
              <div
                className={
                  "" +
                  (this.props.isRelatedInsightsVisible
                    ? "col-lg-10"
                    : "col-lg-12")
                }
              >
                {ecosystemDetails?.id &&
                  ecosystemDetails.companies &&
                  ecosystemDetails.companies.length > 0 && (
                    <div className="segments-card-wrapper btn-items-card-wrapper">
                      <div
                        ref={this.divRef}
                        className={
                          this.state.showMore
                            ? "card custom-card segments-card btn-items-card open-card"
                            : "card custom-card segments-card btn-items-card closed-card"
                        }
                      >
                        <div class="card-body">
                          <div className="d-flex">
                            <h3 className="font-weight-semibold mb-0 text-black2 title">
                              Companies
                            </h3>
                            <ul className="segment-items btn-items d-flex flex-wrap">
                              {ecosystemDetails.companies
                                .sort((a, b) =>
                                  a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                                )
                                .map((company, index) => (
                                  <li key={company.id}>
                                    <div
                                      className="content font-weight-semibold d-flex align-items-center"
                                      onClick={() => {
                                        this.props.goToCompanyDashboard(
                                          company.allowed_access === 1 ||
                                            (company.segments &&
                                              company.segments.length > 0 &&
                                              company.segments.find(
                                                (s) => s.allowed_access === 1
                                              )),
                                          company
                                        );
                                      }}
                                    >
                                      {company.allowed_access === 1 ||
                                      (company.segments &&
                                        company.segments.length > 0 &&
                                        company.segments.find(
                                          (s) => s.allowed_access === 1
                                        )) ? null : (
                                        <span class="icon-fs-18 material-icons-round mr-2 not-allowed-access">
                                          lock
                                        </span>
                                      )}
                                      <span>{company.name}</span>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                            <div className="align-self-start d-flex mt-1 show-more-btn ml-auto">
                              <button
                                ref={this.showMoreRef}
                                type="button"
                                class="btn btn-link font-weight-semibold d-none"
                                onClick={this.handleShowMore}
                              >
                                show more
                              </button>
                              {this.state.showMore ? (
                                <span
                                  class="material-icons-round close-icon"
                                  onClick={this.handleShowMore}
                                >
                                  close
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {ecosystemDetails && ecosystemDetails?.is_active === 0 && (
                  <div className="mb-2 btn-items-card-wrapper">
                    <div className="card custom-card segments-card btn-items-card">
                      <div className="card-body alert-warning">
                        <div className="d-flex align-items-center">
                          <span className="material-icons-round mr-2 icon-fs-16">
                            report_problem
                          </span>
                          <span>
                            Attention: This ecosystem is inactive and not
                            currently updated by TBR.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {ecosystemContent.length == 0 &&
                  ecosystemDetails?.is_active != 0 && (
                    <div className="row mt-1">
                      <div className="col-12">
                        <div
                          className="alert alert-secondary d-flex align-items-center"
                          role="alert"
                        >
                          <span className="material-icons-round mr-2 icon-fs-16">
                            report_problem
                          </span>
                          Attention: No Content Found.
                        </div>
                      </div>
                    </div>
                  )}

                <div
                  className={`${
                    ecosystemDetails?.id &&
                    ecosystemDetails.companies &&
                    ecosystemDetails.companies.length > 0
                      ? "item_details_scroll"
                      : "no-segments-card-wrapper"
                  }`}
                >
                  {ecosystemContent.length > 0 && (
                    <>
                      {categories.map(
                        (category, index) =>
                          ecosystemContent.find(
                            (cc) => cc.category_id === category.id
                          ) && (
                            <>
                              <div className="custom-collapser custom-collapser2 marketforecast-details-collapser details-page-collapser">
                                <div
                                  className={`category-wrapper ${
                                    category.subcategories.find(
                                      (s) => s.is_selected === true
                                    )
                                      ? ""
                                      : "d-none"
                                  }`}
                                  id={`${Slugify(category.name)}_${
                                    category.id
                                  }`}
                                >
                                  <Collapsible
                                    open={category.is_open}
                                    handleTriggerClick={() => {
                                      let cat = categories.findIndex(
                                        (c) => c.id === category.id
                                      );
                                      categories[cat].is_open =
                                        !categories[cat].is_open;
                                      this.props.categories([...categories]);
                                    }}
                                    trigger={
                                      <div className="collapse-trigger">
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div className="d-flex align-items-center">
                                            <CustomTitle
                                              tag="h3"
                                              className="font-weight-bold mb-0 title category_name"
                                              title={category.name}
                                              title_html={category.name_html}
                                            />
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <div className="collapse-icon-wrapper">
                                              <span class="material-icons-round add icon">
                                                add
                                              </span>
                                              <span class="material-icons-round remove icon">
                                                remove
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    }
                                  >
                                    {category.subcategories.map(
                                      (sub_category, sub_index) =>
                                        ecosystemContent &&
                                        ecosystemContent.length > 0 &&
                                        ecosystemContent.filter(
                                          (segment) =>
                                            segment.subcategory_id ===
                                            sub_category.id
                                        ).length > 0 &&
                                        sub_category.is_selected && (
                                          <section
                                            name={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            id={`${Slugify(
                                              sub_category.name
                                            )}_${sub_category.id}`}
                                            className={`row ${
                                              sub_category.mode
                                            } ${
                                              sub_category.is_selected
                                                ? ""
                                                : "d-none"
                                            }`}
                                            key={sub_index}
                                          >
                                            <div
                                              className="tbr_position col-12"
                                              key={sub_index}
                                            >
                                              <div className="collapse-panel-content">
                                                <div className="sub_category_heading d-flex align-items-center">
                                                  <CustomTitle
                                                    tag="h3"
                                                    className="heading mb-0"
                                                    title={sub_category.name}
                                                    title_html={
                                                      sub_category.name_html
                                                    }
                                                  />
                                                  {ecosystemContent.filter(
                                                    (segment) =>
                                                      segment.subcategory_id ===
                                                      sub_category.id
                                                  )[0].published_date && (
                                                    <div className="d-flex align-items-center">
                                                      <div className="published-date">
                                                        Published:{" "}
                                                        {ComapnyDetailsDateTimeFormatter(
                                                          ecosystemContent.filter(
                                                            (segment) =>
                                                              segment.subcategory_id ===
                                                              sub_category.id
                                                          )[0].published_date
                                                        )}
                                                      </div>
                                                      {this.props
                                                        .aclActionVisible ? (
                                                        <AclAction
                                                          type={
                                                            constants.ACL
                                                              .PERMISSION_KEY
                                                          }
                                                          entity={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .TITLE
                                                          }
                                                          action={
                                                            constants.ACL
                                                              .MY_BOARDS_RESOURCE
                                                              .ACTIONS.PIN
                                                          }
                                                        >
                                                          <div
                                                            tbrentitytype={
                                                              constants.ACL
                                                                .ENTITY_TYPE
                                                                .BUTTON
                                                            }
                                                            className={`add_to_board ${
                                                              sub_category.show_actions
                                                                ? "active"
                                                                : ""
                                                            }`}
                                                          >
                                                            <div
                                                              className="add_to_board_box align-items-center justify-content-center d-flex"
                                                              onClick={() => {
                                                                this.props.addToBoard(
                                                                  category.id,
                                                                  sub_category.id,
                                                                  sub_category.name
                                                                );
                                                              }}
                                                            >
                                                              <span
                                                                class="material-icons-outlined"
                                                                data-for={`tooltip-${Slugify(
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                )}`}
                                                                data-tip={
                                                                  constants
                                                                    .APPLICATION_ROUTE
                                                                    .MY_BOARDS
                                                                    .PIN_TO_BOARD
                                                                    .TOOLTIP
                                                                }
                                                                data-iscapture="true"
                                                              >
                                                                <CustomReactTooltip
                                                                  id={`tooltip-${Slugify(
                                                                    constants
                                                                      .APPLICATION_ROUTE
                                                                      .MY_BOARDS
                                                                      .PIN_TO_BOARD
                                                                      .TOOLTIP
                                                                  )}`}
                                                                  multiline={
                                                                    true
                                                                  }
                                                                />
                                                                push_pin
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </AclAction>
                                                      ) : (
                                                        <div
                                                          tbrentitytype={
                                                            constants.ACL
                                                              .ENTITY_TYPE
                                                              .BUTTON
                                                          }
                                                          className={`add_to_board ${
                                                            sub_category.show_actions
                                                              ? "active"
                                                              : ""
                                                          }`}
                                                        >
                                                          <div
                                                            className="add_to_board_box align-items-center justify-content-center d-flex"
                                                            onClick={() => {
                                                              this.props.addToBoard(
                                                                category.id,
                                                                sub_category.id,
                                                                sub_category.name
                                                              );
                                                            }}
                                                          >
                                                            <span class="material-icons-outlined">
                                                              push_pin
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="sub-category-content">
                                                  {ecosystemContent
                                                    .filter(
                                                      (segment) =>
                                                        segment.subcategory_id ===
                                                          sub_category.id &&
                                                        segment.is_deleted === 0
                                                    )
                                                    .map(
                                                      (
                                                        sub_category1,
                                                        sub_index1
                                                      ) => (
                                                        <div
                                                          ref={
                                                            sub_category1.id ===
                                                              this.props
                                                                .contentId &&
                                                            this.elScroll.elRef
                                                          }
                                                        >
                                                          {sub_category1.subcategory_title && (
                                                            <div
                                                              className="desipte_sec"
                                                              key={
                                                                sub_category1.id
                                                              }
                                                            >
                                                              <CustomTitle
                                                                tag="h4"
                                                                className="heading mb-3 font-default"
                                                                title={
                                                                  sub_category1.subcategory_title
                                                                    ? sub_category1.subcategory_title
                                                                    : ""
                                                                }
                                                                title_html={
                                                                  sub_category1.subcategory_title_html
                                                                }
                                                              />
                                                            </div>
                                                          )}
                                                          <TemplateParser
                                                            key={`temp-${sub_category1.id}`}
                                                            template={
                                                              sub_category1
                                                                .template
                                                                .content
                                                            }
                                                            content={
                                                              sub_category1.data
                                                            }
                                                          />
                                                        </div>
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        )
                                    )}
                                  </Collapsible>
                                </div>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                </div>
              </div>
              {this.props.isRelatedInsightsVisible && (
                <aside className="col-lg-2">
                  <div className="related_insights">
                    <h3>Related Insights</h3>
                    <ol className="list_style">
                      <li>
                        <a href="/">Related Insights-1</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-2</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-3</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-4</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-5</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-6</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-7</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-8</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-9</a>
                      </li>
                      <li>
                        <a href="/">Related Insights-10</a>
                      </li>
                    </ol>
                  </div>
                </aside>
              )}
            </div>
          </div>
        )}
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ecosystem_details: state.ecosystem_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categories: async (payload) => {
      await dispatch(categories(payload));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EcosystemDetailsContent)
);
