import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";

import TreeComponent from "../Components/TreeComponent";
import { CompanyServices } from "../Services/Company";
import { activeMenuTab } from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";

class ListTaxonomies extends Component {
  state = {
    finalObject: [],
  };

  componentDidMount = () => {
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.TAXONOMY.LIST.ROUTE);
    DocumentTitle(constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME);
    let finalObject = [];
    function looper(obj, parent) {
      obj.forEach((obj1) => {
        if (parent !== 0) obj1.id = parent + "_" + obj1.id;
        finalObject.push({
          id: obj1.id,
          name: obj1.name,
          parent_id: parent,
          definition: obj1.description ? obj1.description : "",
        });
        if (obj1.terms && obj1.terms.length > 0) {
          looper(obj1.terms, obj1.id);
        }
      });
    }
    CompanyServices.getTaxonomies()
      .then((res) => {
        looper(res.data, 0);
        finalObject.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        
        this.setState({
          finalObject: finalObject,
        });
      })
      .catch((error) => {
        
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false,
      },
      {
        title: constants.APPLICATION_ROUTE.TAXONOMY.LIST.NAME,
        link: null,
        is_active: true,
      },
    ];
    return breadcrumbs;
  };
  render() {
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height'>
        <div className="taxonomy-page-block">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0">
          
            <h1 className="text-ligntblue2 font-weight-bold ">Taxonomy</h1>
           
            <div className="ml-auto">
              <HelpModal category="Taxonomy List" />
            </div>
          </div>

          <div className="taxonomy_page flex-grow-1">
            {this.state.finalObject.length > 0 && (
              <TreeComponent
                columns={[
                  { name: "name", title: "Segments / Terms" },
                  { name: "definition", title: "TBR Definition" },
                ]}
                filterColumns={["name"]}
                filteringColumnExtensions={[
                  { columnName: "definition", filteringEnabled: false },
                ]}
                tableColumnExtensions={[
                  { columnName: "name", width: "300", className: "asd" },
                  {
                    columnName: "definition",
                    wordWrapEnabled: true,
                    width: "900",
                  },
                ]}
                rows={this.state.finalObject}
                treeColumnName={"name"}
              />
            )}
          </div>
        </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListTaxonomies);
