import React, { Component, useState } from "react";
import DynamicTableComponent from "../Components/DynamicTableComponent";
import constants from "../Utils/constants";
import AclAction from "../Components/ACL/AclAction";
import Slugify from "../Utils/Slugify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Select, { components } from "react-select";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Toast from "../Utils/Toast";
import isRoleMember from "../Utils/isRoleMember";
import { HistoricalFileService } from "../Services/HistoricalFiles";
import { isObject, orderBy, uniqBy } from "lodash";
import { getFullYear } from "../Helpers/Helper";
import { delay } from "lodash";

const tableHeader = [
  {
    id: "name",
    title: "File Name",
    sortable: false,
    style: {
      textAlign: "left",
      textTransform: "capitalize",
      zIndex: "9",
    },
    rowStyle: {},
    // className: `company_name`,
  },
  {
    id: "download",
    title: "Download",
    sortable: false,
    style: {
      width: "150px",
      textAlign: "right",
      textTransform: "capitalize",
    },
    rowStyle: { textTransform: "capitalize" },
  },
];

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#EBF3FF";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    zIndex: "9",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input className="mr-2" type="checkbox" checked={isSelected} readOnly />
      <label className="pt-2">{children}</label>
    </components.Option>
  );
};

const multiStyles = {
  control: (styles) => ({
    ...styles,
    minWidth: "100px",
    maxWidth: "400px",
    marginBottom: "6px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#EBF3FF",
      color: "#2E6AD2",
    };
  },
};

class CompanySegmentFiles extends Component {
  constructor(props) {
    super(props);

    this.reportTypes = [
      {
        label: "benchmark",
        value: "benchmarks",
      },
      {
        label: "company",
        value: "companies",
      },
      {
        label: "customer_study",
        value: "customer_studies",
      },
      {
        label: "market_forecast",
        value: "market_forecasts",
      },
      {
        label: "market_landscape",
        value: "market_landscapes",
      },
      {
        label: "ecosystem",
        value: "ecosystems",
      },
      {
        label: "special_reports",
        value: "special_reports",
      },
    ];

    let yearOptions = [
      {
        id: "all",
        value: "all",
        label: "All",
        is_selected: true,
      },
    ];

    let quarterOptions = [];

    this.state = {
      rowData: [],
      filterRowData: [],
      quarterData: [],
      apiError: null,
      yearOptions: yearOptions,
      isLoading: true,
      selectedYear: [],
      selectedSegment: null,
      segments: [],
      quarterOptions: quarterOptions,
    };
  }

  componentDidMount() {
    this.getSegments();
  }

  getSegments = () => {
    const reportType =
      this.reportTypes.find((t) => t.label === this.props.reportType)?.value ??
      "";

    HistoricalFileService.getSegmentObjects(
      reportType === "ecosystems" ? "eco_systems" : reportType,
      this.props.reportId
    )
      .then((res) => {
        const segmentData = res?.data?.segments ?? [];
        const lobSubCategories = res?.data?.lobSubCategories ?? [];
        let newData = [];
        newData.push({
          report_id: "all",
          report_name: "All Segments",
          corelation_type: "all",
        });
        for (let h of segmentData) {
          newData.push(h);
        }
        for (let h of lobSubCategories) {
          newData.push(h);
        }

        const data = newData.map((segment) => {
          return {
            value: segment.report_id,
            label: segment.report_name,
            corelation_type: segment.report_type,
            is_selected: segment.id === this.state.selectedSegment,
          };
        });

        let currentSegment = this.state.selectedSegment
          ? this.state.selectedSegment
          : this.props.reportSegment;

        if (!isObject(currentSegment)) {
          currentSegment =
            data.find((s) => s.value === currentSegment) ?? data[0];
        }

        this.setState(
          {
            segments: data,
            selectedSegment: {
              ...currentSegment,
              is_selected: true,
            },
          },
          () => {
            this.setState(
              {
                selectedYear: this.state.yearOptions[0],
              },
              () => {
                this.getTableData();
              }
            );
          }
        );
      })
      .catch((error) => {
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handleFilterDataByYear = (selectedOption) => {
    this.setState(
      {
        selectedYear: selectedOption,
        quarterData: [],
      },
      () => this.filterRowDataBySegment(this.state.quarterData)
    );
  };

  handleSegment = (selectedOption) => {
    this.setState(
      {
        selectedSegment: selectedOption,
        selectedYear: null,
        quarterData: [],
      },
      () => {
        this.getTableData();
      }
    );
  };

  filterRowDataBySegment = (selectedQuater) => {
    let filteredRowData = this.state.rowData;
    // if (
    //   this.state.selectedSegment &&
    //   this.state.selectedSegment?.value !== "all"
    // ) {
    //   filteredRowData = filteredRowData.filter(
    //     (row) =>
    //       parseInt(row.corelation_id) ===
    //       parseInt(this.state.selectedSegment?.value)
    //   );
    // }

    if (this.state.selectedYear?.value !== "all") {
      const array = [];
      filteredRowData.forEach((item) => {
        const year = item.displayName?.split("_")[2];
        if (year) {
          const isIRSegment = year.toLowerCase().includes("ir");
          const shortYear = isIRSegment
            ? year[2] + year[3]
            : year.substring(year.length - 2);

          const currentYear = getFullYear(shortYear);
          const selectedShortYear = getFullYear(
            this.state.selectedYear?.value % 100
          );
          if (currentYear === selectedShortYear) {
            array.push(item);
          }
        }
      });
      filteredRowData = array;
    }
    if (selectedQuater.length > 0) {
      const array = [];
      selectedQuater.forEach((option) => {
        filteredRowData.forEach((item) => {
          let quater = item.displayName?.split("_")[2];
          if (quater) {
            quater = quater?.slice(0, 2);
            if (quater.toLowerCase() === option.value.toLowerCase()) {
              array.push(item);
            }
          }
        });
      });
      filteredRowData = array;
    }
    this.setState({
      filterRowData: filteredRowData,
    });
  };

  handleFilterDataByQuarter = (selectedOption) => {
    const updatedOptions = selectedOption.map((option) => {
      option.isSelected = true;
      return option;
    });
    this.setState({ quarterData: updatedOptions }, () => {
      this.filterRowDataBySegment(this.state.quarterData);
    });
  };

  getActions = (data) => {
    return (
      <>
        <AclAction
          type={constants.ACL.PERMISSION_KEY}
          entity={constants.ACL.DOWNLOADS_RESOURCE.TITLE}
          action={constants.ACL.DOWNLOADS_RESOURCE.ACTIONS.DOWNLOAD}
        >
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            id={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            )}-${data.id}`}
            onClick={(e) => {
              this.rowAction(
                constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD,
                data,
                e
              );
            }}
            className={
              "dynamicTableActionButton btn btn-md d-inline-flex align-items-center"
            }
            data-for={`tooltip-${Slugify(
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            )}-${data.id}`}
            data-tip={
              constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
            }
            data-iscapture="true"
            disabled={true}
          >
            <span className="material-icons-outlined text-grey icon-fs-20">
              download
            </span>
            <CustomReactTooltip
              id={`tooltip-${Slugify(
                constants.APPLICATION_ROUTE.DOWNLOAD.LIST.DOWNLOAD_TOOLTIP
              )}-${data.id}`}
              multiline={true}
            />
          </button>
        </AclAction>
      </>
    );
  };

  getTableData = () => {
    const reportType =
      this.reportTypes.find((t) => t.label === this.props.reportType)?.value ??
      "";
    HistoricalFileService.getHistoricalFileSegmentObjects(
      reportType === "ecosystems" ? "eco_systems" : reportType,
      this.props.reportId,
      this.state.selectedSegment.corelation_type,
      this.state.selectedSegment.value
    )
      .then((res) => {
        let clientData = res.data.historicalFiles;
        let yearList = this.state.yearOptions ?? [];
        let quarterList = this.state.quarterOptions ?? [];

        for (let item of clientData) {
          const year = item.file_name?.split("_")[2];
          if (year) {
            const quarter = year.substring(0, 2);
            const isIRSegment =
              year.toLowerCase().includes("ir") ||
              year.toLowerCase().includes("er");
            const shortYear = isIRSegment
              ? year[2] + year[3]
              : year.substring(year.length - 2);
            const currentYear = getFullYear(shortYear);
            if (currentYear) {
              yearList.push({
                id: currentYear,
                value: currentYear,
                label: currentYear,
                is_selected: false,
              });
            }
            if (quarter && this.props.reportType === "company") {
              quarterList.push({
                id: quarter.toUpperCase(),
                value: quarter.toUpperCase(),
                label: quarter.toUpperCase(),
                is_selected: false,
              });
            }
          }
        }

        yearList = orderBy(uniqBy(yearList, "value") ?? [], "value", "desc");

        if (this.props.reportType === "company") {
          quarterList = orderBy(
            uniqBy(quarterList, "value") ?? [],
            "value",
            "asc"
          );
        } else {
          quarterList = [
            {
              value: "1Q",
              label: "Q1",
              isSelected: false,
            },
            {
              value: "2Q",
              label: "Q2",
              isSelected: false,
            },
            {
              value: "3Q",
              label: "Q3",
              isSelected: false,
            },
            {
              value: "4Q",
              label: "Q4",
              isSelected: false,
            },
          ];
        }

        this.setState(
          {
            rowData: clientData,
            isLoading: false,
            filterRowData: clientData,
            yearOptions: yearList,
            quarterOptions: quarterList,
          },
          () => this.setTableData(clientData, new Date().getFullYear())
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          rowData: [],
          isLoading: false,
        });
        this.props.documentModalHandler();
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  setTableData = (totalData, year) => {
    totalData.sort(function (a, b) {
      const aYear = a.file_name?.split("_")[2];
      const bYear = b.file_name?.split("_")[2];

      if (aYear && bYear) {
        const aIsIRSegment =
          aYear.toLowerCase().includes("ir") ||
          aYear.toLowerCase().includes("er");
        const bIsIRSegment =
          bYear.toLowerCase().includes("ir") ||
          bYear.toLowerCase().includes("er");
        const aShortYear = aIsIRSegment
          ? aYear[2] + aYear[3]
          : aYear.substring(aYear.length - 2);
        const bShortYear = bIsIRSegment
          ? bYear[2] + bYear[3]
          : bYear.substring(bYear.length - 2);

        const aCurrentYear = getFullYear(aShortYear);
        const bCurrentYear = getFullYear(bShortYear);

        if (aCurrentYear && bCurrentYear) {
          // return parseInt(aCurrentYear.toString()) - parseInt(bCurrentYear.toString()); // asc
          return (
            parseInt(bCurrentYear.toString()) -
            parseInt(aCurrentYear.toString())
          ); // desc
        } else {
          return 0;
        }
      }
    });
    let bool = isRoleMember();
    const updatedTotalData = totalData.map((data, index) => {
      return {
        ...data,
        displayName: data.file_name ? data.file_name : "",
        name: data.file_name ? (
          <a
            style={{
              // cursor: "pointer",
              // color: "rgb(16, 133, 198)",
              ...(bool && {
                pointerEvents: "none",
                color: "black",
              }),
              textTransform: "none",
            }}
          >
            {data.file_name}
          </a>
        ) : (
          "_"
        ),
        download: data ? (
          <div
            onClick={() => this.downloadFile(data.uuid)}
            style={{
              width: "100%",
              cursor: "Pointer",
            }}
          >
            <span
              style={{ color: "#5B87C4" }}
              className="ml-4 material-icons-round icon-fs-16"
            >
              download
            </span>
          </div>
        ) : (
          "_"
        ),
        actions: this.getActions(data),
      };
    });
    this.setState({
      rowData: updatedTotalData,
      filterRowData: updatedTotalData,
    });
  };

  downloadFile = (uuid) => {
    HistoricalFileService.downloadHistoricalFile(uuid)
      .then((response) => {
        // Create a blob from the response
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        delay(() => {
          // Create a link element
          const link = document.createElement("a");
          link.href = url;

          // Set the file name (if provided in response headers)
          const filename = response.headers["content-disposition"]
            ? response.headers["content-disposition"]
                .split("filename=")[1]
                .trim()
                .replace(/['"]/g, "")
            : "downloaded_file";

          link.setAttribute("download", filename);

          // Append the link to the document
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up by removing the link and revoking the object URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }, 500);
      })
      .catch((error) => {
        Toast(
          error?.data?.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.documentModal}
        toggle={this.props.documentModalHandler}
        className={
          "modal-lg modal-historical-file modal-w-header custom-modal company-segments-modal"
        }
      >
        <ModalHeader toggle={this.props.documentModalHandler}>
          Files
          <span className="cmp-seg-files-text">
            {this.props.reportName && `/ ${this.props.reportName}`}
            {this.props.reportType === "company"
              ? this.state.selectedSegment &&
                ` / ${this.state.selectedSegment.label}`
              : ""}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            {this.props.reportType === "company" ? (
              <div class="col-12 col-md-4">
                <>
                  <p className="comp-files-modal-para my-2">Segment: </p>
                  <Select
                    options={this.state.segments}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    onChange={this.handleSegment}
                    styles={multiStyles}
                    value={this.state.selectedSegment}
                    placeholder={"Select Segment"}
                  />
                </>
              </div>
            ) : (
              <></>
            )}

            <div class="col-12 col-md-3">
              <div>
                <p className="comp-files-modal-para my-2">Year: </p>
                <Select
                  options={this.state.yearOptions}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  onChange={this.handleFilterDataByYear}
                  styles={multiStyles}
                  value={this.state.selectedYear}
                  placeholder={"Select Year"}
                  style={{ width: "130px" }}
                />
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div>
                <p className="comp-files-modal-para my-2">Quarter: </p>
                <Select
                  options={this.state.quarterOptions}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  onChange={this.handleFilterDataByQuarter}
                  styles={multiStyles}
                  value={this.state.quarterData}
                  placeholder={"Select Quarter"}
                  components={{
                    Option: InputOption,
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 mt-2">
              <div className="w-100 custom-table-block custom-table-block-2 marketforecast-list-table-block sort-table-block th-nowrap center-th-align">
                <DynamicTableComponent
                  columnData={tableHeader}
                  rowData={this.state.filterRowData ?? []}
                  showPagination={false}
                  isFileTable={true}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default CompanySegmentFiles;
