import { AxiosTBR } from '../lib/axios_tbr'
export const TbriServices = {
  getUserLogin,
  getFinancialData
}
function getUserLogin(body) {
  return AxiosTBR.post('token', body)
}
function getFinancialData(combination, from, to) {
  return AxiosTBR.get(`api/analytics/json?Combinations=${combination}&From=${from}&To=${to}`)
}
