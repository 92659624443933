import React, { useState } from "react";
import Collapsible from "react-collapsible";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";

const PreferenceCenterSidebar = ({
  reportTabSections,
  setReportsTabSelected,
}) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState(true);
  const [reportCollapseIsOpen, setReportCollapseIsOpen] = useState(true);
  const [otherCollapseIsOpen, setOtherCollapseIsOpen] = useState(false);

  return (
    <div className="preference-sidebar">
      <a
        className="d-flex ml-4 mt-4 cursor-pointer"
        style={{ textDecoration: "none", color: "#000" }}
        href={formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})}
      >
        <span className="material-icons icon-fs-24">arrow_back</span>
        <h3 className="preference-head">Preference Center</h3>
      </a>
      <div className="pref-collapser-item pref-collapse-list">
        <Collapsible
          open={collapseIsOpen}
          trigger={
            <div
              onClick={() => setCollapseIsOpen(!collapseIsOpen)}
              className="preference-collapse"
            >
              <h2 className="pref-collapse-header">Notification</h2>
              <span
                style={{ color: "#fff" }}
                className="material-icons-outlined material-icon mr-2"
              >
                {collapseIsOpen ? "expand_less" : "expand_more"}
              </span>
            </div>
          }
        >
          <div className="collapser-container">
            <div className="pref-collapser-sub-item">
              <Collapsible
                open={reportCollapseIsOpen}
                trigger={
                  <div
                    onClick={() =>
                      setReportCollapseIsOpen(!reportCollapseIsOpen)
                    }
                    className="sub-preference-collapse"
                  >
                    <h2 className="pref-collapse-header-2">Reports</h2>
                    <span
                      style={{ color: "#555555" }}
                      className="material-icons-outlined material-icon mr-2"
                    >
                      {reportCollapseIsOpen ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                }
              >
                <div>
                  {reportTabSections.slice(0, 7).map((item) => (
                    <div
                      onClick={() =>
                        setReportsTabSelected(item.slug, item.id, item.name)
                      }
                      key={item.id}
                      className={
                        item.is_selected
                          ? "sub-pref-list-item-selected"
                          : "sub-pref-list-item"
                      }
                    >
                      <span
                        style={{
                          color: item.is_selected ? "#2E6AD2" : "inherit",
                        }}
                        className="material-icons-outlined pl-0 icon-fs-20 align-middle"
                      >
                        {item.icon}
                      </span>
                      <p
                        className={
                          item.is_selected
                            ? "pref-list-name-selected"
                            : "pref-list-name"
                        }
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </Collapsible>
            </div>

            <div className="pref-collapser-sub-item">
              <Collapsible
                open={otherCollapseIsOpen}
                trigger={
                  <div
                    onClick={() => setOtherCollapseIsOpen(!otherCollapseIsOpen)}
                    className="sub-preference-collapse mt-2"
                  >
                    <h2 className="pref-collapse-header-2">Others</h2>
                    <span
                      style={{ color: "#555555" }}
                      className="material-icons-outlined material-icon mr-2"
                    >
                      {otherCollapseIsOpen ? "expand_less" : "expand_more"}
                    </span>
                  </div>
                }
              >
                <div className="list-height">
                  {reportTabSections.slice(7, 10).map((item) => (
                    <div
                      onClick={() =>
                        setReportsTabSelected(item.slug, item.id, item.name)
                      }
                      key={item.id}
                      className={
                        item.is_selected
                          ? "sub-pref-list-item-selected"
                          : "sub-pref-list-item"
                      }
                    >
                      <span
                        style={item.is_selected ? { color: "#5B87C4" } : {}}
                        className="material-icons-outlined pl-0 icon-fs-20 align-middle"
                      >
                        {item.icon}
                      </span>
                      <p
                        className={
                          item.is_selected
                            ? "pref-list-name-selected"
                            : "pref-list-name"
                        }
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </Collapsible>
            </div>
          </div>
        </Collapsible>
      </div>
    </div>
  );
};

export default PreferenceCenterSidebar;
