import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const DailogNew = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle || props.decline}
      className={`${props.modalStyleType} modal-confirm modal-sm custom-modal`}
    >
      <ModalHeader toggle={props.decline}>{props.header}</ModalHeader>
      <ModalBody>{props.content}</ModalBody>
      {props.config && props.config.type && (
        <ModalFooter>
          <Button
            className="btn-outline-primary modal-btn"
            onClick={props.decline}
          >
            {props.cancelTitle ?? "Cancel"}
          </Button>{" "}
          <Button
            className="modal-right-btn modal-btn"
            onClick={() => {
              props.accept(props.config);
            }}
          >
            {props.submitTitle ?? "Confirm"}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DailogNew;
