import { special_reports as DETAILS } from '../actionConstants'

const specialreportCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_CATEGORIES:
      return action.payload
    default:
      return state
  }
}


const specialreportDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const specialreportContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.SPECIAL_REPORT_CONTENT:
      return action.payload
    default:
      return state
  }
}


export {
    specialreportCategories,
    specialreportDetails,
  categories,
  specialreportContent
}
