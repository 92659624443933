import { Axios } from '../lib/axios'
export const CompanyServices = {
  getTotalCompanies,
  getTotalSegments,
  getCompanies,
  getCategories,
  getCompanyDetails,
  getCompanyContent,
  getTaxonomies,
  getProjects,
  downloadContent,
  getSegmentDetails,
  postDownloadDataReport,
  addMostViewsOfReports,
  addMostViewsOfReportsforModules
}
function getProjects() {
  return Axios.get('https://my-json-server.typicode.com/parameshwarggk/tbr-json-server/projects')
}
function getTotalCompanies(url) {
  return Axios.get(url)
}
function getTotalSegments() {
  return Axios.get('segments')
}
function getCompanies(url) {
  return Axios.get(url)
}
function getCategories() {
  return Axios.get('categories?type=company')
}
function getCompanyDetails(company_id) {
  return Axios.get(`company/${company_id}`)
}
function getCompanyContent(company_id, segment_id) {
  return Axios.get(`company/${company_id}/segment/${segment_id}/content`)
}
function getTaxonomies() {
  return Axios.get('taxonomy')
}
function downloadContent(body) {
  return Axios.post('export', body, { responseType: 'blob' })
}
function getSegmentDetails(is_ER_allowed) {
  return Axios.get(`lobs?includeCompanies=1&is_ER_allowed=${is_ER_allowed}`)
}
function postDownloadDataReport(body)
{
  return Axios.post("DownloadDataReport",body)
}
function addMostViewsOfReports(companyId, segmentId) {
  return Axios.put(`addMostViews/company/${companyId}/segment/${segmentId}`)
}
function addMostViewsOfReportsforModules(type, typeId) {
  return Axios.put(`mostViews/type/${type}/typeId/${typeId}`)
}