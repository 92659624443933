import gotoSection from './ScrollToTop'; 

const BoardLoadEventRedirect = (location) => {
  const hash = location?.state?.key ?? "";
  if (hash) {
    setTimeout(() => {
      gotoSection(hash, 190); 
    }, 1000);
  }
};

export default BoardLoadEventRedirect;
