import { market_landscape_details as DETAILS } from '../actionConstants'

const marketLandscapeCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_LANDSCAPE_CATEGORIES:
      return action.payload
    default:
      return state
  }
}


const marketLandscapeDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_LANDSCAPE_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const marketLandscapeContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.MARKET_LANDSCAPE_CONTENT:
      return action.payload
    default:
      return state
  }
}


export {
  marketLandscapeCategories,
  marketLandscapeDetails,
  categories,
  marketLandscapeContent
}
