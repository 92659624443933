import { clients as DETAILS } from '../Reducers/actionConstants'

const clients = (payload) => {
  return {
    type: DETAILS.CLIENTS,
    payload,
  }
}

const clientRoles = (payload) => {
  return {
    type: DETAILS.CLIENT_ROLES,
    payload,
  }
}

export {
  clients,
  clientRoles
}
