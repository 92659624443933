import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/benchmarkDetailsReference'

const benchmarkCategories = DETAILS_REF.benchmarkCategories
const benchmarkDetails = DETAILS_REF.benchmarkDetails
const categories = DETAILS_REF.categories
const benchmarkContent = DETAILS_REF.benchmarkContent


const benchmarkDetailsReducer = combineReducers({
    benchmarkCategories,
    benchmarkDetails,
    categories,
    benchmarkContent
})

export default benchmarkDetailsReducer
