import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatRoute } from 'react-router-named-routes'
import AclAction from '../Components/ACL/AclAction'

import CustomReactTooltip from '../Components/CustomReactTooltip'
import DailogNew from '../Components/DailogNew';
import DynamicTableComponent from '../Components/DynamicTableComponent'

import Search from '../Components/searchComponent'
import { ClientServices} from '../Services/Client'
import { activeMenuTab } from '../Store/Actions/User'
import DetailsDateTimeFormatter from '../Utils/DetailsDateTimeFormatter'
import constants from '../Utils/constants'
import DocumentTitle from '../Utils/DocumentTitle'
import Slugify from '../Utils/Slugify'
import Toast from '../Utils/Toast'
import { clientRoles, clients } from '../Store/Actions/Client'

const tableHeader = [
  {
    id: 'first_name',
    title: 'Name',
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: 'email',
    title: 'Email',
    sortable: true,
    style: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
  },
  {
    id: 'display_name',
    title: 'Display Name',
    sortable: true,
    style: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
  },
  {
    id: 'designation',
    title: 'Designation',
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left",textTransform: "capitalize" },
  },
  {
    id: 'role', title: 'Role', sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: 'last_login_at', title: 'Last Login At', sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: 'created_by', title: 'Created By', sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: 'created_at', title: 'Created On', sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: 'is_active', title: 'Status', sortable: true, headerClass: 'text-center',
    style: { textTransform: "capitalize" },
    rowStyle: { textAlign: "center", textTransform: "capitalize" },
  },
  {
    id: 'actions', title: 'Actions', sortable: false, headerClass: 'text-center',
    style: { textAlign: "center", textTransform: "capitalize" },
    rowStyle: { textAlign: "center", textTransform: "capitalize", whiteSpace: "nowrap" },
  }
]
const showActions = false
class ListClientUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.USER_DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: '',
      dailogModal: false,
      dailogModalContent: '',
      dailogModalHeader: '',
      dailogModalConfig: {},
      dailogModalStyleType: ''
    }
  }
  async componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.HEADER_TITLE)
    let params = queryString.parse(this.props.location.search)
    await ClientServices.getClientUserRoles()
      .then((res) => {
        let response = res && res.data
        this.props.setClientRoles(response)
      })
      .catch((error) => {
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })

    this.setState({
      pageNo: params.page ? parseInt(params.page) : constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: params.page_size ? parseInt(params.page_size) : constants.PAGINATION.DEFAULT_PAGE_SIZE,
      
      sortColumn: "created_at",
      sortOrder: "desc",
      name: params.search_value ? params.search_value : ''
    }, () => { this.getTableData() })
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE)
  }
  pagePrevious = () => {
    this.setState((prevState) => ({
      pageNo: prevState.pageNo - 1
    }), () => this.getTableData())
  }
  pageNext = () => {
    this.setState((prevState) => ({
      pageNo: prevState.pageNo + 1
    }), () => this.getTableData())
  }
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData())
  }
  handleSort = (clickedColumn, key) => {
    this.setState({ sortColumn: clickedColumn, segmentSort: false, sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc', }, () =>
      this.getTableData())
  }
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData()
    })
  }
  toggleClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = <>
     
      <h3 className="text-darkblue2 font-weight-bold">{data.is_active === 1 ? 'De-Activation' : 'Activation'}</h3>
    </>
    let dailogModalContent = <>
      {data.is_active === 1 ?
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">Are you sure want to de-activate the user?</h2>
        </div> :
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">Are you sure want to activate the user?</h2>
        </div>
      }
    </>
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: data.is_active === 1 ? 'de-activate_user' : 'activate_user', id: data.user_id },
      dailogModalStyleType: data.is_active === 1 ? "danger-modal" : "success-modal"
    })
  }
  removeClientUserConfirmationHandler = (data) => {
    let dailogModalHeader = <>
     
      <h3 className="text-darkblue2 font-weight-bold">Remove User</h3>
    </>
    let dailogModalContent = <>
      <div className="remove-body">
        <h2 className="font-weight-semibold mb-0">Are you sure that you want to remove the user?</h2>
      </div>
    </>
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: 'remove_user', id: data.id },
      dailogModalStyleType: "danger-modal"
    })
  }
  removeClientUser = (clientUserId) => {
    let arr = []
    arr.push({ id: clientUserId })
    ClientServices.removeClientUser(arr)
      .then((res) => {
        this.getTableData()
      })
      .catch((error) => {
        this.getTableData();
        
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
  }
  toggleClientUser = (clientUserId, isActive) => {
    let arr = []
    arr.push({ id: clientUserId })
    ClientServices.toggleClientUser(clientUserId,isActive)
      .then((res) => {
        Toast(isActive ? 'User Activated Successfully' : 'User De-Activated Succesfully', 'success')
        this.getTableData()
      })
      .catch((error) => {
        this.getTableData();
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
  }
  resendEmailVerification = (data) => {
    let arr = []
    arr.push({ id: data.user_id })
    ClientServices.resendEmailVerification(arr).then((res) => {
      Toast('Verification mail has been sent.', 'success')
    }).catch((error) => {
      Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
    })
  }
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: '',
      dailogModalContent: '',
      dailogModalConfig: {},
    })
    switch (config.type) {
      case 'remove_user':
        this.removeClientUser(config.id)
        break;
      case 'de-activate_user':
        this.toggleClientUser(config.id, 0)
        break;
      case 'activate_user':
        this.toggleClientUser(config.id, 1)
        break;
      default:
        break;
    }
  }
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: '',
      dailogModalContent: '',
      dailogModalConfig: {}
    })
  }
  getActions = (data) => {
    let disabled = data.user_id === this.props.home.loginUserSuccess.id
    return <>

      {(data.is_active === 2) ?
        <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE} action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.STATUS}>
          <button
            tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
            disabled={disabled}
            data-for={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESEND_EMAIL_VERIFICATION_TOOLTIP)}-${data.id}`}
            data-tip={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESEND_EMAIL_VERIFICATION_TOOLTIP}
            data-iscapture="true"
            onClick={(e) => { this.rowAction(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESEND_EMAIL_VERIFICATION, data, e) }}
            className={`dynamicTableActionButton btn`}
          >
            <span className="material-icons-round text-blue-clr icon-fs-20">
              send
            </span>
            <CustomReactTooltip
              id={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESEND_EMAIL_VERIFICATION_TOOLTIP)}-${data.id}`}
              multiline={true}
            />
          </button>

        </AclAction> : <button
          className={`dynamicTableActionButton btn visibility-hidden`}
        >
          <span className="material-icons-round text-blue-clr icon-fs-20">
            send
          </span>
        </button>
      }
      { }
      <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE} action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.EDIT}>


        <button
          tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
          disabled={disabled}
          data-for={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER_TOOLTIP)}-${data.id}`}
          data-tip={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER_TOOLTIP}
          data-iscapture="true"
          onClick={(e) => { this.rowAction(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER, data, e) }}
          className={'dynamicTableActionButton btn'}
        >
          <span className="material-icons-outlined text-blue-clr icon-fs-20">
            edit
          </span>
          <CustomReactTooltip
            id={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER_TOOLTIP)}-${data.id}`}
            multiline={true}
          />
        </button>
       
      </AclAction>
      
      <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE} action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.DELETE}>
        <button
          tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
          disabled={disabled}
          data-for={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER_TOOLTIP)}-${data.id}`}
          data-tip={constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER_TOOLTIP}
          onClick={(e) => { this.rowAction(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER, data, e) }}
          className={'dynamicTableActionButton btn '}
        >
          <span className="material-icons-outlined error-clr icon-fs-20">
            delete
          </span>
          <CustomReactTooltip
            id={`tooltip-${Slugify(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER_TOOLTIP)}-${data.id}`}
            multiline={true}
          />
        </button>
       

      </AclAction>
    </>
  }
  getTableData = () => {
    let queryParams = ''
    if (this.state.sortOrder) {
      queryParams = '&sort_order=' +
        this.state.sortOrder + '&sort_column=' + this.state.sortColumn
    }
    if (this.state.name !== '') {
      queryParams += '&search_column=name_or_email&search_value=' + this.state.name
    }
    this.changeRoute()
    let url = '/clientusers/all'
    let roles = (this.props.client && this.props.client.clientRoles) ? this.props.client.clientRoles : []
    let is_role_member = roles.find((r) => r.id === this.props.home.loginUserSuccess.role_id && r.short_name==constants.ROLES.CLIENT_USER.MEMBER) ? true : false
    ClientServices.getClientUsers(`${url}?page=` +
      this.state.pageNo + '&page_size=' + this.state.pageSize + queryParams)
      .then((res) => {
        let clientData = res.data.data
        let count = (res.data && res.data.meta && res.data.meta.total_count) || 0
        clientData = clientData.map((data) => {
          let disabled = data.user_id === this.props.home.loginUserSuccess.id
          return {
            id: data.id,
            name: `${data.first_name ?? ''} ${data.last_name ?? ''}`,
            first_name: `${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''}`,
            email: data.email,
            display_name: data.display_name !== null ? data.display_name : '-',
            designation: data.designation !== null ? data.designation : '-',
            role: data.role.name,
            client: data.client.name,
            created_by: data.created_by_user && data.created_by_user.id ? `${data.created_by_user.first_name ? data.created_by_user.first_name : ''} ${data.created_by_user.last_name ? data.created_by_user.last_name : ''}` : '-',
            created_at: data.created_at ? DetailsDateTimeFormatter(data.created_at, false) : DetailsDateTimeFormatter(new Date(), false),
            last_login_at: data.last_login_datetime ? DetailsDateTimeFormatter(data.last_login_datetime, false) : '-',
            is_active: (data.is_active === 0 || data.is_active === 1) ?
              <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE} action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.STATUS}>
                <div className="success-toggle">
                  <label className="custom-switch-checkbox success-custom-switch-checkbox" for={`customSwitch${data.id}`}>
                    <input disabled={disabled || is_role_member} type="checkbox" className="custom-control-input" id={`customSwitch${data.id}`} checked={data.is_active === 1} onChange={(e) => { e.stopPropagation(); this.rowAction(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS, data, e) }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </AclAction> : 'pending',
            actions: this.getActions(data)
          }
        })
        this.setState({ rowData: clientData, count: count })
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });
        Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
      })
  }
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData()
    })
  }
  handleInput = (e) => {
    this.setState({ name: e.target.value })
  }
  rowAction = (action, data, e) => {
    e.stopPropagation()
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER:
        this.props.history.push({
          pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE, { clientId: data.client.id, id: data.id })
        })
        break
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS:
        this.toggleClientUserConfirmationHandler(data)
        break
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER:
        this.removeClientUserConfirmationHandler(data)
        break
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data)
        break
      default:
        break
    }
  }
  addClient = () => {
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE, { clientId: this.state.clientId })
    })
  }
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients]
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false
    })
    this.props.setClients(clients)
    let clientId = clients.find((c) => c.is_selected === true) ? clients.find((c) => c.is_selected === true).id : 'all'
    this.setState({
      clientId: clientId,
      pageNo: 1
    }, () => {
      this.getTableData()
    })
  }
  changeRoute = () => {
    let queryParams = ''
    if (this.state.sortOrder) {
      queryParams = '&sort_order=' +
        this.state.sortOrder + '&sort_column=' + this.state.sortColumn
    }
    if (this.state.name !== '') {
      queryParams += '&search_column=name_or_email&search_value=' + this.state.name
    }
    this.props.history.push({
      pathname: formatRoute(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE, { clientId: this.state.clientId }),
      search: '?page=' + this.state.pageNo + '&page_size=' + this.state.pageSize + queryParams
    })
  }
  breadcrumbs = () => {
    let breadcrumbs = [
      {
        title: constants.APPLICATION_ROUTE.DASHBOARD.NAME,
        // link: formatRoute(constants.APPLICATION_ROUTE.DASHBOARD.ROUTE, {}),
        link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
        is_active: false
      },
      {
        title: constants.APPLICATION_ROUTE.CLIENT_USER.LIST.NAME,
        link: null,
        is_active: true
      }
    ]
    return breadcrumbs
  }
  render() {
    return (
      <aside className="main_content">
        <div className='m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height'>
       <div className=" comapnay_tbl">

          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 pt-3">
            <h1 className="pt-0 mb-0 text-ligntblue2 font-weight-bold">{constants.APPLICATION_ROUTE.CLIENT_USER.LIST.HEADER_TITLE}</h1>
            <div className='ml-auto'>
              <aside className="d-flex align-items-center">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  value={this.state.name}
                  placeHolder={'Filter Name / Email'}
                />
                <AclAction type="permissions" entity={constants.ACL.CLIENT_USER_RESOURCE.TITLE} action={constants.ACL.CLIENT_USER_RESOURCE.ACTIONS.CREATE}>
                  <button tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON} className={'btn btn-md d-inline-flex align-items-center btn-primary ml-3'} type='button' onClick={this.addClient}>
                    {/* <i className={'fa fa-plus'} /> */}
                    <span className="material-icons mr-1 icon-fs-16">
                      add
                    </span>
                    {' ' + constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ADD_BUTTON_TITLE}
                  </button>
                </AclAction>
              </aside>
            </div>
          </div>

         <div className="w-100 custom-table-block custom-table-block-2 benchmark-list-table-block sort-table-block th-nowrap center-th-align">
            <DynamicTableComponent
              columnData={tableHeader}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.state.count}
              rowAction={this.rowAction}
              colSpan={tableHeader.length}
              showActions={showActions}
              showPagination={true}
              handlePageSize={this.handlePageSize}
              paginationClass={"center-pagination custom-pagination-new"}
            />
          </div>
        
          <DailogNew isOpen={this.state.dailogModal} accept={this.dailogModalAccept} decline={this.dailogModalDecline} header={this.state.dailogModalHeader} content={this.state.dailogModalContent} config={this.state.dailogModalConfig} modalStyleType={this.state.dailogModalStyleType} />
         </div>
        </div>
      </aside>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab))
    },
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles))
    },
  }
}
const mapStateToProps = state => {
  return {
    home: state.home,
    client: state.client
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListClientUsers)