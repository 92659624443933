import React from "react";
import Slugify from "../Utils/Slugify";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import CustomReactTooltip from "../Components/CustomReactTooltip";

const AuthorsPopover = ({ authorsData, copyHandler }) => {
  return (
    <>
      {authorsData?.authors && authorsData.authors.length > 0 && (
        <button
          className="btn dropdown-btn d-inline-flex align-items-center mr-3 pr-1"
          type="button"
          id="authors-popover"
        >
          <span>
            {authorsData?.authors != undefined
              ? authorsData?.authors.length
              : 0}{" "}
            Author(s)
          </span>
          <span className="material-icons arrow-drown-down ml-1 icon-fs-20">
            arrow_drop_down
          </span>
        </button>
      )}
      {authorsData?.authors && authorsData.authors.length > 0 && (
        <UncontrolledPopover
          target="authors-popover"
          className="custom-popover-author authors-popover"
          placement="bottom"
          trigger="legacy"
        >
          <PopoverBody>
            <div className="authors-list">
              {authorsData?.authors.map((author, index) => (
                <div
                  className="author d-flex justify-content-between align-items-center"
                  key={index}
                >
                  <a
                    href={`mailto:${author.email}?subject=${authorsData.name} Inquiry`}
                    data-for={`tooltip-${Slugify(author.id)}-details`}
                    data-tip={`${author.first_name} ${author.last_name} ${
                      author.designation === null
                        ? ""
                        : ", " + author.designation
                    } <br/> (${author.email})`}
                    data-iscapture="true"
                  >
                    {author.first_name} {author.last_name}
                    {author.designation === null
                      ? ""
                      : ", " + author.designation}
                  </a>
                  <CustomReactTooltip
                    id={`tooltip-${Slugify(author.id)}-details`}
                    multiline={true}
                  />
                  <>
                    {author.copied && (
                      <>
                        <span
                          className="material-icons-outlined copy-icon icon-fs-14 ml-4 text-green cursor-pointer"
                          data-for={`tooltip-${Slugify(author.id)}-copied`}
                          data-tip={`Copied`}
                          data-iscapture="true"
                        >
                          done
                        </span>
                        <CustomReactTooltip
                          id={`tooltip-${Slugify(author.id)}-copied`}
                          multiline={true}
                        />
                      </>
                    )}
                    {!author.copied && (
                      <>
                        <span
                          className="material-icons-outlined copy-icon icon-fs-14 ml-4 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => {
                            copyHandler(author);
                          }}
                          data-for={`tooltip-${Slugify(author.id)}-copy`}
                          data-tip={`Copy<br>Email`}
                          data-iscapture="true"
                        >
                          content_copy
                        </span>
                        <CustomReactTooltip
                          id={`tooltip-${Slugify(author.id)}-copy`}
                          multiline={true}
                        />
                      </>
                    )}
                  </>
                </div>
              ))}
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
};

export default AuthorsPopover;
