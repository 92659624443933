import { Axios } from "../lib/axios";
export const PreferenceServices = {
  getClientPreferences,
  setClientPreferences,
  getAllSegments,
  getClientPermissions,
  offClassicNotification,
  getClientPlatformNotifications,
  updatePlatformNotificationPreferences
};

function getClientPreferences() {
  return Axios.get(`client-prefereces`);
}
function setClientPreferences(body) {
  return Axios.post(`setClientPreference`, body);
}

function getAllSegments() {
  return Axios.get(`lobs?includeCompanies=1`);
}

function getClientPermissions() {
  return Axios.get(`/client-segments`)
}

function offClassicNotification() {
  return Axios.post(`/classic-notification-off`)
}

function getClientPlatformNotifications(){
  return Axios.get(`/client-platform/user`)
}

function updatePlatformNotificationPreferences(input){
  return Axios.post(`/client-platform/user`, input)
}