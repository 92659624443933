import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ReactTooltip from "react-tooltip";
import ChildPieChartDashboard from "./ChildPieChartDashboard";

const PieChartDashboard = ({
  type,
  chartData,
  dataIsAvailable,
  toggleReport,
}) => {
  const [selectedSlice, setSelectedSlice] = useState("");
  const [loader, setLoader] = useState(true);
  const initializeChartData = () => {
    let chartDiv = document.getElementById(type);
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(chartDiv, am4charts.PieChart);
    chart.data = chartData;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";

    pieSeries.slices.template.events.on("hit", function (event) {
      setSelectedSlice(event.target.dataItem.properties.category);
      pieSeries.hide();
    });

    pieSeries.slices.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;

    // Label and line configuration for shorter lines
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.fontSize = 12;
    pieSeries.labels.template.fontWeight = 500;

    // Place labels slightly outside with shorter lines
    pieSeries.labels.template.radius = am4core.percent(10); // Closer to the pie chart
    pieSeries.ticks.template.disabled = false;
    pieSeries.ticks.template.strokeWidth = 1;
    pieSeries.ticks.template.length = 5; // Shorter line length
    pieSeries.ticks.template.strokeOpacity = 0.7;
    pieSeries.labels.template.fill = am4core.color("black");

 

    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.slices.template.events.on("over", function (event) {
      let currentSlice = event.target;
      let currentSliceName = event.target.dataItem.properties.category;
      let currentCount = event.target.dataItem.values.value.value;
      let currentPercent =
        event.target.dataItem.values.value.percent.toFixed(2);
      currentSlice.tooltipText = `${currentSliceName} | Count: [medium]${currentCount} (${currentPercent}%)`;
    });

    

    // Adjust chart padding for better spacing
    chart.padding(5, 5, 5, 5); // Reduced padding to keep things compact
  };

  useEffect(() => {
    initializeChartData();
    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, [chartData, selectedSlice, loader]);

  useEffect(() => {
    setSelectedSlice("");
  }, [toggleReport]);

  return (
    <div>
      {dataIsAvailable ? (
        <div className="d-flex">
          {selectedSlice.length > 0 ? (
            <ChildPieChartDashboard
              type={selectedSlice}
              data={dataIsAvailable}
              setState={setSelectedSlice}
              heading="Total Reports Usage"
              setLoader1={setLoader}
            />
          ) : (
            <div>
              {/* <h4 className="pie-chart-head">Total Reports Usage</h4> */}
              <div className="d-flex">
                <button className="dashboard-back-btn">
                  <span
                    data-tip
                    data-for="child-info"
                    className="material-icons mt-1"
                  >
                    keyboard_double_arrow_left
                  </span>
                  <ReactTooltip id="child-info" place="right" effect="solid">
                    Do not Drill Up
                  </ReactTooltip>
                </button>
                <div className="pie-info">
                  <span
                    data-tip
                    data-for="parent-info"
                    style={{ color: "#1678CF" }}
                    className="material-icons icon-fs-20"
                  >
                    info
                  </span>
                  <ReactTooltip id="parent-info" place="right" effect="solid">
                    Click on the any below section to drill down in detailed
                    analysis
                  </ReactTooltip>
                </div>
              </div>
              {loader ? (
                <div class="lds-hourglass"></div>
              ) : (
                <div className="d-flex">
                  <div
                    style={{
                      width: "100%",
                      height: "380px",
                      marginLeft: "50px",
                    }}
                    id={type}
                  ></div>
                  <div className="d-flex flex-column justify-content-center pie-chart-report-card">
                    <h3 style={{ color: "#5e5e5e", fontWeight: "600" }}>
                      Reports
                    </h3>
                    <div
                      style={{
                        height: "0.5px",
                        background: "#727272",
                        width: "100%",
                      }}
                    ></div>
                    {chartData?.map(
                      (chart) =>
                        chart.value > 0 && (
                          <div
                            key={chart.color}
                            style={{ height: "30px", marginTop: "10px" }}
                            className="d-flex align-items-center"
                          >
                            <div
                              className="pie-legend-dots"
                              style={{ backgroundColor: chart.legendColor }}
                            ></div>
                            <p
                              data-tip
                              data-for={chart.name}
                              className="legend-text"
                            >
                              {chart.name}
                            </p>
                            <ReactTooltip
                              id={chart.name}
                              place="top"
                              effect="solid"
                            >
                              {chart.name}
                            </ReactTooltip>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>No Data Available</div>
      )}
    </div>
  );
};

export default PieChartDashboard;
