import { board as DETAILS } from '../Reducers/actionConstants'

const boards = (payload) => {
  return {
    type: DETAILS.BOARDS,
    payload,
  }
}

const boardDetails = (payload) => {
  return {
    type: DETAILS.BOARD_DETAILS,
    payload,
  }
}

const boardContent = (payload) => {
  return {
    type: DETAILS.BOARD_CONTENT,
    payload,
  }
}

const boardContentDetails = (payload) => {
  return {
    type: DETAILS.BOARD_CONTENT_DETAILS,
    payload,
  }
}

const boardModes = (payload) => {
  return {
    type: DETAILS.BOARD_MODES,
    payload,
  }
}

const boardContentComments = (payload) => {
  return {
    type: DETAILS.BOARD_CONTENT_COMMENTS,
    payload,
  }
}

const boardGeneralComments = (payload) => {
  return {
    type: DETAILS.BOARD_GENERAL_COMMENTS,
    payload,
  }
}

const boardType =(payload)=>{
  return{
    type: DETAILS.BOARD_TYPE,
    payload
  }
}

export {
  boards,
  boardDetails,
  boardContent,
  boardModes,
  boardGeneralComments,
  boardContentComments,
  boardContentDetails,
  boardType
}
