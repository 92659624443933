import { Axios } from "../lib/axios";
export const ApiCredentialServices = {
  generateApiCredentials,
  getApiCredential,
  regenerateApiCredentials,
};
function generateApiCredentials(body) {
  return Axios.post("open-apis/generate-keys", body);
}
function getApiCredential(body) {
  return Axios.get("open-apis/key", body);
}
function regenerateApiCredentials(body) {
  return Axios.put("open-apis/re-generate-keys",body)
}
