import { customer_study_details as DETAILS } from '../actionConstants'

const customerStudyCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMERSTUDY_CATEGORIES:
      return action.payload
    default:
      return state
  }
}


const customerStudyDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMERSTUDY_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const customerStudyContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.CUSTOMERSTUDY_CONTENT:
      return action.payload
    default:
      return state
  }
}


export {
  customerStudyCategories,
  customerStudyDetails,
  categories,
  customerStudyContent
}
