import { visualization as DETAILS } from '../Reducers/actionConstants'

const chartType = (payload) => {
  return {
    type: DETAILS.CHART_TYPE,
    payload,
  }
}

const chartData = (payload) => {
  return {
    type: DETAILS.CHART_DATA,
    payload,
  }
}

const chartAxisDetails = (payload) => {
  return {
    type: DETAILS.CHART_AXIS_DETAILS,
    payload,
  }
}


const selectedElements = (selectedElementsObj) => {
  let data = {};
  if(Object.keys(selectedElementsObj).length){
    data = selectedElementsObj
  }
  return {
    type : DETAILS.CHART_ELEMENTS,
    payload: data
  }
} 

const storeSeriesNames = (payload) => {
  return {
    type: DETAILS.CHART_SERIES_NAMES,
    payload,
  }
}

export {
  chartType,
  chartData,
  selectedElements,
  chartAxisDetails,
  storeSeriesNames
}

