import constants from '../../../Utils/constants'
import { visualization as DETAILS } from '../actionConstants'

const chartType = (state = constants.VISUALIZATION.CHART_TYPE.LINE, action) => {
    switch (action.type) {
        case DETAILS.CHART_TYPE:
            if(action.payload === ""){
                return constants.VISUALIZATION.CHART_TYPE.LINE
            }
            return action.payload
        default:
            return state
    }
}

const chartData = (state = [], action) => {
    switch (action.type) {
        case DETAILS.CHART_DATA:
            return action.payload
        default:
            return state
    }
}

const chartAxisDetails = (state = {}, action) => {
    switch (action.type) {
        case DETAILS.CHART_AXIS_DETAILS:
            return action.payload
        default:
            return state
    }
}


const selectedElements = (state = {}, action) => {
    switch (action.type) {
        case DETAILS.CHART_ELEMENTS:
            return action.payload
        default:
            return state
    }
}

const storeSeriesNames = (state = [], action) => {
    switch (action.type) {
        case DETAILS.CHART_SERIES_NAMES:
            return action.payload
        default:
            return state
    }
}


export {
    chartType,
    chartData,
    selectedElements,
    chartAxisDetails,
    storeSeriesNames
}
