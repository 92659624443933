import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import ReactTooltip from "react-tooltip";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ChildPieChartDashboard2 from "./ChildPieChartDashboard2";
import DataNotAvailable from "../Pages/SvgIcons/ReportNotFound.svg";

const chartColors = [
  "#77C3FE",
  "#011E3C",
  "#003870",
  "#09579E",
  "#1678CF",
  "#239DFF",
];

const ChildPieChartDashboard = ({
  type,
  data,
  setState,
  heading,
  setLoader1,
}) => {
  const [selectedSlice, setSelectedSlice] = useState("");
  const [loader, setLoader] = useState(true);
  let vendorNames = [
    "Company Research",
    "Company Profile",
    "Company Snapshot",
    "Benchmark Coverage",
  ];
  let vendorData = [
    data.total_vendor_reports,
    data.total_vendor_profile,
    data.total_vendor_snapshot,
    data.total_benchmark_coverage,
  ];
  var selectedSliceData;
  var selectedSliceIsEmpty = false;
  const modifiedChartData = () => {
    let chartDataArray = [];
    if (type === "Company-Specific Research") {
      vendorData.forEach((item, index) => {
        let chartDataObject = {};
        let value = item;
        chartDataObject["name"] = vendorNames[index];
        chartDataObject["value"] = value;
        chartDataObject["color"] = am4core.color(`${chartColors[index]}`);
        chartDataObject["legendColor"] = chartColors[index];
        chartDataArray.push(chartDataObject);
      });
    } else {
      let typeName = type.toLowerCase().replace(" ", "_") + "_analytics";
      Object.keys(data).forEach((item) => {
        if (item == typeName) {
          selectedSliceData = data[item];
          if (data[item].length === 0) {
            selectedSliceIsEmpty = true;
          }
          data[item].forEach((dataItem, index) => {
            let chartDataObject = {};
            let name = Object.keys(dataItem);
            let value = Object.values(dataItem);
            chartDataObject["name"] = name[0];
            chartDataObject["value"] = value[0];
            chartDataObject["color"] = am4core.color(`${chartColors[index]}`);
            chartDataObject["legendColor"] = chartColors[index];
            chartDataArray.push(chartDataObject);
          });
        }
      });
    }
    return chartDataArray;
  };

  const initializeChartData = () => {
    let chartDiv = document.getElementById(type);
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(chartDiv, am4charts.PieChart);
    chart.data = modifiedChartData();
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";

    pieSeries.slices.template.events.on("hit", function (event) {
      if (type === "Company-Specific Research") {
        setSelectedSlice(event.target.dataItem.properties.category);
        pieSeries.hide();
      }
    });
    pieSeries.slices.template.cursorOverStyle =
      type == "Company-Specific Research"
        ? am4core.MouseCursorStyle.pointer
        : am4core.MouseCursorStyle.default;
    pieSeries.ticks.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.tooltip.label.maxWidth = 300;
    pieSeries.tooltip.label.wrap = true;
    pieSeries.tooltip.pointerOrientation = "vertical";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.labels.template.fontSize = 10;
    pieSeries.labels.template.fontWeight = 500;
    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.labels.template.adapter.add("radius", function (radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0;
      }
      return radius;
    });

    pieSeries.labels.template.adapter.add("fill", function (color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color("#000");
      }
      return color;
    });

    pieSeries.slices.template.events.on("over", function (event) {
      let currentSlice = event.target;
      let currentSliceName = event.target.dataItem.properties.category;
      if (type !== "Company-Specific Research") {
        let views = event.target.dataItem.values.value.value;
        let companyList = getCompanyArrayList(
          event.target.dataItem.properties.category,
          event.target.dataItem.values.value.value
        );
        currentSlice.tooltipText = `[bold] ${currentSliceName} | [bold] Companies: [medium]${
          companyList.length === 0 ? "N/A" : companyList
        } | [bold] Views: [medium]${views}`;
        currentSlice.invalidate();
        currentSlice.showTooltip();
      } else {
        let currentCount = event.target.dataItem.values.value.value;
        let currentPercent =
          event.target.dataItem.values.value.percent.toFixed(2);
        currentSlice.tooltipText = `${currentSliceName} | Count: [medium]${currentCount} (${currentPercent}%)`;
      }
    });
  };

  const getCompanyArrayList = (name, value) => {
    let companies = "";
    let lastIndex;
    selectedSliceData.forEach((item) => {
      if (item[name] === value) {
        let array = item?.company || item?.reports;
        array &&
          array.forEach((item) => {
            companies += item + ", ";
          });
        lastIndex = companies.lastIndexOf(",");
      }
    });
    return companies.slice(0, lastIndex);
  };

  const goBack = () => {
    setState("");
    setLoader1(true);
  };

  useEffect(() => {
    initializeChartData();
    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, [selectedSlice, loader]);

  let chartData = modifiedChartData();
  return (
    <div className="">
      {data ? (
        <div className="d-flex">
          {selectedSlice.length > 0 ? (
            <ChildPieChartDashboard2
              selectedSlice={selectedSlice}
              data={data}
              setState={setSelectedSlice}
              heading={heading}
              heading2={type}
              setLoader2={setLoader}
            />
          ) : (
            <div>
              {/* <h4 className="pie-chart-head">
                {heading} / <span className="pie-chart-head-2">{type}</span>
              </h4> */}
              <div className="d-flex">
                <button onClick={() => goBack()} className="dashboard-back-btn">
                  <span
                    data-tip
                    data-for="child-info"
                    className="material-icons mt-1"
                  >
                    keyboard_double_arrow_left
                  </span>
                  <ReactTooltip id="child-info" place="right" effect="solid">
                    Click to Drill Up the Data
                  </ReactTooltip>
                </button>
                <div className="pie-info">
                  <span
                    style={
                      type === "Company-Specific Research"
                        ? { color: "#1678CF" }
                        : { color: "" }
                    }
                    data-tip
                    data-for="parent-info"
                    className="material-icons icon-fs-20"
                  >
                    info
                  </span>
                  <ReactTooltip id="parent-info" place="right" effect="solid">
                    {type === "Company-Specific Research"
                      ? "Click on the any below section to drill down in detailed analysis"
                      : "Do not Drill Down"}
                  </ReactTooltip>
                </div>
              </div>
              {loader ? (
                <div class="lds-hourglass"></div>
              ) : (
                <div className="d-flex">
                  {selectedSliceIsEmpty ? (
                    <div className="data-not-found">
                      <img src={DataNotAvailable} alt="" />
                      <p>No {type} data found yet.</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "380px",
                        marginLeft: "50px",
                      }}
                      id={type}
                    ></div>
                  )}
                  {selectedSliceIsEmpty ? (
                    ""
                  ) : (
                    <div className="d-flex flex-column justify-content-center pie-chart-report-card">
                      <h3 style={{ color: "#5e5e5e", fontWeight: "600" }}>
                        {type}
                      </h3>
                      <div
                        style={{
                          height: "0.5px",
                          background: "#727272",
                          width: "100%",
                        }}
                      ></div>
                      {chartData?.map((chart) => (
                        <div
                          key={chart.color}
                          style={{ height: "30px", marginTop: "10px" }}
                          className="d-flex align-items-center"
                        >
                          <div
                            className="pie-legend-dots"
                            style={{ backgroundColor: chart.legendColor }}
                          ></div>
                          <p
                            data-tip
                            data-for={chart.name}
                            className="legend-text"
                          >
                            {chart.name.length <= 22
                              ? chart.name
                              : `${chart.name.slice(0, 19)}...`}
                          </p>
                          <ReactTooltip
                            id={chart.name}
                            place="top"
                            effect="solid"
                          >
                            {chart.name}
                          </ReactTooltip>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>No Data Available</div>
      )}
    </div>
  );
};

export default ChildPieChartDashboard;
