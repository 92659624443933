import moment from 'moment'

function TimeSince(date) {
  try {
    return moment(date).fromNow()
  } catch (error) {
    return '-'
  }
}

export default TimeSince
