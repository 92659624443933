import { Axios } from "../lib/axios";
export const BoardServices = {
  getBoards,
  getBoardDetails,
  getBoardContent,
  addBoard,
  editBoard,
  removeBoard,
  pinToBoard,
  getTotalBoards,
  getShareModes,
  removeFromBoard,
  shareBoard,
  addBoardContentComments,
  addBoardGeneralComments,
  removeBoardComment,
  updatedBoardComment,
  getBoardContentCommentsById,
  getBoardGeneralComments,
  downloadContent,
  getBoardbyId,
  boardPublish,
  getBoardCategories,
  addAndUpdateBoardCategory,
  rearrangeBoardCategoryOrder,
  deleteBoardCategory,
  boardUnPublish,
  deleteContentFromSubcategory,
};
function getBoards(url) {
  return Axios.get(url);
}
function getTotalBoards(mapping_id, sub_category_id, type) {
  return Axios.get(
    `boards/list?mapping_id=${mapping_id}&sub_category_id=${sub_category_id}&type=${type}`
  );
}
function getBoardDetails(boardId) {
  return Axios.get(`board/${boardId}`);
}
function getBoardContent(url) {
  return Axios.get(url);
}
function addBoard(body) {
  return Axios.post(`board`, body);
}
function editBoard(boardId, body) {
  return Axios.patch(`board/${boardId}`, body);
}
function removeBoard(boardId) {
  return Axios.delete(`board/${boardId}`);
}
function pinToBoard(boardId, type, body) {
  return Axios.post(`board/${boardId}/type/${type}/pin`, body);
}
function getShareModes() {
  return Axios.get(`modes?type=board`);
}
function removeFromBoard(id, sub_category_id) {
  return Axios.delete(`boardpinned/${id}?sub_category_id=${sub_category_id}`);
}
function shareBoard(boardId, body) {
  return Axios.post(`board/${boardId}/share`, body);
}
function addBoardContentComments(boardId, body) {
  return Axios.post(`board/${boardId}/comment`, body);
}
function addBoardGeneralComments(boardId, body) {
  return Axios.post(`board/${boardId}/comment`, body);
}
function removeBoardComment(commentId) {
  return Axios.delete(`boardcomment/${commentId}`);
}
function updatedBoardComment(commentId, body) {
  return Axios.patch(`boardcomment/${commentId}`, body);
}
function getBoardContentCommentsById(boardId, contentId) {
  return Axios.get(
    `board/${boardId}/comments?type=content&content_id=${contentId}`
  );
}
function getBoardGeneralComments(boardId) {
  return Axios.get(`board/${boardId}/comments?type=general`);
}
function downloadContent(body) {
  return Axios.post("export", body, { responseType: "blob" });
}

function getBoardbyId(boardid) {
  return Axios.get(`board/${boardid}`);
}

function boardPublish(boardid, body) {
  return Axios.post(`boardPublish/${boardid}`, body);
}

function boardUnPublish(boardid) {
  return Axios.post(`unPublishBoard/${boardid}`);
}

function getBoardCategories(board_id) {
  return Axios.get(`board-category/${board_id}`);
}

function addAndUpdateBoardCategory(board_id, body) {
  return Axios.post(`board-category/${board_id}`, body);
}

function rearrangeBoardCategoryOrder(board_id, body) {
  return Axios.put(`board-category/arrange/${board_id}`, body);
}

function deleteBoardCategory(id) {
  return Axios.delete(`/board-category/${id}`);
}

function deleteContentFromSubcategory(id, body) {
  return Axios.post(`board-category/content-delete/${id}`, body);
}
