import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const Dailog = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.decline} className={'modal-confirm'}>
      <ModalHeader toggle={props.decline}>
        {props.header}
      </ModalHeader>
      <ModalBody>
        {props.content}
      </ModalBody>
      {props.config.type && <ModalFooter>
        <Button color="secondary" className={'btn-info'} onClick={props.decline}>Cancel</Button>{' '}
        <Button color="primary" className={'btn-danger'} onClick={() => { props.accept(props.config) }}>Confirm</Button>
      </ModalFooter>}
    </Modal>
  )
}

export default Dailog
