let constants = {
  SITE: {
    TITLE: "TBR - Insight Center",
  },
  PAGE: {
    LOGIN: {
      LOGO_TEXT: "Technology Business Research, INC.",
    },
  },
  APPLICATION_ROUTE: {
    LOGIN: {
      NAME: "Login",
      ROUTE: "/login",
    },
    DASHBOARD_LIST: {
      NAME: "Dashboard",
      ROUTE: "/dashboard/list",
    },
    COMPANY: {
      LIST: {
        NAME: "Companies",
        COMPANY_REPORTS: "Company Research",
        ROUTE: "/company/list",
      },
      VIEW: {
        NAME: "Company Dashboard",
        ROUTE: "/company/:id/dashboard",
      },
      QUANTDATA: {
        NAME: "Quant Data",
        ROUTE: "/quantData",
      },
    },
    PROJECT: {
      LIST: {
        NAME: "Projects",
        ROUTE: "/project/list",
      },
      VIEW: {
        NAME: "Project Dashboard",
        ROUTE: "/project/:id/dashboard",
      },
    },
    BENCHMARK: {
      LIST: {
        NAME: "Benchmarks",
        ROUTE: "/benchmark/list",
      },
      VIEW: {
        NAME: "Benchmark Dashboard",
        ROUTE: "/benchmark/:id/dashboard",
      },
    },
    MARKET_FORECAST: {
      LIST: {
        NAME: "Market Forecasts",
        ROUTE: "/market-forecast/list",
      },
      VIEW: {
        NAME: "Market Forecast Dashboard",
        ROUTE: "/market-forecast/:id/dashboard/:catid?/:subcatid?",
      },
    },
    MARKET_LANDSCAPE: {
      LIST: {
        NAME: "Market Landscapes",
        ROUTE: "/market-landscape/list",
      },
      VIEW: {
        NAME: "Market Landscape Dashboard",
        ROUTE: "/market-landscape/:id/dashboard",
      },
    },
    CUSTOMER_STUDIES: {
      LIST: {
        NAME: "Customer Studies",
        ROUTE: "/customer-studies/list",
        HEADER_TITLE: "Customer Studies",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_CUSTOMER_STUDY: "Edit Customer Study",
        REMOVE_CUSTOMER_STUDY: "Remove Customer Study",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_CUSTOMER_STUDY_TOOLTIP: "Edit",
        REMOVE_CUSTOMER_STUDY_TOOLTIP: "Remove",
        ACTIVATE_CUSTOMER_STUDY_TOOLTIP: "Activate",
        INACTIVATE_CUSTOMER_STUDY_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Customer Studies Dashboard",
        ROUTE: "/customer-studies/:id/dashboard",
      },
    },
    ECOSYSTEM: {
      LIST: {
        NAME: "Ecosystems",
        ROUTE: "/ecosystems/list",
        HEADER_TITLE: "Ecosystems",
        ADD_BUTTON_TITLE: "Add",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_ECOSYSTEM: "Edit Ecosystem",
        REMOVE_ECOSYSTEM: "Remove Ecosystem",
        STATUS: "Status",
        MANAGE_CONTENT: "Manage Content",
        MANAGE_CONTENT_TOOLTIP: "Manage Content",
        EDIT_ECOSYSTEM_TOOLTIP: "Edit",
        REMOVE_ECOSYSTEM_TOOLTIP: "Remove",
        ACTIVATE_ECOSYSTEM_TOOLTIP: "Activate",
        INACTIVATE_ECOSYSTEM_TOOLTIP: "De-Activate",
      },
      VIEW: {
        NAME: "Ecosystems Dashboard",
        ROUTE: "/ecosystems/:id/dashboard",
      },
    },
    SPECIAL_REPORTS: {
      LIST: {
        NAME: "Special Reports",
        ROUTE: "/special-reports/list",
      },
      VIEW: {
        NAME: "Special Reports Dashboard",
        ROUTE: "/special-reports/:id/dashboard",
      },
    },

    CHANGE_PASSWORD: {
      NAME: "Change Password",
      ROUTE: "/change-password",
    },
    MANAGE_PREFERENCES: {
      NAME: "Manage Preferences",
      ROUTE: "/manage-preferences",
    },
    MANAGE_PREFERENCES_OLD: {
      NAME: "Manage Preferences",
      ROUTE: "/manage-preferences-old",
    },
    GLOBAL_SEARCH: {
      NAME: "Global Search",
      ROUTE: "/search",
    },
    EDIT_PREFERENCES: {
      NAME: "Edit Preferences",
      ROUTE: "/edit-preferences",
    },
    MY_WATCH_LIST: {
      NAME: "Edit Preferences",
      ROUTE: "/edit-preferences",
    },
    DASHBOARD: {
      NAME: "Dashboard",
      ROUTE: "/dashboard",
    },
    SEGMENTS: {
      NAME: "Segments",
      ROUTE: "/segment/list",
    },
    COMAPRISION_ANALYTICS: {
      NAME: "Data Visualizations",
      ROUTE: "/data-visualizations",
    },
    CUSTOM_ANALYTICS: {
      NAME: "Custom Analytics",
      ROUTE: "/custom-analytics",
    },
    MY_BOARDS: {
      LIST: {
        NAME: "Boards",
        ROUTE: "/my-boards",
        ADD_BUTTON_TITLE: "Create Board",
        NO_BOARDS_FOUND: "No Boards Found",
      },
      CREATE: {
        NAME: "Creat Board",
        ADD_BUTTON_TITLE: "Create Board",
        HEADER_TITLE: "Add Board",
      },
      EDIT: {
        NAME: "Edit Board",
        ADD_BUTTON_TITLE: "Edit Board",
        HEADER_TITLE: "Edit Board",
      },
      PIN_TO_BOARD: {
        TOOLTIP: "Pin to Board",
        NAME: "Add to Board",
        ADD_BUTTON_TITLE: "Add to Board",
        HEADER_TITLE: "Add to Board",
      },
      REMOVE_FROM_BOARD: {
        NAME: "Remove from Board",
        REMOVE_FROM_BOARD_BUTTON_TITLE: "Remove from Board",
        HEADER_TITLE: "Remove from Board",
      },
      SHARE_BOARD: {
        NAME: "Share Board",
        ADD_BUTTON_TITLE: "Share Board",
        HEADER_TITLE: "Share",
      },
      DASHBOARD: {
        NAME: "Board Dashboard",
        ROUTE: "/board/:id/dashboard",
      },
    },
    DATA_VISUALIZATION: {
      VIEW: {
        NAME: "Data Visualization",
        ROUTE: "/data-visualization",
        NO_TEMPLATE_FOUND: "No Visualizations Found",
      },
    },

    WEBINAR_CALENDAR: {
      LIST: {
        NAME: "Calendar",
        ROUTE: "/webinar-published-calendar",
      },
    },

    VISUALIZATION: {
      CREATE: {
        NAME: "Create Chart",
        ROUTE: "/data-visualization/create-chart",
      },
      EDIT: {
        NAME: "Edit Chart",
        ROUTE: "/data-visualization/edit-chart/:id",
      },
      PREVIEW_WITH_ID: {
        NAME: "Preview Chart With ID",
        ROUTE: "/data-visualization/preview/:id",
      },
      PREVIEW: {
        NAME: "Preview Chart",
        ROUTE: "/data-visualization/preview",
      },
      DAILOG: {
        HEADER: "Enter The Visualization Name",
      },
      SUCCESS_MESSAGE: "Visualization Saved Sucessfully",
      TEMPLATE_NAME_ENTER: "Enter Visualization Name",
      TEMPLATE_NAME_EXISTS: "Visualization Name Already Exists",
      TEMPLATE_NAME_EXCEEDS:
        "Visualization Name should be less than 25 characters",
      TEMPLATE_NAME_REQUIRED: "Visualization Name is required",
      TEMPLATE_DELETED: "Visualization Deleted Successfully.",
      TEMPLATE_UPDATE_SUCCESS_MESSAGE: "Visualization Updated Successfully",
    },
    DATA_VISUALIZATION_MAIN: {
      VIEW: {
        NAME: "Data Visualization Test",
        ROUTE: "/data-visualization-main",
        NO_TEMPLATE_FOUND: "No Visualization Found",
      },
    },
    VISUALIZATION_MAIN: {
      CREATE: {
        NAME: "Create Chart",
        ROUTE: "/data-visualization-main/create-chart",
      },
      EDIT: {
        NAME: "Edit Chart",
        ROUTE: "/data-visualization-main/edit-chart/:id",
      },
      PREVIEW_WITH_ID: {
        NAME: "Preview Chart With ID",
        ROUTE: "/data-visualization-main/preview/:id",
      },
      PREVIEW: {
        NAME: "Preview Chart",
        ROUTE: "/data-visualization-main/preview",
      },
      DAILOG: {
        HEADER: "Enter The Visualization Name",
      },
      CHART_TYPE: {
        LINE: "line",
        BUBBLE: "bubble",
        COLUMN: "bar",
      },
      SUCCESS_MESSAGE: "Visualization Saved Sucessfully",
      TEMPLATE_NAME_ENTER: "Enter Visualization Name",
      TEMPLATE_NAME_EXISTS: "Visualization Name Already Exists",
      TEMPLATE_NAME_EXCEEDS:
        "Visualization Name should be less than 25 characters",
      TEMPLATE_NAME_REQUIRED: "Visualization Name is required",
      TEMPLATE_DELETED: "Visualization Deleted Successfully.",
    },
    TBR_CALENDAR: {
      NAME: "TBR Calendar",
      ROUTE: "/tbr-calendar",
    },
    WEBNIORS: {
      NAME: "Webinars",
      ROUTE: "/webinars",
    },
    LOGOUT: {
      NAME: "Logout",
      ROUTE: "/logout",
    },
    TAXONOMY: {
      LIST: {
        NAME: "Taxonomy",
        ROUTE: "/taxonomy/list",
      },
    },
    CLIENT_USER: {
      LIST: {
        NAME: "Manage Users",
        ROUTE: "/user/list",
        HEADER_TITLE: "Manage Users",
        ADD_BUTTON_TITLE: "Add User",
        EDIT_BUTTON_TITLE: "Edit",
        REMOVE_BUTTON_TITLE: "Remove",
        ACTIVE_BUTTON_TITLE: "Active",
        INACTIVE_BUTTON_TITLE: "In-Active",
        EDIT_USER: "Edit User",
        REMOVE_USER: "Remove User",
        STATUS: "Status",
        RESEND_EMAIL_VERIFICATION: "Resend email verification",
        ACTIVATE_USER_TOOLTIP: "Activate User",
        INACTIVATE_USER_TOOLTIP: "De-Activate User",
        EDIT_USER_TOOLTIP: "Edit User Details",
        REMOVE_USER_TOOLTIP: "Remove User",
        RESEND_EMAIL_VERIFICATION_TOOLTIP: "Resend Email Verification Link",
      },
      CREATE: {
        NAME: "Add User",
        ROUTE: "/user/add",
        HEADER_TITLE: "Add User",
      },
      EDIT: {
        NAME: "Edit User",
        ROUTE: "/user/:id/edit",
        HEADER_TITLE: "Edit User",
      },
    },
    VERIFY_EMAIL: {
      NAME: "Verify Email",
      ROUTE: "/email-verify/:code",
    },
    RESET_PASSWORD: {
      NAME: "Set Password",
      ROUTE: "/reset-password/:code",
    },
    FORGOT_PASSWORD: {
      NAME: "Forgot Password",
      ROUTE: "/forgot-password",
    },
    DOWNLOAD: {
      LIST: {
        NAME: "Files",
        HEADER_TITLE: "Files",
        DOWNLOAD_TOOLTIP: "Download",
        DOWNLOAD: "Document",
      },
    },
    ACCESS_DENIED: {
      NAME: "Access Denied",
      ROUTE: "/access-denied",
    },
    FAQS: {
      HEADER: {
        TITLE: "FAQs",
        CATEGORY_TITLE: "Category",
      },
      LIST: {
        NAME: "FAQs",
        ROUTE: "/FAQs",
      },
    },
    NOTIFICATIONS: {
      HEADER: {
        TITLE: "Notifications",
      },
      LIST: {
        NAME: "Notification",
        ROUTE: "/notifications",
      },
    },
    RESOURCES_AND_FAQS: {
      HEADER: {
        TITLE: "Resources and FAQs",
        CATEGORY_TITLE: "Category",
      },
      LIST: {
        NAME: "RESOURCE_AND_FAQ",
        ROUTE: "/resources",
      },
    },
  },
  ACL: {
    PERMISSION_KEY: "permissions",
    BOARD_PERMISSION_KEY: "mode_permissions",
    ENTITY_TYPE: {
      NAVIGATION: "TBR-NAVIGATION",
      BUTTON: "TBR-BUTTON",
      DIV: "TBR-DIV",
      SPAN: "TBR-SPAN",
    },
    CLIENT_USER_RESOURCE: {
      TITLE: "clientuser",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        SHOW: "show",
      },
    },
    COMPANY_RESOURCE: {
      TITLE: "company",
      CONTENT_TITLE: "company_content",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "update",
        DOWNLOAD: "show",
        EXPORT: "export",
      },
    },
    PROJECT_RESOURCE: {
      TITLE: "project",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "update",
        DOWNLOAD: "export",
      },
    },
    MY_BOARDS_RESOURCE: {
      TITLE: "board",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
        SHARE: "share",
        DASHBOARD: "show",
        PIN: "pin",
        UN_PIN: "unpin",
        COMMENT_LIST: "comment.list",
        COMMENT_EDIT: "comment.update",
        COMMENT_DELETE: "comment.destroy",
        COMMENT_CREATE: "comment.create",
        COMMENT_STATUS: "comment.update",
        PUBLISH: "publish",
      },
    },

    MY_BOARDS_RESOURCE_MAIN: {
      TITLE: "board",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        SHARE: "share",
        DASHBOARD: "show",
        PIN: "pin",
        UN_PIN: "unpin",
        COMMENT_LIST: "comment.list",
        COMMENT_EDIT: "comment.update",
        COMMENT_DELETE: "comment.destroy",
        COMMENT_CREATE: "comment.create",
        COMMENT_STATUS: "comment.update",
      },
    },

    MY_BOARDS_RESOURCE_EDIT: {
      TITLE: "edit",
      ACTIONS: {
        LIST: "board.list",
        CREATE: "board.create",
        EDIT: "board.update",
        DELETE: "board.destroy",
        STATUS: "board.update",
        SHARE: "board.share",
        DASHBOARD: "board.show",
        PIN: "board.pin",
        UN_PIN: "board.unpin",
        COMMENT_LIST: "board.comment.list",
        COMMENT_EDIT: "board.comment.update",
        COMMENT_DELETE: "board.comment.destroy",
        COMMENT_CREATE: "board.comment.create",
        COMMENT_STATUS: "board.comment.update",
      },
    },
    MY_BOARDS_RESOURCE_VIEW: {
      TITLE: "view",
      ACTIONS: {
        LIST: "board.list",
        CREATE: "board.create",
        EDIT: "board.update",
        DELETE: "board.destroy",
        STATUS: "board.update",
        SHARE: "board.share",
        DASHBOARD: "board.show",
        PIN: "board.pin",
        UN_PIN: "board.unpin",
        COMMENT_LIST: "board.comment.list",
        COMMENT_EDIT: "board.comment.update",
        COMMENT_DELETE: "board.comment.destroy",
        COMMENT_CREATE: "board.comment.create",
        COMMENT_STATUS: "board.comment.update",
      },
    },
    MY_BOARDS_RESOURCE_COMMENT: {
      TITLE: "comment",
      ACTIONS: {
        LIST: "board.list",
        CREATE: "board.create",
        EDIT: "board.update",
        DELETE: "board.destroy",
        STATUS: "board.update",
        SHARE: "board.share",
        DASHBOARD: "board.show",
        PIN: "board.pin",
        UN_PIN: "board.unpin",
        COMMENT_LIST: "board.comment.list",
        COMMENT_EDIT: "board.comment.update",
        COMMENT_DELETE: "board.comment.destroy",
        COMMENT_CREATE: "board.comment.create",
        COMMENT_STATUS: "board.comment.update",
      },
    },
    MY_BOARDS_RESOURCE_VIEW_COMMENT: {
      TITLE: "view_comment",
      ACTIONS: {
        LIST: "board.list",
        CREATE: "board.create",
        EDIT: "board.update",
        DELETE: "board.destroy",
        STATUS: "board.update",
        SHARE: "board.share",
        DASHBOARD: "board.show",
        PIN: "board.pin",
        UN_PIN: "board.unpin",
        COMMENT_LIST: "board.comment.list",
        COMMENT_EDIT: "board.comment.update",
        COMMENT_DELETE: "board.comment.destroy",
        COMMENT_CREATE: "board.comment.create",
        COMMENT_STATUS: "board.comment.update",
      },
    },
    MY_VISUALIZATION_RESOURCE: {
      TITLE: "visualization",
      ACTIONS: {
        CREATE: "create",
        EXPORT: "export",
        PUBLISH: "publish",
      },
    },
    BENCHMARK_RESOURCE: {
      TITLE: "benchmark",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "delete",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
    SPECIAL_REPORTS_RESOURCE: {
      TITLE: "special_reports",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "delete",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
    CUSTOMER_STUDY_RESOURCE: {
      TITLE: "customer_study",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        MANAGE: "show",
        DOWNLOAD: "export",
      },
    },
    DOWNLOADS_RESOURCE: {
      TITLE: "user",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "add",
        DOWNLOAD: "update",
      },
    },
    MARKET_FORECAST_RESOURCE: {
      TITLE: "market_forecast",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
    MARKET_LANDSCAPE_RESOURCE: {
      TITLE: "market_landscape",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
    CUSTOMER_STUDIES_RESOURCE: {
      TITLE: "customer_studies",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
    ECOSYSTEM_RESOURCE: {
      TITLE: "ecosystem",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
        MANAGE: "show",
      },
    },
    WEBINAR_RESOURCE: {
      TITLE: "webinar",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },

    QUANTDATA: {
      TITLE: "quantdata",
      ACTIONS: {
        EXPORT: "export",
      },
    },
    SPECIAL_REPORTS_RESOURCE: {
      TITLE: "special_report",
      ACTIONS: {
        LIST: "list",
        EDIT: "update",
        DELETE: "destroy",
        STATUS: "update",
        CREATE: "create",
        DOWNLOAD: "export",
      },
    },
  },

  PAGINATION: {
    DEFAULT_PAGE_SIZE: 10,
    DEFAULT_PAGE_NO: 1,
    COMPANY_DEFAULT_SORT_ORDER: "asc",
    COMPANY_DEFAULT_SORT_COLUMN: "name",
    PAGE_SIZE_OPTIONS: [10, 25],
    NO_RECORDS_FOUND: "No Records Found",
    DEFAULT_SORT_COLUMN: "updated_at",
    USER_DEFAULT_SORT_COLUMN: "created_at",
    DEFAULT_SORT_ORDER: "desc",
  },
  TBRI_METRIC_CODES: {
    REVENUE: "REV",
    OPERATING_INCOME: "OPINC",
  },
  COMPANY: {
    LIST: {
      NAME: "Companies",
    },
    VIEW: {
      NAME: "Company Dashboard",
      DOWNLOAD_TOOLTIP: "Download",
      REDIRECT_TO_BENCHMARKS_TOOLTIP: "Benchmark Reports",
      REDIRECT_TO_MARKET_FORECASTS_TOOLTIP: "Market Forecasts",
      REDIRECT_TO_MARKET_LANDSCAPES_TOOLTIP: "Market Landscapes",
      REDIRECT_TO_SPECIAL_REPORTS_TOOLTIP: "Special Reports",
      FILES_TOOLTIP: "Files",
    },
  },
  PROJECT: {
    LIST: {
      NAME: "Projects",
    },
    VIEW: {
      NAME: "Project Dashboard",
      DOWNLOAD_TOOLTIP: "Download",
    },
  },
  BENCHMARK: {
    LIST: {
      NAME: "Benchmarks",
    },
    VIEW: {
      NAME: "Benchmark Dashboard",
      DOWNLOAD_TOOLTIP: "Download",
    },
  },
  MARKET_FORECAST: {
    LIST: {
      NAME: "Market Forecasts",
    },
    VIEW: {
      NAME: "Market Forecast Dashboard",
      DOWNLOAD_TOOLTIP: "Download",
    },
  },
  MARKET_LANDSCAPE: {
    LIST: {
      NAME: "Market Landscapes",
    },
    VIEW: {
      NAME: "Market Landscape Dashboard",
      DOWNLOAD_TOOLTIP: "Download",
    },
  },
  CLIENT_USER: {
    CREATE: {
      SUCCESS: "User Created Successfully",
    },
    EDIT: {
      SUCCESS: "User Details Updated Successfully",
    },
  },
  MY_BOARDS: {
    LIST: {
      REMOVE_BOARD_CONFIRMATION: "Are you sure want to remove the board?",
      PUBLISH_BOARD_CONFIRMATION: "Are you sure want to add Enterprise board?",
      UNPUBLISH_BOARD_CONFIRMATION:
        "Are you sure want to remove from the Enterprise board?",
      ADMIN_PUBLISH_BOARD_CONFIRMATION: "Are you sure want to add TBR board?",
      ADMIN_UNPUBLISH_BOARD_CONFIRMATION:
        "Are you sure want to remove from the TBR board?",
      PUBLISHED_REMOVE_BOARD_CONFIRMATION:
        "This Board is published, on deleting it will be removed from all collections. Do you want to proceed ?",
    },
    CREATE: {
      SUCCESS: "Board Created Successfully",
    },
    EDIT: {
      SUCCESS: "Board Details Updated Successfully",
    },
    DELETE: {
      SUCCESS: "Board Deleted Successfully",
    },
    PIN: {
      SUCCESS: "Added to Board Successfully",
    },
    UN_PIN: {
      SUCCESS: "Removed from Board Successfully",
      REMOVE_FROM_BOARD_CONFIRMATION:
        "Are you sure want to remove from the board ?",
      REMOVE_SUBCATEGORY_FROM_BOARD:
        "Are you sure want to remove subcategory from the board ?",
      REMOVE_CATEGORY_FROM_BOARD:
        "Are you sure want to remove category from the board ?",
      REMOVE_CONTENT_FROM_BOARD:
        "Are you sure want to remove content from the board?",
    },

    SHARE: {
      SUCCESS: "Board Shared Successfully",
    },
    COMMENT: {
      ADD_BUTTON_TITLE: "Add",
      PUBLIC_TOOLTIP: "Public (Everyone can see)",
      PRIVATE_TOOLTIP: "Only me",
      POST_BUTTON_TITLE: "Post",
      ADD_BUTTON_TOOLTIP: "Add Comment",
      GENERAL_COMMENTS_TOOLTIP: "General Comments",
    },
    ENABLE_DISABLE_COMMENTS: "enableDisableComments",
    SHARED_WITH_USERS: "shared with users",
    DELETE_BOARD: "delete board",
    PUBLISH: {
      ADMIN_PUBLISH_SUCCESS: "Added to TBR Boards Successfully",
      ADMIN_UNPUBLISH_SUCCESS: "Removed from TBR Boards Successfully",
      CLIENT_PUBLISH_SUCCESS: "Added to Enterprise Boards Successfully",
      CLIENT_UNPUBLISH_SUCCESS: "Removed from Enterprise Boards Successfully",
      ADMIN_PUBLISH: "Add to TBR Boards",
      ADMIN_UNPUBLISHED: "Remove from TBR Boards",
      CLIENT_PUBLISH: "Add to Enterprise Boards",
      CLIENT_UNPUBLISHED: "Remove from Enterprise Boards",
      PUBLISHED: "Published",
    },
  },
  CHANGE_PASSWORD: {
    HEADER_TITLE: "Change Password",
    SUCCESS:
      "Password changed successfully. You will be logged out in a second",
  },
  CHANGE_NAME: {
    HEADER_TITLE: "Change Display Name",
    SUCCESS: "Display name changed successfully.",
  },
  API_CREDENTIAL_TITLE: {
    HEADER_TITLE: "API Credential",
    SUCCESS: "API Credentials Regenerated successfully",
  },
  RESET_PASSWORD: {
    HEADER_TITLE: "Set Password",
    SUCCESS: "Password set successfully.",
  },
  VERIFY_EMAIL: {
    SUCCESS:
      "E-mail has been verified successfully! \n \n Please set your password.",
    SUCCESS2:
      "E-mail already verified successfully! \n \n Please set your password.",
  },
  ERROR: {
    SOMETHING_WENT_WRONG: "Something Went Wrong! Please Try Again",
    API_ERRORS: "Errors",
    ALL_FIELDS_ERRORS: "All fields required!",
    REQUEST_NOT_FOUND: "Request Not Found",
    CODE_EXPIRED:
      "User verification code got expired and Redirecting to forget password page.",
  },
  WARNING: {
    CONTENT_ACCESS_DENIED_TEXT:
      "Sorry, You are not allowed to access. Please contact your administrator.",
    CONTENT_ACCESS_DENIED_TITLE: "No Access",
    CONTENT_INACTIVE_TEXT:
      "Sorry, You are not allowed to access. Please contact your administrator.",
    CONTENT_INACTIVE_TITLE: "No Access",
  },
  FORM: {
    MANDATORY_FIELD: "Please fill the field",
    INVALID_EMAIL: "Please fill the valid E-mail",
    INVALID_CONTACT_NUMBER: "Please fill the valid contact number",
  },
  REG_EX: {
    PASSWORD_REG_EX:
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_?])(?=.{8,})",
    EMAIL_REG_EX:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE_REG_EX: /^[0-9\+]{10}$/,
    NAME_SPACE_REG_EX: /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/,
    NAME_REG_EX: /^\d*[a-zA-Z][a-zA-Z\d]+(\s+[-a-zA-Z0-9-()]+)*$/,
  },
  MY_BOARDS_LABELS: {
    COMPANY_CATEGORIES: {
      INSIGHTS: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#00AA5D" },
      STRATEGY: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "rgb(25, 15, 178)" },
      FINANCIAL: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "rgb(152, 169, 5)" },
      REFERENCES: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "rgb(178, 15, 165)" },
      DEFAULT: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#CCC" },
    },
    PROJECT: {
      DEFAULT: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#CCC" },
    },
    BENCHMARK: {
      DEFAULT: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#00AA5D" },
    },
    MARKET_FORECAST: {
      DEFAULT: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#00AA5D" },
    },
    MARKET_LANDSCAPE: {
      DEFAULT: { TEXT_COLOR: "#FFF", BACKGROUND_COLOR: "#00AA5D" },
    },
  },
  MY_BOARDS_SHARE_MODE: {
    VIEW: { SLUG: "view" },
    EDIT: { SLUG: "edit" },
    COMMENT: { SLUG: "comment" },
    VIEW_COMMENT: { SLUG: "view_comment" },
  },
  ELLIPSIS: {
    TEXT_MAX_LENGTH: 30,
  },
  YEAR_DROPDOWN: {
    LIMIT: 10,
  },
  TOKEN: {
    INVALID_MESSAGE: "Token is invalid!",
  },
  VISUALIZATION: {
    CHART: {
      PERIOD: "PERIOD",
    },
    CHART_TYPE: {
      LINE: "line",
      BUBBLE: "bubble",
      COLUMN: "bar",
    },
    SAVE_AS_TEMPLATE: "Save as Template",
    UPDATE_TEMPLATE: "Update Template",
    PERIOD_TYPE: {
      QUARTERLY: "Quarterly",
      ANNUAL: "Annual",
    },
    BUBBLE_CHART_TYPE: {
      ZOOMABLE_CHART: "Zoomable",
      ANIMATED_CHART: "Animated",
    },
    CHARACTER_LENGTH: 29,
    SHARED_WITH_USERS: "shared with users",
    ENABLE_DISABLE_COMMENTS: "enableDisableComments",
    DELETE_BOARD: "delete template",
    SHARE: {
      SUCCESS: "Visualization Shared Successfully",
    },
    PUBLISH: {
      ADMIN_PUBLISH: "Add to TBR Visualization",
      ADMIN_UNPUBLISHED: "Remove from TBR Visualization",
      CLIENT_PUBLISH: "Add to Enterprise Visualization",
      CLIENT_UNPUBLISHED: "Remove from Enterprise Visualization",
      PUBLISHED: "Published",
    },
  },
  GA: {
    EVENTS: {
      PAGE_VIEW: "page_visit",
    },
    CUSTOM_EVENTS: {
      SCROLL: "scroll",
      USER_ENGAGEMENT_TIME: "time_on_page",
      LOGIN: "login_event",
      DOWNLOAD_VIEW: "download_list",
    },
    PAGE_TYPE: {
      BENCHMARK_DETAIL: "BENCHMARK DETAIL",
      BOARD_DETAIL: "BOARD DETAIL",
      COMPANY_DETAIL: "COMPANY DETAIL",
      COMPANY_LIST: "COMPANY LIST",
      MARKET_FORECAST_DETAIL: "MARKET FORECAST DETAIL",
      MARKET_LANDSCAPE_DETAIL: "MARKET LANDSCAPE DETAIL",
      PROJECT_DETAIL: "PROJECT_DETAIL",
    },
  },
  SESSION_POP_UP: {
    RENEWED_SUCCESS: "Session renewed successfully.",
    OPEN_POP_UP_BEFORE_THESE_SEC: 300,
    EVENT: "token_changes_in_localStorage",
  },
  ROLES: {
    CLIENT_USER: {
      LEAD: "lead",
      MEMBER: "member",
    },
  },
  DATAVISUALIZATION_TEMPLATE: {
    REMOVE_TEMPLATE_CONFIRMATION_MESSAGE:
      "Are you sure want to remove the visualization?",
    REMOVE_PUBLISHED_TEMPLATE_CONFIRMATION_MESSAGE:
      "This visualization is published, on deleting it will be removed from all collections. Do you want to proceed ?",
  },
  REACT_SELECT_MODULE: {
    SELECTED_OPTIONS_COLOR: "#DCEDFF",
    SELECTED_OPTIONS_LABEL_COLOR: "#4D88C9",
    SELECTED_OPTIONS_REMOVER_COLOR: "#4D88C9",
    SELECTED_OPTIONS_REMOVER_HOVER_COLOR: "red",
    SELECTED_OPTIONS_REMOVER_HOVER_BACKGROUND_COLOR: "#f8d7da",
    SELECTED_OPTIONS_REMOVER_HOVER_BORDER_COLOR: "#f5c6cb",
    SELECTED_OPTIONS_HOVER_COLOR: "white",
  },

  DASHBOARD: {
    VISUALIZATION_TABS: {
      MY_VISUALIZATION: "My Visualizations",
      MY_TYPE: "",
      TBRI: "TBRI Visualization Collection",
      TBRI_TYPE: "tbri",
      ENTERPRISE: "Enterprise Visualization Collection",
      ENTERPRISE_TYPE: "enterprise",
      SHARED: "Shared Visualizations",
      SHARED_TYPE: "shared",
    },
    BOARD_TABS: {
      MY_BOARD: "My Boards",
      MY_BOARD_TYPE: "my",
      ENTERPRISE: "Enterprise Boards",
      ENTERPRISE_TYPE: "enterprise",
      TBRI_BOARD: "TBRI Boards",
      TBRI_BOARD_TYPE: "tbri",
      SHARED_BOARD: "Shared Boards",
      SHARED_BOARD_TYPE: "shared",
    },
  },
  PC: {
    DIGEST: "digest",
    IMMEDIATE: "immediate",
    DAILY: "daily",
    WEEKLY: "weekly",
    SMALL_CASE: {
      COMPANY: "company",
      BENCHMARK: "benchmark",
      MARKET_LANDSCAPE: "market_landscape",
      MARKET_FORECAST: "market_forecast",
      SPECIAL_REPORT: "special_report",
      CUSTOMER_STUDIES: "customer_study",
      ECOSYSTEM: "ecosystem",
      PROJECT: "project",
      BOARD: "boards",
      VISUALIZATION: "visualization",
    },
    UPPER_CASE: {
      COMPANY: "Companies",
      BENCHMARK: "Benchmarks",
      MARKET_LANDSCAPE: "Market Landscapes",
      MARKET_FORECAST: "Market Forecasts",
      SPECIAL_REPORT: "Special Reports",
      CUSTOMER_STUDIES: "Customer Studies",
      ECOSYSTEM: "Ecosystems",
    },
  },
  CALENDAR: {
    WEBINAR_TYPE: {
      PUBLISHED: "Published",
      DRAFT: "Drafted",
    },
    SLUG: {
      PUBLISHED: "publish",
      DRAFT: "draft",
    },
    PUBLISHED_TYPE: {
      COMPANY: "Company Research",
      CUSTOMER_STUDIES: "Customer Studies",
      BENCHMARK: "Benchmarks",
      SPECIAL_REPORT: "Special Reports",
      MARKET_FORECAST: "Market Forecasts",
      MARKET_LANDSCAPE: "Market Landscapes",
      ECOSYSTEM: "Ecosystems",
    },
    PUBLISHED_SLUG: {
      COMPANY: "company_report",
      CUSTOMER_STUDIES: "customer_studies",
      ECOSYSTEM: "ecosystem",
      BENCHMARK: "benchmark",
      SPECIAL_REPORT: "special_report",
      MARKET_FORECAST: "market_forecast",
      MARKET_LANDSCAPE: "market_landscape",
    },
  },
  APP_URLS: {
    ADMIN: {
      LOCAL: "http://localhost:3001",
      STAGE: "https://admin.staging.tbrinsightcenter.com",
      TEST: "https://admin.test.tbrinsightcenter.com",
      PROD: "https://admin.tbrinsightcenter.com",
    },
    CLIENT: {
      LOCAL: "localhost",
      STAGE: "staging.tbrinsightcenter.com",
      TEST: "test.tbrinsightcenter.com",
      PROD: "tbrinsightcenter.com",
    },
  },
};
export default constants;
