const _ = require('lodash');
function CustomCloneDeep(data) {
  let clonedData
  try {
    clonedData = _.cloneDeep(data)
  } catch (error) {

  }
  return clonedData
}

export default CustomCloneDeep
