import React from "react";
import { connect } from "react-redux";
import { loginUserSuccess } from "../Store/Actions/User";
import constants from "../Utils/constants";

const ToggleSwitch = (props) => {
  let hostName = window.location.hostname;
  let url =
    "company/list?page=1&page_size=10&sort_order=desc&sort_column=updated_at";
  const goToClient = (link) => {
    if (link === "admin") {
      setTimeout(() => {
        if (hostName === constants.APP_URLS.CLIENT.LOCAL) {
          window.location = `${constants.APP_URLS.ADMIN.LOCAL}/${url}`;
        } else if (hostName === constants.APP_URLS.CLIENT.TEST) {
          window.location = `${constants.APP_URLS.ADMIN.TEST}/${url}`;
        } else if (hostName === constants.APP_URLS.CLIENT.STAGE) {
          window.location = `${constants.APP_URLS.ADMIN.STAGE}/${url}`;
        } else if (hostName === constants.APP_URLS.CLIENT.PROD) {
          window.location = `${constants.APP_URLS.ADMIN.PROD}/${url}`;
        }
      }, 700);
    }
  };

  return (
    <div className="back-forth">
      <div
        onClick={() => goToClient("admin")}
        className="mt-2 d-flex align-items-center justify-content-between"
      >
        <h3 style={{ marginRight: "10px" }} className="toggle-unselect">
          Admin
        </h3>
        <label className="toggle-switch">
          <input type="checkbox" />
          <span className="slider"></span>
        </label>
        <h3 style={{ marginLeft: "10px" }} className="toggle-select">
          Client
        </h3>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
  };
}

export default connect(null, mapDispatchToProps)(ToggleSwitch);
