import React, { memo } from "react";
import { Route, Switch } from "react-router-dom";
import ForgotPassword from "../Pages/ForgotPassword";
import Layout from "../Pages/Layout";
import Login from "../Pages/Login";
import NotFound from "../Pages/NotFound";
import ResetPassword from "../Pages/ResetPassword";
import VerifyEmail from "../Pages/VerifyEmail";
import PrivateRoute from "../Routes/PrivateRoutes";
import PublicRoute from "../Routes/PublicRoutes";
import constants from "../Utils/constants";
const Routes = () => {
  return (
    <Switch>
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.LOGIN.ROUTE}
        exact
        component={Login}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.VERIFY_EMAIL.ROUTE}
        exact
        component={VerifyEmail}
      />
      <PublicRoute
        restricted={true}
        path={constants.APPLICATION_ROUTE.RESET_PASSWORD.ROUTE}
        exact
        component={ResetPassword}
      />
      <PublicRoute
        restricted={false}
        path={constants.APPLICATION_ROUTE.FORGOT_PASSWORD.ROUTE}
        exact
        component={ForgotPassword}
      />
      <PrivateRoute restricted={false} path="/" component={Layout} />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
};

export default memo(Routes);
