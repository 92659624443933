import React from "react";
import upcoming_event_right_icon from "../Pages/SvgIcons/upcoming_event_right_icon.svg";
import { useHistory } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";
import CustomReactTooltip from "../Components/CustomReactTooltip";

const getReportClass = (reportType) => {
  switch (reportType) {
    case "company_report":
      return "companyReportEvent";
    case "benchmark":
      return "benchmarkEvent";
    case "market_landscape":
      return "marketLandscapeEvent";
    case "special_report":
      return "specialReportEvent";
    case "ecosystem":
      return "ecosystemEvent";
    case "market_forecast":
      return "marketForecastEvent";
    case "customer_studies":
      return "customerStudiesEvent";
    default:
      break;
  }
};

const UpcomingEventsDashboard = ({ data }) => {
  let history = useHistory();
  return (
    <div style={{ width: "100%" }}>
      {data.length === 0 ? (
        <div
          style={{
            display: "block",
            marginTop: "20px",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          No upcoming events
        </div>
      ) : (
        data.map((event, index) => (
          <div
            key={index}
            className={`${getReportClass(event.report_type)} ${
              event.type === "webinar"
                ? "webinar-upcoming-event"
                : "publish-calendar-event"
            } upcoming-event`}
            onClick={(e) => {
              history.push({
                pathname: formatRoute(
                  constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE,
                  {}
                ),
                state: {
                  event: event,
                },
              });
            }}
          >
            <div className="row">
              <div className="col-9 grid-item">
                <div
                  className="upcoming-event-header"
                  data-for="upcoming_event_title"
                  data-tip={event.title}
                >
                  {event.title.length > 25
                    ? event.title.slice(0, 25) + "..."
                    : event.title}
                  <CustomReactTooltip id="upcoming_event_title" />
                </div>

                <div className="date">
                  {new Date(event.published_date).toLocaleDateString("en-us", {
                    weekday: "long",
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>
              </div>
              <div className="col-3 d-flex justify-content-center align-items-center">
                <div className="recent-view-collapse-icon">
                  <img src={upcoming_event_right_icon} alt="reportButtonIcon" />
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UpcomingEventsDashboard;
