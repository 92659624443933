import React, { useState } from "react";
import Collapsible from "react-collapsible";

const PreferenceCenterSegmentReportsBox = ({ segment, isDisabled }) => {
  const [openCollapse, setOpenCollapse] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const getReportCountLabel = () => {
    const reportCount = segment?.reports.filter(
      (report) => report.is_access && report.is_selected
    ).length;
    const remainingCount = reportCount - 4;
    if (remainingCount > 0 && !showAll) {
      return `+${remainingCount} more`;
    }
    return null;
  };

  const getReportsToShow = () => {
    const reports = segment?.reports.filter(
      (report) => report.is_access && report.is_selected
    );
    if (showAll) {
      return reports;
    }
    return reports.slice(0, 4);
  };

  return (
    <div
      className={isDisabled ? "pref-cmp-collapse-disable" : "pref-cmp-collapse"}
      key={segment.id}
    >
      <Collapsible
        open={true}
        trigger={
          <div
            className={`d-flex justify-content-between ${
              openCollapse
                ? "pref-segment-head"
                : "pref-seg-head-without-border"
            }`}
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            <h4 className="pref-cmp-collapse-header">{segment?.name}</h4>
            <div className="pref-cmp-collapse-icon">
              <span
                className="material-icons-round icon-fs-14 cursor-pointer"
                style={{ color: "#FFFFFF", fontWeight: "400" }}
              >
                {openCollapse ? "remove" : "add"}
              </span>
            </div>
          </div>
        }
      >
        <div className="cmp-list-container">
        {getReportsToShow().map((report) => (
            <div key={report.id} className="cmp-content mt-2 mr-3">
              {report.name}
            </div>
          ))}
          {getReportCountLabel() && !showAll && (
            <div
              className="cmp-content mt-2 mr-3 cursor-pointer"
              onClick={toggleShowAll}
            >
              {getReportCountLabel()}
            </div>)}
        </div>
      </Collapsible>
    </div>
  );
};

export default PreferenceCenterSegmentReportsBox;
