import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink as RRNavLink } from "react-router-dom";
import { formatRoute } from "react-router-named-routes";
import { NavLink } from "reactstrap";

import Dailog from "../Components/Dailog";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import DynamicTableComponent from "../Components/DynamicTableComponent";

import Search from "../Components/searchComponent";
import { ProjectServices } from "../Services/Project";
import { activeMenuTab } from "../Store/Actions/User";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import ComapnyDetailsDateTimeFormatter from "../Utils/ComapnyDetailsDateTimeFormatter";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";
import CustomEllipsis from "../Utils/CustomEllipsis";

let tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segments",
    title: "Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "additional_segments",
    title: "Additional Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "project_manager",
    title: "Project Manager",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    style: { textAlign: "left" },
  },
];

let tableHeaderWithClient = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segments",
    title: "Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "additional_segments",
    title: "Additional Segments",
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: { textTransform: "capitalize", textAlign: "left" },
    visibility: true,
  },
  {
    id: "project_manager",
    title: "Project Manager",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "client",
    title: "Client",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: true,
    style: { textAlign: "left" },
  },
];
const showActions = false;
class ListProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      sortColumn: constants.PAGINATION.DEFAULT_SORT_COLUMN,
      sortOrder: constants.PAGINATION.DEFAULT_SORT_ORDER,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      name: "",
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
    };
  }
  componentDidMount() {
    DocumentTitle(constants.APPLICATION_ROUTE.PROJECT.LIST.NAME);
    let params = queryString.parse(this.props.location.search);
    this.setState(
      {
        pageNo: params.page
          ? parseInt(params.page)
          : constants.PAGINATION.DEFAULT_PAGE_NO,
        pageSize: params.page_size
          ? parseInt(params.page_size)
          : constants.PAGINATION.DEFAULT_PAGE_SIZE,
        sortColumn: params.sort_column
          ? params.sort_column
          : constants.PAGINATION.DEFAULT_SORT_COLUMN,
        sortOrder: params.sort_order
          ? params.sort_order
          : constants.PAGINATION.DEFAULT_SORT_ORDER,
        name: params.search_value ? params.search_value : "",
      },
      () => {
        this.getTableData();
      }
    );
    this.props.activeMenuTab(constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE);
  }
  pagePrevious = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo - 1,
      }),
      () => this.getTableData()
    );
  };
  pageNext = () => {
    this.setState(
      (prevState) => ({
        pageNo: prevState.pageNo + 1,
      }),
      () => this.getTableData()
    );
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.getTableData());
  };
  handleSort = (clickedColumn, key) => {
    this.setState(
      {
        sortColumn: clickedColumn,
        segmentSort: false,
        sortOrder: this.state.sortOrder === "desc" ? "asc" : "desc",
      },
      () => this.getTableData()
    );
  };
  handleSearch = () => {
    this.setState({ pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  goToProject = (access, project) => {
    if (access) {
      this.props.history &&
        this.props.history.push({
          //for unit testing in jest (this.props.history &&) Use
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.PROJECT.VIEW.ROUTE,
            { id: project.id }
          ),
        });
    }
  };
  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_user":
        this.removeClientUser(config.id);
        break;
      case "de-activate_user":
        this.toggleClientUser(config.id, 0);
        break;
      case "activate_user":
        this.toggleClientUser(config.id, 1);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  getTableData = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.changeRoute();
    let url = "/projects";
    ProjectServices.getProjects(
      `${url}?page=` +
        this.state.pageNo +
        "&page_size=" +
        this.state.pageSize +
        queryParams
    )
      .then((res) => {
        let clientData = res.data.data;
        let count =
          (res.data && res.data.meta && res.data.meta.total_count) || 0;
        clientData = clientData.map((data) => {
          let projectRepoName = CustomEllipsis(data.name);
          const additionalSegments = CustomEllipsis(
            this.props?.home?.segments
              ?.filter((segment) =>
                data?.supported_segments?.includes(segment.id)
              )
              ?.map((c) => c.name)
              .join(", ")
          ) ?? { text: "-", ellipsis: false, fullText: "" };
          return {
            id: data.id,
            name: (
              <NavLink
                tag={RRNavLink}
                style={{
                  padding: 0,
                  color: "#046CD9",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                exact
                to={formatRoute(
                  constants.APPLICATION_ROUTE.PROJECT.VIEW.ROUTE,
                  { id: data["id"] }
                )}
              >
                <label
                  className={`mb-0`}
                  style={{
                    color: "#046CD9",
                    cursor: "pointer",
                    textWrap: "wrap",
                  }}
                >
                  {data.name}
                </label>
              </NavLink>
            ),
            is_active:
              data.is_active === 1 ? (
                <span
                  onClick={() => {
                    this.goToProject(true, data);
                  }}
                  className="material-icons-round icon allowed-access text-green"
                  aria-hidden="true"
                  data-for={`tooltip-${data.id}-active`}
                  data-tip={"Active"}
                  data-iscapture="true"
                >
                  circle
                  <CustomReactTooltip
                    id={`tooltip-${data.id}-active`}
                    multiline={true}
                  />
                </span>
              ) : (
                <span
                  onClick={() => {
                    this.goToProject(true, data);
                  }}
                  className="material-icons-round icon text-orange allowed-access allowed-access-warning"
                  aria-hidden="true"
                  data-for={`tooltip-${data.id}-inactive`}
                  data-tip={"Inactive"}
                  data-iscapture="true"
                >
                  circle
                  <CustomReactTooltip
                    id={`tooltip-${data.id}-inactive`}
                    multiline={true}
                  />
                </span>
              ),
            segments: `${
              data.segment && data.segment.id ? data.segment.name : "-"
            }`,
            additional_segments: (
              <span className="datagrid_tooltip">
                {additionalSegments.text || "-"}
                {additionalSegments.ellipsis && (
                  <span
                    className="material-icons-outlined icon"
                    data-for={`tooltip-segments-${data.id}`}
                    data-tip={additionalSegments.fullText.replace(
                      /,/g,
                      ",<br>"
                    )}
                    data-iscapture="true"
                  >
                    info
                    <CustomReactTooltip
                      id={`tooltip-segments-${data.id}`}
                      multiline={true}
                    />
                  </span>
                )}
              </span>
            ),
            client: data.client.name,
            project_manager: `${
              data.project_manager.first_name
                ? data.project_manager.first_name
                : ""
            } ${
              data.project_manager.last_name
                ? data.project_manager.last_name
                : ""
            }`,
            created_by: `${
              data.created_by_user?.first_name
                ? data.created_by_user.first_name
                : ""
            } ${
              data.created_by_user?.last_name
                ? data.created_by_user.last_name
                : ""
            }`,
            created_at: data.created_at
              ? ComapnyDetailsDateFormatter(data.created_at, false)
              : ComapnyDetailsDateFormatter(new Date(), false),
            updated_at: data.updated_at
              ? ComapnyDetailsDateTimeFormatter(data.updated_at, false)
              : "-",
          };
        });
        this.setState({ rowData: clientData, count: count });
      })
      .catch((error) => {
        this.setState({ rowData: [], count: 0 });

        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePageSize = (e) => {
    this.setState({ pageSize: e.target.value, pageNo: 1 }, () => {
      this.getTableData();
    });
  };
  handleInput = (e) => {
    this.setState({ name: e.target.value });
  };
  rowAction = (action, data, e) => {
    e.stopPropagation();
    switch (action) {
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.EDIT_USER:
        this.props.history &&
          this.props.history.push({
            //for unit testing in jest (this.props.history &&) Use
            pathname: formatRoute(
              constants.APPLICATION_ROUTE.CLIENT_USER.EDIT.ROUTE,
              { clientId: data.client.id, id: data.id }
            ),
          });
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.STATUS:
        this.toggleClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST.REMOVE_USER:
        this.removeClientUserConfirmationHandler(data);
        break;
      case constants.APPLICATION_ROUTE.CLIENT_USER.LIST
        .RESEND_EMAIL_VERIFICATION:
        this.resendEmailVerification(data);
        break;
      default:
        break;
    }
  };
  addClient = () => {
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(
          constants.APPLICATION_ROUTE.CLIENT_USER.CREATE.ROUTE,
          { clientId: this.state.clientId }
        ),
      });
  };
  handleClientChange = (e) => {
    let clients = [...this.props.client.clients];
    clients.forEach((c) => {
      c.is_selected = c.id === e.id ? true : false;
    });
    this.props.setClients(clients);
    let clientId = clients.find((c) => c.is_selected === true)
      ? clients.find((c) => c.is_selected === true).id
      : "all";
    this.setState(
      {
        clientId: clientId,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };
  changeRoute = () => {
    let queryParams = "";
    if (this.state.sortOrder) {
      queryParams =
        "&sort_order=" +
        this.state.sortOrder +
        "&sort_column=" +
        this.state.sortColumn;
    }
    if (this.state.name !== "") {
      queryParams += "&search_column=name&search_value=" + this.state.name;
    }
    this.props.history &&
      this.props.history.push({
        //for unit testing in jest (this.props.history &&) Use
        pathname: formatRoute(constants.APPLICATION_ROUTE.PROJECT.LIST.ROUTE, {
          clientId: this.state.clientId,
        }),
        search:
          "?page=" +
          this.state.pageNo +
          "&page_size=" +
          this.state.pageSize +
          queryParams,
      });
  };

  render() {
    let th = tableHeader;
    if (
      this.props.home &&
      this.props.home.loginUserSuccess &&
      this.props.home.loginUserSuccess.id &&
      this.props.home.loginUserSuccess.is_admin === 1
    ) {
      th = tableHeaderWithClient;
    }
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height">
          <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 pt-3">
            <h1 className="text-ligntblue2 font-weight-bold ">Projects</h1>
            <div className="ml-auto">
              <HelpModal category="Project List" />
            </div>
          </div>

          <div className="row mb-18px">
            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
              <div className="w-100-search border-search search-box-shadow-2">
                <Search
                  handleSearch={this.handleSearch}
                  handleInput={this.handleInput}
                  data-testid="list-project-search"
                  value={this.state.name}
                  placeHolder={"Filter Name"}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center flex-wrap ml-auto col">
              <div className="table-list-legends d-flex flex-wrap pb-0">
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Active</h3>
                </span>
                <span className="d-inline-flex align-items-center mr-2">
                  <span
                    className="material-icons-round icon inactive-allowed-access-icon icon-fs-14"
                    aria-hidden="true"
                  >
                    circle
                  </span>
                  <h3 className="mb-0">Inactive</h3>
                </span>
              </div>
            </div>
          </div>
          <div className="w-100 custom-table-block custom-table-block-2 projects-list-table-block sort-table-block th-nowrap center-th-align">
            <DynamicTableComponent
              columnData={th}
              rowData={this.state.rowData}
              pagePrevious={this.pagePrevious}
              pageNext={this.pageNext}
              pageSize={this.state.pageSize}
              pageNo={this.state.pageNo}
              handlePage={this.handlePage}
              handleSort={this.handleSort}
              sortColumn={this.state.sortColumn}
              sortOrder={this.state.sortOrder}
              count={this.state.count}
              rowAction={this.rowAction}
              colSpan={tableHeader.length}
              showActions={showActions}
              table={{ className: "client_user" }}
              showPagination={true}
              handlePageSize={this.handlePageSize}
              paginationClass={"center-pagination custom-pagination-new"}
            />
          </div>
          <Dailog
            isOpen={this.state.dailogModal}
            accept={this.dailogModalAccept}
            decline={this.dailogModalDecline}
            header={this.state.dailogModalHeader}
            content={this.state.dailogModalContent}
            config={this.state.dailogModalConfig}
          />
        </div>
      </aside>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListProjects);
