import $ from 'jquery'
import _ from "lodash"
import queryString from 'query-string'
import React, { Component } from 'react'
import { formatRoute } from 'react-router-named-routes'
import AclAction from '../Components/ACL/AclAction'
import CustomBreadcrumb from '../Components/CustomBreadcrumb'
import CustomReactTooltip from '../Components/CustomReactTooltip'

import DailogNew from '../Components/DailogNew'
import DynamicTableComponent from '../Components/DynamicTableComponent'
import CustomDropdownList from '../Components/FiltersDropdown'
import Search from '../Components/searchComponent'
import { CompanyServices } from '../Services/Company'
import { QuantdataServices } from '../Services/QuantData'
import constants from '../Utils/constants'
import CustomCloneDeep from '../Utils/CustomCloneDeep'
import Slugify from '../Utils/Slugify'
import Toast from '../Utils/Toast'
import HelpModal from './HelpModal'
import gaEvent from '../Utils/GAEvents'
import { downloadExcelFile } from '../Helpers/Helper'

const fixedTableHeader = [
    { id: 'lob_name', title: 'Line of Business', sortable: false, headerStyle: { color: '#000000', textTransform: 'capitalize' }, rowStyle: { color: '#000000', textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'lob_subcategory1_name', title: 'LOB Segment Group', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'lob_subcategory2_name', title: 'LOB Segment', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'lob_subcategory3_name', title: 'LOB Subsegment', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'lob_subcategory4_name', title: 'LOB Subsegment4', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'lob_subcategory5_name', title: 'LOB Subsegment5', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'geo_name', title: 'Geography', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'geosubcategory_name', title: 'Geo Category', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'country_name', title: 'Geo Sub-category', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'vertical_name', title: 'Vertical', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'verticalcategory_name', title: 'Vertical Category', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'verticalsubcategory_name', title: 'Vertical Sub-category', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'metrictype_name', title: 'Metric Type', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize', whiteSpace: "nowrap" }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    { id: 'metric_name', title: 'Metric', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize', whiteSpace: "nowrap" }, headerClass: 'w80', rowClass: 'w80', visibility: true },
    // { id: 'metric_value', title: 'Value', sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },
]

const showActions = true

class QuantData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            params: [],
            companyDetails: [],
            lob: [],
            geography: [],
            vertical: [],
            metric_type: [],
            metric_name: [],
            metrics: [],
            year: [],
            quater: [],
            segment: '',
            selectedYearsList: [],
            selectedQuaterList: [],
            tableHeader: [],
            rowData: [],
            entireData: [],
            quantData: [],
            filteredThresholdData: [],
            filtersEnabled: false,
            sortColumn: null,
            sortOrder: null,
            pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
            pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
            count: 0,
            name: '',
            dailogModal: false,
            dailogModalContent: '',
            dailogModalHeader: '',
            dailogModalConfig: {},
            dailogModalStyleType: '',
            isOpen: false,
            segmentList: [],
            documentModal: false,
            threshold: 10,
            templateRecordId: null,
            totalData: []

        }
    }
    componentDidMount() {
        let params = queryString.parse(this.props.location.search)
        CompanyServices.getCompanyDetails(params.companyId)
            .then((res) => {
                let response = res && res.data
                this.setState({ params: params, companyDetails: response }, () => {
                    QuantdataServices.getQuantdata(params.companyId, params.segmentId).then((res) => {
                        if (res.data && res.data.data) {
                            let tempData = res.data.data.filter((item, index) => index < this.state.pageSize)
                            this.setState({ entireData: res.data.data, quantData: tempData, count: res.data.meta.total_count }, () => {
                                this.getMasters()
                            })
                        }
                    }).catch((error) => {
                        if (error.status === 403) {
                            this.setState({ dailogModal: true })
                        }
                        else {
                            
                            Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error');
                        }
                        this.setState({ quantData: [], rowData: [], count: 0 })
                        this.getTableData()
                    })
                })
            })
            .catch((error) => {
                
                Toast(error && error.data && error.data.error ? error.data.error : constants.ERROR.SOMETHING_WENT_WRONG, 'error')
            })

        let dailogModalHeader =
            <>
                -                <h3 className="text-darkblue2 font-weight-bold">{constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}</h3>
               
            </>
        this.setState({
           
            dailogModalHeader: dailogModalHeader,
            dailogModalContent: constants.WARNING.CONTENT_ACCESS_DENIED_TEXT,
            dailogModalConfig: { type: false, id: null },
            pageNo: params.page ? parseInt(params.page) : constants.PAGINATION.DEFAULT_PAGE_NO,
            pageSize: params.page_size ? parseInt(params.page_size) : constants.PAGINATION.DEFAULT_PAGE_SIZE,
            sortColumn: params.sort_column ? params.sort_column : null,
            sortOrder: params.sort_order ? params.sort_order : null,
            name: params.search_value ? params.search_value : '',
        }, () => { this.getTableData() })
    }
    componentDidUpdate(prevProps, prevState) {
        
        let lobIdArray = this.state.lob.filter(val => val.is_selected === true)[0] !== undefined ? (this.state.lob.filter(val => val.is_selected === true).map(data => data.id)) : []
        let lobFilteredData = this.state.entireData.filter(val => val.lob && val.lob.id && lobIdArray?.includes(val.lob.id))
        let geographyIdArray = this.state.geography.filter(val => val.is_selected === true)[0] !== undefined ? (this.state.geography.filter(val => val.is_selected === true).map(data => data.geographyid)) : []
        let geographyFilteredData = this.state.entireData.filter(val => val.geography && val.geography.geographyid && geographyIdArray?.includes(val.geography.geographyid))
        let verticalIdArray = this.state.vertical.filter(val => val.is_selected === true)[0] !== undefined ? (this.state.vertical.filter(val => val.is_selected === true).map(data => data.verticalid)) : []
        let verticalFilteredData = this.state.entireData.filter(val => val.vertical && val.vertical.verticalid && verticalIdArray?.includes(val.vertical.verticalid))
        let metrictypeIdArray = this.state.metric_type.filter(val => val.is_selected === true)[0] !== undefined ? (this.state.metric_type.filter(val => val.is_selected === true).map(data => data.metrictypeid)) : []
        let metrictypeFilteredData = this.state.entireData.filter(val => val.metrictype && val.metrictype.metrictypeid && metrictypeIdArray?.includes(val.metrictype.metrictypeid))
        let metricIdArray = this.state.metrics.filter(val => val.is_selected === true)[0] !== undefined ? (this.state.metrics.filter(val => val.is_selected === true).map(data => data.metricid)) : []
        let metricFilteredData = this.state.entireData.filter(val => val.metric && val.metric.metricid && metricIdArray?.includes(val.metric.metricid))
        let yearIdArray = this.state.year.length > 0 ? (this.state.year.filter(datum => datum.is_selected === true).map(data => data.yearid)) : []
        let quaterIdArray = this.state.quater.length > 0 ? (this.state.quater.filter(datum => datum.is_selected === true).map(data => data.quaterid)) : []

        if (prevState.lob !== this.state.lob || prevState.geography !== this.state.geography
            || prevState.vertical !== this.state.vertical || prevState.metric_type !== this.state.metric_type
            || prevState.metrics !== this.state.metrics || prevState.year !== this.state.year || prevState.quater !== this.state.quater) {
            
            let result = [...this.state.entireData]
            let filtersFlag;
            if (lobIdArray.length > 0) {
                result = _.intersection(lobFilteredData, result)
                filtersFlag = true;
            }
            if (geographyIdArray.length > 0) {
                result = _.intersection(geographyFilteredData, result)
                filtersFlag = true;
            }
            if (verticalIdArray.length > 0) {
                result = _.intersection(verticalFilteredData, result)
                filtersFlag = true;
            }
            if (metrictypeIdArray.length > 0) {
                result = _.intersection(metrictypeFilteredData, result)
                filtersFlag = true;
            }
            if (metricIdArray.length > 0) {
                result = _.intersection(metricFilteredData, result)
                filtersFlag = true;
            }
                     
            this.setState({ selectedQuaterList: quaterIdArray, selectedYearsList: yearIdArray, count: result.length, quantData: result, filteredThresholdData: result, filtersEnabled: filtersFlag, pageNo: 1 }, () => this.getTableData())
            
        }
    }
    verifyMasterData = (data, id) => {
        let array = data.filter(item => item !== null)
        return _.uniqBy(array, id);
    }
    getMasters = () => {
        if (this.state.companyDetails.company.segments !== null && this.state.companyDetails.company.segments !== undefined && this.state.companyDetails.company.segments.length > 0) {
            var selectedsegments = this.state.companyDetails.company.segments.map(c => {
                if (parseInt(this.state.params.segmentId) === c.id) {
                    c.is_selected = true;
                    this.setState({ segment: c.name })
                } else { c.is_selected = false }
                return c
            }
            )
            this.setState({ lob: selectedsegments })
        }
        let year = []
        let yearList = []
        let quarters=[]
        this.state.entireData.length > 0 &&
            this.state.entireData.forEach((row) => {
                row.quantmetrics.forEach((item, key) => {
                    if (!year.includes(item.year)) {
                        year.push(item.year)
                        yearList.push({ yearid: item.year, name: "20" + item.year,is_selected: false })
                    }
                })

            })

        yearList = yearList.map((year, key) => {
            if (key >= (yearList.length - 5) && key < (yearList.length)) {
                year.is_selected = true
            }
            return year
        })

        let quaterDropdown = [
            { quaterid: '1', name: 'Q1', is_selected: true },
            { quaterid: '2', name: 'Q2', is_selected: true },
            { quaterid: '3', name: 'Q3', is_selected: true },
            { quaterid: '4', name: 'Q4', is_selected: true }
        ]
        let geography = this.state.entireData.map((item, key) => item.geography !== null ? ({ key: key, geographyid: item.geography.geographyid, name: item.geography.name, is_selected: false }) : null)
        let vertical = this.state.entireData.map((item, key) => item.vertical !== null ? ({ key: key, verticalid: item.vertical.verticalid, name: item.vertical.name, is_selected: false }) : null)
        let metrictype = this.state.entireData.map((item, key) => item.metrictype !== null ? ({ key: key, metrictypeid: item.metrictype.metrictypeid, name: item.metrictype.name, is_selected: false }) : null)
        let metric = this.state.entireData.map((item, key) => item.metric !== null ? ({ key: key, metricid: item.metric.metricid, name: item.metric.name, is_selected: false }) : null)

        this.setState({
            lob: CustomCloneDeep(selectedsegments ? this.sortOptions(selectedsegments) : []),
            geography: CustomCloneDeep(this.verifyMasterData(geography, 'geographyid').length > 0 ? this.sortOptions(this.verifyMasterData(geography, 'geographyid')) : []),
            vertical: CustomCloneDeep(this.verifyMasterData(vertical, 'verticalid').length > 0 ? this.sortOptions(this.verifyMasterData(vertical, 'verticalid')) : []),
            metric_type: CustomCloneDeep(this.verifyMasterData(metrictype, 'metrictypeid').length > 0 ? this.sortOptions(this.verifyMasterData(metrictype, 'metrictypeid')) : []),
            metrics: CustomCloneDeep(this.verifyMasterData(metric, 'metricid').length > 0 ? this.sortOptions(this.verifyMasterData(metric, 'metricid')) : []),
            year: yearList,
            quater: quaterDropdown
        })
    }
    sortOptions = (rows) => {
        return rows.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    }
    dailogModalAccept = (config) => {
        this.setState({
            dailogModal: false,
            dailogModalHeader: '',
            dailogModalContent: '',
            dailogModalConfig: {},
        })
    }
    dailogModalDecline = () => {
        this.setState({
            dailogModal: false,
            dailogModalHeader: '',
            dailogModalContent: '',
            dailogModalConfig: {}
        })
    }
    breadcrumbs = () => {
        let breadcrumbs = [
            {
                title: constants.APPLICATION_ROUTE.COMPANY.LIST.NAME,
                link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.LIST.ROUTE, {}),
                is_active: false
            },
            {
                title: this.state.companyDetails.company?.name,
                link: formatRoute(constants.APPLICATION_ROUTE.COMPANY.VIEW.ROUTE, { id:  this.state.companyDetails.company?.id }),
                is_active: false
            },
            {
                title: constants.APPLICATION_ROUTE.COMPANY.QUANTDATA.NAME,
                link: null,
                is_active: true
            }
        ]
        return breadcrumbs
    }
    getData = () => {
        if (this.state.filtersEnabled) {
            return this.state.filteredThresholdData
        } else {
            return this.state.entireData
        }
    }

    pagePrevious = () => {
        let data = this.getData();
        let tempData = data.filter((item, index) => (index >= (this.state.pageSize * (this.state.pageNo - 2)) && index < (this.state.pageSize * (this.state.pageNo - 1))))
        this.setState((prevState) => ({
            pageNo: prevState.pageNo - 1,
            quantData: tempData
        }), () => this.getTableData())
    }
    pageNext = () => {
        let data = this.getData();
        let tempData = data.filter((item, index) => (index >= (this.state.pageSize * (this.state.pageNo)) && index < (this.state.pageSize * (this.state.pageNo + 1))))
        this.setState((prevState) => ({
            pageNo: prevState.pageNo + 1,
            quantData: tempData
        }), () => this.getTableData())
    }
    handlePage = (input) => {
        let data = this.getData();
        this.setState({ quantData: data, pageNo: input }, () => this.getTableData())
    }
    handlePageSize = (e) => {
        let data = this.getData();
        this.setState({ quantData: data, pageSize: e.target.value, pageNo: 1 }, () => {
            this.getTableData()
        })
    }
    handleInput = (e) => {
        this.setState({ name: e.target.value })
    }
    handleSort = (clickedColumn, key) => {
        let column
        if (clickedColumn === 'lob_name') { column = 'lob.name' }
        else if (clickedColumn === 'lob_subcategory1_name') { column = 'lob_subcategory1.name' }
        else if (clickedColumn === 'lob_subcategory2_name') { column = 'lob_subcategory2.name' }
        else if (clickedColumn === 'lob_subcategory3_name') { column = 'lob_subcategory3.name' }
        else if (clickedColumn === 'lob_subcategory4_name') { column = 'lob_subcategory4.name' }
        else if (clickedColumn === 'lob_subcategory5_name') { column = 'lob_subcategory5.name' }
        else if (clickedColumn === 'geo_name') { column = 'geography.name' }
        else if (clickedColumn === 'geosubcategory_name') { column = 'geosubcategory.name' }
        else if (clickedColumn === 'country_name') { column = 'country.name' }
        else if (clickedColumn === 'vertical_name') { column = 'vertical.name' }
        else if (clickedColumn === 'verticalcategory_name') { column = 'verticalcategory.name' }
        else if (clickedColumn === 'verticalsubcategory_name') { column = 'verticalsubcategory.name' }
        else if (clickedColumn === 'metrictype_name') { column = 'metrictype.name' }
        else if (clickedColumn === 'metric_name') { column = 'metric.name' }
        else if (clickedColumn === 'threshold') { column = 'threshold_value' }
        else {
            column = clickedColumn
        }
        let data = this.getData();
        let tempSortedData
        if (this.state.sortOrder === 'asc') {
            tempSortedData = _.sortBy(data, column);
        } else if (this.state.sortOrder === 'desc') {
            tempSortedData = _.sortBy(data, column).reverse();
        }
        if (this.state.filtersEnabled) {
            this.setState({ filteredThresholdData: tempSortedData })
        } else {
            this.setState({ entireData: tempSortedData })
        }
        let tempData = data.filter((item, index) => index < this.state.pageSize)
        this.setState({ pageNo: 1, quantData: tempData, sortColumn: clickedColumn, segmentSort: false, sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc', }, () =>
            this.getTableData())
    }
    handleSearch = () => {
        this.setState({ pageNo: 1 }, () => {
            this.getTableData()
        })
    }
    handleMasterChange = (e, props) => {
        let selected = e.map(a => a[props.valueField])
        let selectors = [...this.state[props.selector]]
        selectors.forEach((c) => {
            c.is_selected = selected.includes(c[props.valueField])
        })
        this.setState({
            ...this.state,
            [props.selector]: selectors
        })
    }

    getTableData = () => {
        
        var templatesData;
        let data;
        let rowsCount = 0;
        if (this.state.name === '') {
            data = this.state.quantData
            rowsCount = this.state.quantData.length
            
        }
        else {
            let dataToBeSearched = this.getData();
            
            let filteredData = dataToBeSearched.filter((val) =>
            (
                
                (val.metric && val.metric.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.metrictype && val.metrictype.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob && val.lob.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob_subcategory1 && val.lob_subcategory1.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob_subcategory2 && val.lob_subcategory2.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob_subcategory3 && val.lob_subcategory3.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob_subcategory4 && val.lob_subcategory4.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.lob_subcategory5 && val.lob_subcategory5.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.vertical && val.vertical.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.verticalcategory && val.verticalcategory.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.verticalsubcategory && val.verticalsubcategory.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.geography && val.geography.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.geosubcategory && val.geosubcategory.name.toLowerCase().includes(this.state.name.toLowerCase()))
                ||
                (val.country && val.country.name.toLowerCase().includes(this.state.name.toLowerCase()))
            ))
            data = filteredData;
        }
        let tempTableHeader = []
       
        
        if (data.length > 0) {
                if (rowsCount == 0)
                    rowsCount = data.length;
                data.forEach((row) => {
                    if(row && row.quantmetrics && row.quantmetrics.length > 0){
                    row.quantmetrics.forEach((q, key) => {
                        if (this.state.selectedYearsList.includes(q.year) && this.state.selectedQuaterList.includes(q.quarter)) {
                            var index = tempTableHeader.findIndex((element) => element.id == q.quarteryear)
                            index < 0 && tempTableHeader.push({ id: q.quarteryear, title: q.quarteryear, sortable: false, headerStyle: { textTransform: 'capitalize' }, rowStyle: { textTransform: 'capitalize' }, headerClass: 'w80', rowClass: 'w80', visibility: true },)
                        }
                    })
                }
                })

                this.setState({ tableHeader: fixedTableHeader.concat(tempTableHeader) })
            }

            templatesData = data?.map((data) => {

            let metricsQ = []
            data.quantmetrics.forEach((q) => {
                // metricsQ[q.quarteryear] = Math.round(q.amount)
                metricsQ[q.quarteryear] = q.amount
            })
            return {
                id: data.id,
                company: data.company ? data.company.name : 'N/A',
                lob_name: data.lob ? data.lob.name : 'N/A',
                lob_subcategory1_name: data.lob_subcategory1 ? data.lob_subcategory1.name : 'N/A',
                lob_subcategory2_name: data.lob_subcategory2 ? data.lob_subcategory2.name : 'N/A',
                lob_subcategory3_name: data.lob_subcategory3 ? data.lob_subcategory3.name : 'N/A',
                lob_subcategory4_name: data.lob_subcategory4 ? data.lob_subcategory4.name : 'N/A',
                lob_subcategory5_name: data.lob_subcategory5 ? data.lob_subcategory5.name : 'N/A',
                geo_name: data.geography ? data.geography.name : 'N/A',
                geosubcategory_name: data.geosubcategory ? data.geosubcategory.name : 'N/A',
                country_name: data.country ? data.country.name : 'N/A',
                vertical_name: data.vertical ? data.vertical.name : 'N/A',
                verticalcategory_name: data.verticalcategory ? data.verticalcategory.name : 'N/A',
                verticalsubcategory_name: data.verticalsubcategory ? data.verticalsubcategory.name : 'N/A',
                metrictype_name: data.metrictype ? data.metrictype.name : 'N/A',
                metric_name: data.metric ? data.metric.name : 'N/A',
                metric_value: data.metric_value ? data.metric_value : 'N/A',
                ...metricsQ
            }
        })
        let totalData = templatesData
        let input = this.state.pageNo
        templatesData = templatesData.filter((item, index) => (index >= (this.state.pageSize * (input - 1)) && index < this.state.pageSize * input))
        this.setState({ rowData: templatesData, count: rowsCount, totalData: totalData }, () => {
            $(".comapany_table").css('min-width', $(".comapany_table").find("thead").innerWidth())
        })

    }

    exportList = () => {
        let fileName=`${this.state.companyDetails.company.name}${this.state.segment}-QuantData`
        gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW,{
            fileName:fileName,
            fileFormat:`xlsx`,
            moduleName : constants.APPLICATION_ROUTE.COMPANY.QUANTDATA.NAME,
            pageUrl: this.props.history.location.pathname + this.props.history.location.search
          })
        CompanyServices.postDownloadDataReport({ title: `${this.state.companyDetails.company.name}${this.state.segment}-QuantData`, service: "QuantData", doc_type: "XLSX", source_type: "Website" }).then((res) => {
 
        })

        downloadExcelFile('Quant Data',"QuantData",this.state.totalData,this.state.tableHeader)
    }
    render() {
        return (
            <aside className="main_content">
                <div className='m-3 mb-0 bg-white px-3 pb-3 br_8 main_content_height'>
                <div className="d-flex align-items-center flex-wrap pt-3 quant-data-page-title-sec title-section breadcrumb-mb-0">
                    <CustomBreadcrumb breadcrumbs={this.breadcrumbs()} />
                    <div className="ml-auto">
                        <Search
                            handleSearch={this.handleSearch}
                            handleInput={this.handleInput}
                            value={this.state.name}
                            placeHolder={'Search'}
                        />
                    </div>
                    <AclAction type={constants.ACL.PERMISSION_KEY} entity={constants.ACL.QUANTDATA.TITLE} action={constants.ACL.QUANTDATA.ACTIONS.EXPORT}>
                        <div className="ml-3" tbrentitytype={constants.ACL.ENTITY_TYPE.DIV}>
                            <button className="icon-btn-primary icon-btn d-flex align-items-center justify-content-center"
                                onClick={this.exportList}
                                data-for={`tooltip-${Slugify(constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP)}`}
                                data-tip={constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP}
                                data-iscapture="true">
                                <span className="material-icons-round icon">
                                    download
                                </span>
                                <CustomReactTooltip
                                    id={`tooltip-${Slugify(constants.COMPANY.VIEW.DOWNLOAD_TOOLTIP)}`}
                                    multiline={true}
                                />
                            </button>
                        </div>
                    </AclAction>
                    <div className="ml-3">
                        <HelpModal category="Company Quant Data" />
                    </div>
                </div>
                <div className="d-flex align-items-center flex-wrap">
                    <h3 className="mb-0 text-black2 font-weight-bold mb-3">{this.state.companyDetails.company?.name} - {this.state.segment}</h3>
                    <label className="mb-0 ml-auto mb-3">* All Values in Thousands($)</label>
                </div>

                <div className="row justify-content-between quantData-filters">
                   
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list custom-dropdown-list">
                            <CustomDropdownList
                                data={this.state.geography}
                                handleChange={this.handleMasterChange}
                                placeholder={'Geography'}
                                valueField={'geographyid'}
                                textField={'name'}
                                selector="geography"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list custom-dropdown-list">
                            <CustomDropdownList
                                data={this.state.vertical}
                                handleChange={this.handleMasterChange}
                                placeholder={'Vertical'}
                                valueField={'verticalid'}
                                textField={'name'}
                                selector="vertical"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list custom-dropdown-list">
                            <CustomDropdownList
                                data={this.state.metric_type}
                                handleChange={this.handleMasterChange}
                                placeholder={'Metric Type'}
                                valueField={'metrictypeid'}
                                textField={'name'}
                                selector="metric_type"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list custom-dropdown-list">
                            <CustomDropdownList
                                data={this.state.metrics}
                                handleChange={this.handleMasterChange}
                                placeholder={'Metric'}
                                valueField={'metricid'}
                                textField={'name'}
                                selector="metrics"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list year-list">
                            <CustomDropdownList
                                data={this.state.year}
                                handleChange={this.handleMasterChange}
                                placeholder={'Year'}
                                valueField={'yearid'}
                                textField={'name'}
                                selector="year"
                            />
                        </div>
                    </div>
                    <div className="col-lg-2 mb-3 col-sm-6 position-relative">
                        <div className="custom-dropdown-checkbox-list quater-list">
                            <CustomDropdownList
                                data={this.state.quater}
                                handleChange={this.handleMasterChange}
                                placeholder={'Quarter'}
                                valueField={'quaterid'}
                                textField={'name'}
                                selector="quater"
                            />
                        </div>
                    </div>
                </div>

                <div>
                    <div className="w-100 custom-table-block custom-table-block-2 quant-data-table-block th-nowrap center-th-align">
                        <DynamicTableComponent
                            columnData={this.state.tableHeader}
                            rowData={this.state.rowData}
                            pagePrevious={this.pagePrevious}
                            pageNext={this.pageNext}
                            pageSize={this.state.pageSize}
                            pageNo={this.state.pageNo}
                            handlePage={this.handlePage}
                            handleSort={this.handleSort}
                            sortColumn={this.state.sortColumn}
                            sortOrder={this.state.sortOrder}
                            count={this.state.count}
                            rowAction={this.rowAction}
                            colSpan={this.state.tableHeader.length}
                            showActions={showActions}
                            showPagination={true}
                            handlePageSize={this.handlePageSize}
                            paginationClass={"center-pagination custom-pagination-new"}
                        />
                    </div>
                  
                    <DailogNew accept={this.dailogModalAccept} decline={this.dailogModalDecline} isOpen={this.state.dailogModal} header={this.state.dailogModalHeader} content={this.state.dailogModalContent} config={this.state.dailogModalConfig} modalStyleType={this.state.dailogModalStyleType} />
                   
                </div>
                </div>

            </aside>
        )
    }
}


export default (QuantData)