import React, { useState, useEffect, useRef } from "react";
import PreferenceCenterSettings from "../../Containers/V2/PreferenceCenter/PreferenceCenterSettings";
import PreferenceCenterSidebar from "../../Containers/V2/PreferenceCenter/PreferenceCenterSidebar";
import Toast from "../../Utils/Toast";
import constants from "../../Utils/constants";
import { CompanyServices } from "../../Services/Company";
import { PreferenceServices } from "../../Services/PreferenceServices";
const PreferenceCenter = (props) => {
  const divRef = useRef(null);
  const [segmentData, setSegmentData] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState({});
  const [allSegmentsData, setAllSegments] = useState([]);
  const [isSegmentPart, setIsSegmentPart] = useState(true);
  const [platformNotificationData, setPlatformNotificationData] = useState();

  const [othersItems, setOthersItems] = useState([
    {
      name: "Boards",
      is_selected: false,
      value: "boards",
      id: 1,
      is_all_checked: false,
      is_arrow_selected: false,
      data: [
        { name: "Comments", label: "is_comment" },
        { name: "Content Update", label: "is_content_updated" },
        { name: "Content Pin", label: "is_content_pinned" },
        { name: "Publish", label: "is_published" },
      ],
    },
    {
      name: "Visualization",
      is_selected: false,
      id: 2,
      data: [],
      is_all_checked: true,
      is_arrow_selected: false,
    },
  ]);
  const platFromNotificationJson = {
    is_content_pinned: "Content Pin",
    is_comment: "Comments",
    is_content_updated: "Content Update",
    is_published: "Publish",
  };
  const modifiedAPIDataToSegmentData = (data = []) => {
    let temp = [];
    data.forEach((item, index) => {
      if (!item.name.includes("Earnings Response")) {
        temp.push({
          id: item.id,
          name: item.name,
          is_arrow_selected: index === 0,
        });
      }
    });
    return temp;
  };

  const goToScroll = (id) => {
    if (divRef.current) {
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleOthersCollaps = (item) => {
    setSelectedSegment(item);
    setIsSegmentPart(false);
  };

  const fetchAllReportSegmentData = async () => {
    try {
      const companiesSegment = await CompanyServices.getTotalSegments();
      const modifiedCmpSegment = modifiedAPIDataToSegmentData(
        companiesSegment?.data?.data
      );
      setSelectedSegment(modifiedCmpSegment[0]);
      setSegmentData(modifiedCmpSegment);
      setAllSegments(companiesSegment.data.data);
    } catch (error) {
      Toast(
        error && error.data && error.data.error
          ? error.data.error
          : constants.ERROR.SOMETHING_WENT_WRONG,
        "error"
      );
    }
  };
  const handleCollaps = (key) => {
    setIsSegmentPart(true);
    let temp = [...segmentData];
    temp.map((item, index) => {
      if (index === key) {
        item.is_arrow_selected = !item.is_arrow_selected;
      } else {
        item.is_arrow_selected = false;
      }
      return item;
    });
    setSegmentData(temp);
    setSelectedSegment(temp[key]);
  };

  const checkNotificationData = (data) => {};
  const getClientPlatformNotifications = async () => {
    try {
      let clientPlatformNotificationRes =
        await PreferenceServices.getClientPlatformNotifications();
      let data = clientPlatformNotificationRes.data.data;
      let parseJsonData = data.preferences.map((item) => ({
        ...item,
        entities_json: JSON.parse(item.entities_json),
      }));

      setPlatformNotificationData({
        all_notification: data.preferences?.[0]?.published,
        ...data,
        preferences: parseJsonData,
        // is_all_checked: true,
      });
    } catch (e) {
      console.log("Error =>", e);
    }
  };
  useEffect(() => {
    fetchAllReportSegmentData();
    getClientPlatformNotifications();
  }, []);

  return (
    <div className="preference-container">
      <PreferenceCenterSidebar
        segmentData={segmentData}
        setSegmentData={setSegmentData}
        handleCollaps={handleCollaps}
        selectedSegment={selectedSegment}
        goToScroll={goToScroll}
        othersItems={othersItems}
        setOthersItems={setOthersItems}
        handleOthersCollaps={handleOthersCollaps}
        isSegmentPart={isSegmentPart}
        platformNotificationData={platformNotificationData}
      />
      <PreferenceCenterSettings
        mainRef={divRef}
        selectedSegment={selectedSegment}
        segmentData={segmentData}
        allSegmentsData={allSegmentsData}
        handleCollaps={handleCollaps}
        othersItems={othersItems}
        setOthersItems={setOthersItems}
        isSegmentPart={isSegmentPart}
        handleOthersCollaps={handleOthersCollaps}
        platformNotificationData={platformNotificationData}
        platFromNotificationJson={platFromNotificationJson}
        setPlatformNotificationData={setPlatformNotificationData}
      />
    </div>
  );
};
export default PreferenceCenter;
