import { project_details as DETAILS } from '../actionConstants'

const projectCategories = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.PROJECT_CATEGORIES:
      return action.payload
    default:
      return state
  }
}


const projectDetails = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.PROJECT_DETAILS:
      return action.payload
    default:
      return state
  }
}

const categories = (state = [], action) => {
  switch (action.type) {
    case DETAILS.CATEGORIES:
      return action.payload
    default:
      return state
  }
}

const projectContent = (state = {}, action) => {
  switch (action.type) {
    case DETAILS.PROJECT_CONTENT:
      return action.payload
    default:
      return state
  }
}


export {
  projectCategories,
  projectDetails,
  categories,
  projectContent,

}
