import React, { useEffect, useState } from "react";
import { parseCssToObj } from "../Helpers/Helper";

const CustomTitle = (props) => {
  const [customStyle, setCustomStyle] = useState({ textAlign: "left" });
  useEffect(() => {
    const getCustomStyle = async (htmlStyle) => {
      if (htmlStyle) {
        const htmlStyleObj = parseCssToObj(htmlStyle);
        if (htmlStyleObj) {
          setCustomStyle({
            // fontSize: 18,
            // fontFamily: htmlStyleObj["font-family"],
            textAlign: "left",
          });
        } else {
          setCustomStyle({ textAlign: "left" });
        }
      }
    };

    // Call getCustomStyle with the necessary arguments
    if (props.title_html) {
      getCustomStyle(props.title_html);
    }
  }, [props]);

  return (
    <props.tag className={props.className} style={customStyle}>
      {props.title}
    </props.tag>
  );
};

export default CustomTitle;
