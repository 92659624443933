import { Axios } from "../lib/axios";
export const ClientServices = {
  getClientUsers,
  getClientUserDetails,
  addClientUser,
  editClientUser,
  toggleClientUser,
  removeClientUser,
  getClientUserRoles,
  getClientSpecificUsers,
  resendEmailVerification,
  getTotalClientUsers,
  getClientDetails,
};
function getClientUserRoles() {
  return Axios.get(`/roles?type=tbr`);
}
function getClientUsers(url) {
  return Axios.get(url);
}
function getClientSpecificUsers(url) {
  return Axios.get(url);
}
function getClientUserDetails(userId) {
  return Axios.get(`clientuser/${userId}`);
}
function addClientUser(body) {
  return Axios.post(`clientuser`, body);
}
function editClientUser(clientUserId, body) {
  return Axios.patch(`clientuser/${clientUserId}`, body);
}
function toggleClientUser(clientUserId, status) {
  return Axios.patch(`clientuser/${clientUserId}/status/${status}`);
}
function removeClientUser(body) {
  return Axios.delete(`clientuser`, { data: body });
}
function resendEmailVerification(body) {
  return Axios.post(`resendverificationmail`, body);
}

function getTotalClientUsers() {
  return Axios.get("clientusers");
}

function getClientDetails(clientId) {
  return Axios.get(`client/${clientId}`);
}
