import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PreferenceCenterSidebar from "../Containers/PreferenceCenterSidebarOld";
import PreferenceCenterSettings from "../Containers/PreferenceCenterSettingsOld";
import { openSearchBar } from "../Store/Actions/User";
import { useHistory } from "react-router-dom";

const defaultReportTabSections = [
  {
    id: 1,
    name: "Companies",
    slug: "company",
    icon: "business",
    is_selected: true,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 2,
    name: "Benchmarks",
    slug: "benchmark",
    icon: "speed",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 3,
    name: "Market Forecasts",
    slug: "market_forecast",
    icon: "trending_up",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 4,
    name: "Market Landscapes",
    slug: "market_landscape",
    icon: "analytics",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },

  {
    id: 5,
    name: "Customer Studies",
    slug: "customer_study",
    icon: "portrait",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 6,
    name: "Ecosystems",
    slug: "ecosystem",
    icon: "public",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 7,
    name: "Special Reports",
    slug: "special_report",
    icon: "summarize",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: true,
    show_advanced_setting: true,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },

  {
    id: 8,
    name: "My Boards",
    slug: "boards",
    icon: "leaderboard",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: false,
    show_advanced_setting: false,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 9,
    name: "My Projects",
    slug: "project",
    icon: "source",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: false,
    show_advanced_setting: false,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
  {
    id: 10,
    name: "My Visualisation",
    slug: "visualization",
    icon: "area_chart",
    is_selected: false,
    segmentData: [],
    show_advanced_preference: false,
    show_advanced_setting: false,
    preference_actions: [
      {
        id: 1,
        slug: "published",
        is_selected: false,
      },
    ],
  },
];

const PreferenceCenterOld = (props) => {
  const history = useHistory();
  const searchParams = new URLSearchParams(document.location.search);
  const [reportTabSections, setReportTabSections] = useState(
    defaultReportTabSections
  );
  const [preventUnsavedChangesModal, setPreventUnsavedChangesModal] =
    useState(false);
  const [selectedReportTabId, setSelectedReportTabId] = useState("");
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [multiSelectReportOptions, setMultiSelectReportOptions] = useState([]);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [changesIsHappened, setChangesIsHappened] = useState(false);

  useEffect(() => {
    // Will be utilized when a users wants to unsubscribe to the Preference Center using unsubscribe as a query param of the url
    if (searchParams.get("unsubscribe") === "true") {
      setShowUnsubscribeModal(true);
    }
    // Setting notifications for tabs
    const updateReportTab = reportTabSections.map((item) => {
      if (item.slug === "company") item.is_selected = true;
      else item.is_selected = false;
      return item;
    });
    setReportTabSections(updateReportTab);
  }, []);

  const cancelDialog = () => {
    setPreventUnsavedChangesModal(false);
  };

  const moveToNextPage = (type, id) => {
    const reportId = type === "direct" ? id : selectedReportTabId;
    const updatedReportTab = reportTabSections.map((reportTab) => {
      if (reportTab.id === reportId) {
        reportTab.is_selected = true;
      } else {
        reportTab.is_selected = false;
      }
      return reportTab;
    });
    setReportTabSections(updatedReportTab);
    setPreventUnsavedChangesModal(false);
    setEnableSaveButton(false);
    setMultiSelectReportOptions([]);
    setChangesIsHappened(false);
  };

  // select the sidebar item makes blue
  const setReportsTabSelected = (slug, id, name) => {
    if (changesIsHappened) {
      setPreventUnsavedChangesModal(true);
      setSelectedReportTabId(id);
    } else {
      moveToNextPage("direct", id);
    }
  };

  const hideUnsubscribeModal = () => {
    setShowUnsubscribeModal(false);
    history.push(window.location.pathname);
  };

  return (
    <div className='preference-container'>
      <PreferenceCenterSidebar
        reportTabSections={reportTabSections}
        setReportsTabSelected={setReportsTabSelected}
      />
      <PreferenceCenterSettings
        reportTabSections={reportTabSections}
        setReportTabSections={setReportTabSections}
        multiSelectReportOptions={multiSelectReportOptions}
        setMultiSelectReportOptions={setMultiSelectReportOptions}
        preventUnsavedChangesModal={preventUnsavedChangesModal}
        moveToNextPage={moveToNextPage}
        cancelDialog={cancelDialog}
        enableSaveButton={enableSaveButton}
        setEnableSaveButton={setEnableSaveButton}
        showUnsubscribeModal={showUnsubscribeModal}
        hideUnsubscribeModal={hideUnsubscribeModal}
        setChangesIsHappened={setChangesIsHappened}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreferenceCenterOld);
