import React, { useState, useEffect } from "react";
import CustomReactTooltip from "../../../Components/CustomReactTooltip";
import constants from "../../../Utils/constants";
import GreenCheckbox from "./SelectSection/GreenCheckbox.svg";
import { InfoIcon } from "../../../Components/SVGComponents/SVGComponent";
function CustomCheckBox(props) {
  const [activeNode, setActiveNode] = useState();

  useEffect(() => {
    if (Object.keys(props).indexOf("expandAll") !== -1) {
      let expandedArray = [];
      props.expandAll && getExpandedArray(props.nodes, expandedArray);
      props.onExpand(expandedArray);
    }
  }, [props.expandAll, props.nodes, props.disable]);

  useEffect(() => {
    setActiveNode(props.defaultSelection);
  }, [props.defaultSelection]);

  const handleChevron = (value, isOpen) => {
    let expanded = [...props.expanded];
    if (isOpen) {
      expanded.push(value);
    } else {
      let node_values_to_be_removed = getAllChildrenValues(value);
      expanded = expanded.filter(
        (value) => !node_values_to_be_removed.includes(value)
      );
    }
    props.onExpand(expanded);
  };

  /**
   * @param {Integer} value Representing the value of node
   * @returns An Array of all node values to be removed from expanded array
   */
  const getAllChildrenValues = (value) => {
    let node = dfs_algo_on_nodes(props.nodes, value);
    let valuesArray = [];
    populateValuesArrayRecursively(node, valuesArray);
    return valuesArray;
  };

  /**
   * This function recursively updates the valuesArray with all the node values which can be used to remove them from expanded array
   * @param {Object} node An Object representing indiviudal node
   * @param {Array} valuesArray A list of all child nodes values which has children
   */
  const populateValuesArrayRecursively = (node = {}, valuesArray = []) => {
    if (node.children && node.children.length) {
      valuesArray.push(node.value);
      node.children.forEach((item) => {
        populateValuesArrayRecursively(item, valuesArray);
      });
    }
  };

  /**
   * Performs a dfs algorithm on nodes array to find the particular node whose value is same as value provided as attribute
   * @param {Array} nodes An Array of nodes
   * @param {Integer} value An Integer representing the value of node
   * @returns A Node Object
   */
  const dfs_algo_on_nodes = (nodes = [], value) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].value === value) {
        return nodes[i];
      }
      if (nodes[i].children && nodes[i].children.length) {
        let node = dfs_algo_on_nodes(nodes[i].children, value);
        if (node) {
          return node;
        }
      }
    }
  };

  const handleChecked = (e, value) => {
    let selectedItems = [...props.checked];
    if (e) {
      selectedItems.push(value);
    } else {
      let index = selectedItems.indexOf(value);
      selectedItems.splice(index, 1);
    }
    props.onCheck(selectedItems);
  };

  const handleArrow = (e, value, segment_id) => {
    e.stopPropagation();
    setActiveNode(value);
    props.handleButtonClick(value, segment_id);
  };

  /**
   * This function iterates through all its children and adds value to expanded array
   * This expanded array is used to open all nodes by default if expandAll prop is true
   * @param {Array} nodes An Array of nodes
   * @param {Array} expandedArray An Array of expanded nodes
   */
  const getExpandedArray = (nodes = [], expandedArray = []) => {
    nodes.forEach((element) => {
      if (element.children && element.children.length) {
        expandedArray.push(element.value);
        getExpandedArray(element.children, expandedArray);
      }
    });
  };

  const getOrderlist = (nodes = []) => {
    let style =
      props.side === "Companies"
        ? { display: "flex", justifyContent: "space-between" }
        : {};
    return (
      <ol className="checkbox-list">
        {nodes.map((item, index) => {
          let isChecked = props.checked.includes(item.value);
          let isExpanded = props.expanded
            ? props.expanded.includes(item.value)
            : false;
          let is_data =
            item?.is_data_available === "true" ? true : false ?? false;
          let is_valid =
            item?.is_data_available === undefined
              ? true
              : item.is_data_available === "true"
              ? true
              : false;
          return (
            <li
              key={index}
              data-testid={`checkbox-li-${item.value}`}
              // style={style}
              className={`checkbox-item ${
                activeNode === item.value ? "active" : ""
              } ${isExpanded ? "expanded-true" : "expanded-false"}`}
            >
              <span
                className="d-flex align-items-center rct-text"
                // style={{
                //   justifyContent: !is_valid ? "space-between" : "flex-start",
                //   width: is_valid ? "-webkit-fill-available" : "auto",
                // }}
              >
                {item.children && item.children.length ? (
                  !isExpanded ? (
                    <span
                      data-testid={`checkbox-chevron-${item.value}`}
                      className="material-icons-outlined expanded-false icon"
                      onClick={() => handleChevron(item.value, true)}
                    >
                      chevron_right
                    </span>
                  ) : (
                    <span
                      data-testid={`checkbox-chevron-${item.value}`}
                      className="material-icons-outlined expanded-true icon"
                      onClick={() => handleChevron(item.value, false)}
                    >
                      expand_more
                    </span>
                  )
                ) : (
                  <></>
                )}

                {item.is_data_available === "true" &&
                isChecked &&
                props.disabled ? (
                  <img src={GreenCheckbox} alt="GreenCheck" />
                ) : (
                  <input
                    className={
                      props.disabled
                        ? "custom-checkbox-new no-cursor"
                        : "custom-checkbox-new"
                    }
                    style={{
                      cursor: is_valid ? "pointer" : "not-allowed",
                    }}
                    type="checkbox"
                    id={item.value}
                    data-testid={`checkbox-${item.value}`}
                    onChange={(e) =>
                      handleChecked(e.target.checked, item.value)
                    }
                    checked={isChecked}
                    disabled={
                      props.disabled
                        ? true
                        : item.is_data_available === "true"
                        ? false
                        : item.is_data_available === "false"
                        ? true
                        : false
                    }
                  />
                )}
                {item.label.length >
                constants.VISUALIZATION.CHARACTER_LENGTH ? (
                  <label
                    className="text rct-title mb-0"
                    key={item.value}
                    htmlFor={item.label}
                    data-for={`tooltip-${item.value}-active`}
                    data-tip={item.label}
                    style={{
                      opacity:
                        item?.is_data_available === undefined
                          ? 1
                          : item?.is_data_available === "true"
                          ? 1
                          : 0.5,
                      textTransform: "capitalize",
                    }}
                  >
                    {item.label.substring(
                      0,
                      constants.VISUALIZATION.CHARACTER_LENGTH
                    ) + "..."}
                    <CustomReactTooltip
                      id={`tooltip-${item.value}-active`}
                      multiline={true}
                    />
                  </label>
                ) : (
                  <label
                    className="text rct-title mb-0"
                    key={item.value}
                    htmlFor={item.label}
                    style={{
                      opacity:
                        item?.is_data_available === undefined
                          ? 1
                          : item.is_data_available === "true"
                          ? 1
                          : 0.5,
                      textTransform: "capitalize",
                    }}
                  >
                    {item.label}
                  </label>
                )}

                {isChecked && props.showArrow && !props.disabled && (
                  <span
                    className="material-icons-outlined add-icon"
                    data-testid={`checkbox-arrow-${item.value}`}
                    onClick={(e) => handleArrow(e, item.value, item.segment_id)}
                  >
                    arrow_forward
                  </span>
                )}
                {/* {!is_valid && (
                  <label
                    className="text rct-title mb-0 not-available-data-tooltip"
                    key="Currently, the necessary data is unavailable."
                    htmlFor="Currently, the necessary data is unavailable."
                    data-for={`tooltip-${item.value}-active`}
                    data-tip="Currently, the necessary data is unavailable."
                  >
                    <InfoIcon />
                    <CustomReactTooltip
                      id={`tooltip-${item.value}-active`}
                      multiline={true}
                    />
                  </label>
                )} */}
              </span>
              {item.children && isExpanded ? (
                getOrderlist(item.children)
              ) : (
                <></>
              )}
            </li>
          );
        })}
      </ol>
    );
  };

  return (
    <div className="visualisation" style={{ width: props?.width || "auto" }}>
      {getOrderlist(props.nodes)}
    </div>
  );
}

export default React.memo(CustomCheckBox);
