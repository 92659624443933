import React, { useState, useEffect } from "react";
import { VisualizationServicesV2 } from "../../../../Services/VisualizationV2";
import CustomCheckBox from "../CustomCheckBox";
import { orderBy } from "lodash";

function CompanySection({
  disableMetricsBox,
  disableGroVerticalBox,
  addSegment,
  handleByCompanyTrigger,
  selectedCompanies,
  disabledCompany,
}) {
  const [companyList, setCompanyList] = useState([]);
  const [backupCompantList, setBackupCompanyList] = useState([]);

  const handleSearch = (e) => {
    const regularExp = new RegExp(e.target.value, "i");

    let tempNodes = JSON.parse(JSON.stringify(backupCompantList));
    let result = [];
    result = tempNodes.filter((x) => regularExp.test(x.label));
    setCompanyList(result);
  };

  const fetchCompanies = async () => {
    try {
      let companyRes = await VisualizationServicesV2.getCompanies();
      let data = companyRes.data.data.map((i) => ({
        ...i,
        value: i.id,
        label: i.name,
      }));

      setCompanyList(
        orderBy(data, [(d) => d.is_data_available === "true"], ["desc"])
      );
      setBackupCompanyList(
        orderBy(data, [(d) => d.is_data_available === "true"], ["desc"])
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <div
      className="col-md-3 select-companies-block"
      style={{
        backgroundColor: disabledCompany
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(243, 243, 243, 0.5)",
        cursor: disabledCompany ? "pointer" : "not-allowed",
        // opacity: disabledCompany ? 1 : 0.5,
      }}
    >
      <h6 className="text-grey block-title font-weight-normal mb-0 text-grey">
        Select Companies
      </h6>
      <div className="plain-checkbox-list-wrapper">
        <div className="sticky-section-search">
          <div className="select_companies_search">
            <input
              className="form-control text-grey"
              type="text"
              name="name"
              onChange={(e) => handleSearch(e)}
              placeholder={`Search Company`}
              disabled={!disabledCompany}
            />
            <span className="material-icons-outlined text-grey">search</span>
          </div>
          <hr className="m-0" />
        </div>
        <CustomCheckBox
          nodes={companyList}
          checked={selectedCompanies}
          is_data={true}
          showArrow={false}
          onCheck={(checked) => handleByCompanyTrigger(checked)}
          key="Companies"
          disabled={!disabledCompany}
          side="Companies"
          is_tree={false}
        />
      </div>
      {disabledCompany && (
        <div
          style={{
            // position: "absolute",
            bottom: "0%",
            height: "30px",
            width: "100%",
            backgroundColor: "#DCEDFF",
            display: "flex",
            alignItems: "center",
            padding: "5px",
            justifyContent: "space-between",
            cursor: selectedCompanies.length ? "pointer" : "not-allowed",
          }}
          onClick={(e) => selectedCompanies.length > 0 && addSegment(e)}
        >
          <span style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}>
            Next
          </span>
          <span
            className="material-icons-outlined add-icon"
            style={{ color: "#2E6AD2", fontSize: "18px" }}
          >
            arrow_forward
          </span>
        </div>
      )}
    </div>
  );
}

export default CompanySection;
