// import { Chart } from "@amcharts/amcharts4/charts";
import React, { useCallback, useMemo } from "react";
import Chart from "../../../Containers/V2/VisalizationV2/Chart";
import { useSelector } from "react-redux";
import decodeJWT from "../../../lib/decodeJWT";
import constants from "../../../Utils/constants";

const MemoizedChart = React.memo(
  ({
    selectedNodes,
    setChartPreview,
    quarterToggle,
    setQuarterToggle,
    paramId,
    selectedChart,
    setSelectedChart,
    chartName,
  }) => {
    return (
      <Chart
        selectedNodes={selectedNodes}
        setChartPreview={setChartPreview}
        quarterToggle={quarterToggle}
        setQuarterToggle={setQuarterToggle}
        paramId={paramId}
        setSelectedChart={setSelectedChart}
        selectedChart={selectedChart}
        chartName={chartName}
      />
    );
  }
);
function ChartView({
  setChartName,
  saveChart,
  selectedNodes,
  setChartPreview,
  chartName,
  quarterToggle,
  setQuarterToggle,
  paramId,
  chartData,
  selectedChart,
  setSelectedChart,
}) {
  const memoizedSelectedNodes = useMemo(
    () => selectedNodes.slice(0, 25),
    [selectedNodes]
  );
  const userData = useSelector((state) => state.home);
  let isCreatedUser = chartData
    ? userData.loginUserSuccess.id === chartData.created_by
    : true;

  const memoizedSetChartPreview = useCallback(
    (preview) => {
      setChartPreview(preview);
    },
    [setChartPreview]
  );
  const roleName = decodeJWT(localStorage.getItem("token")).role.short_name;

  return (
    <div className="preview-main-div">
      {isCreatedUser && (
        <div className="div-1">
          <span>Insight Studio</span>
          <div>
            <input
              type="text"
              placeholder="Enter Visualization name"
              value={chartName}
              onChange={(e) => setChartName(e.target.value)}
            />
            <button
              className="btn btn-primary save-button"
              style={{ background: "#046cd9" }}
              onClick={() => saveChart(false)}
              disabled={!chartName.length}
            >
              Save
            </button>
            <button
              className="btn btn-primary save-button"
              style={{ background: "#007F4D" }}
              onClick={() => saveChart(true)}
              disabled={!chartName.length}
            >
              {chartData?.is_published
                ? roleName == "lead"
                  ? constants.VISUALIZATION.PUBLISH.CLIENT_UNPUBLISHED
                  : constants.VISUALIZATION.PUBLISH.ADMIN_UNPUBLISHED
                : roleName == "lead"
                ? constants.VISUALIZATION.PUBLISH.CLIENT_PUBLISH
                : constants.VISUALIZATION.PUBLISH.ADMIN_PUBLISH}
            </button>
          </div>
        </div>
      )}

      <div className="chart-div">
        <MemoizedChart
          selectedNodes={memoizedSelectedNodes}
          setChartPreview={memoizedSetChartPreview}
          quarterToggle={quarterToggle}
          setQuarterToggle={setQuarterToggle}
          paramId={paramId}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          chartName={chartName}
        />
      </div>
    </div>
  );
}

export default ChartView;
