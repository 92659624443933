import gaEvent from '../Utils/GAEvents';
import constants from './constants';

class CalculateScrollOnPage {
    constructor() {
        this.scrollTimerRef = null;
        this.scrollValue = 0;
    }

    init = () => {
        document.addEventListener('scroll', this.scrollEventListenerCallBack);       // add scroll event
    }

    scrollEventListenerCallBack = () => {
        if (this.scrollTimerRef) {
            clearTimeout(this.scrollTimerRef);
        }
        this.scrollTimerRef = setTimeout(() => {
            let h = document.documentElement,
                b = document.body,
                st = 'scrollTop',
                sh = 'scrollHeight';
            let percent = parseInt((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100);
            if (percent && this.scrollValue < percent) {
                this.scrollValue = percent;
            }
        }, 500)
    }

    sendScrollEvent = (customData) => {
        customData.value = this.scrollValue;
        if(this.scrollValue) {
            gaEvent.sendEventToGA(constants.GA.CUSTOM_EVENTS.SCROLL, customData);      // save the percentage of scrolling to ga4
        }
        document.removeEventListener('scroll', this.scrollEventListenerCallBack);
    }

}

const calculateScrollOnPage = new CalculateScrollOnPage();

export default calculateScrollOnPage