import React, { Component } from "react";
import CustomReactToast from "../Components/CustomReactToast";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import SessionPopup from "../Components/SessionPopup";
import React_GA_Intialise from "../Utils/InitializeGa";
import { openSearchBar } from "../Store/Actions/User";
import { formatRoute } from "react-router-named-routes";

class Layout extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    toggleSideBar: false,
    popoverOpen: false,
  };

  componentDidMount() {
    let is_admin =
      this.props.home &&
      this.props.home.loginUserSuccess &&
      this.props.home.loginUserSuccess.is_admin;
    if (!is_admin) {
      let userId =
        this.props.home &&
        this.props.home.loginUserSuccess &&
        this.props.home.loginUserSuccess.id;
      React_GA_Intialise(userId);
    }
  }

  toggleSupport = () => this.setState({ popoverOpen: !this.state.popoverOpen });

  toggleSideBar = () => {
    this.setState({
      toggleSideBar: !this.state.toggleSideBar,
    });
  };

  changeSearch = () => {
    this.props.history.push({
      pathname: formatRoute("/search", {}),
    });
    this.props.openSearchBar(false);
  };
  render() {
    let path = window.location.pathname;
    return (
      <div className={`wrapper ${this.state.toggleSideBar ? "open_nav" : ""}`}>
        <div>
          <Header
            popoverOpen={this.state.popoverOpen}
            toggleSupport={this.toggleSupport}
            history={this.props.history}
            changepopover={this.changeSearch}
          />
        </div>
        <div
          onClick={this.toggleSideBar}
          className={`${this.state.toggleSideBar ? "body-overlay" : ""}`}
        ></div>
        <main>
          <div className="">
            <div>
              <div
                style={{
                  display: `${
                    this.props.home && this.props.home.openSearchBar
                      ? "none"
                      : "initial"
                  }`,
                }}
              >
                {path !== "/manage-preferences" && path !== "/search" && (
                  <Sidebar
                    toggleSideBarHandler={this.toggleSideBar}
                    history={this.props.history}
                    toggleSideBar={this.state.toggleSideBar}
                  />
                )}
                <Content />
              </div>
            </div>
            <CustomReactToast />
          </div>
        </main>
        <footer>
          <div className="footer-content d-flex align-items-center h-100 justify-content-center">
            Copyright &copy; {new Date().getFullYear()} . TBR, Inc.
          </div>
        </footer>
        <SessionPopup />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
