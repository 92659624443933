import React, { Component } from "react";
import CustomPagination from "./Pagination";
import constants from "../Utils/constants";
import { OpenSearchServices } from "../Services/OpenSearch";
import DailogNew from "../Components/DailogNew";
import Toast from "../Utils/Toast";
import Highlight from "./highlight";
import decodeJWT from "../lib/decodeJWT";
import { debounce } from "lodash";
import MultiSelect from "../Pages/Multiselectv2";
import { getSearchText, openSearchBar } from "../Store/Actions/User";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";

const resources = {
  company: "Company",
  project: "Project",
  benchmark: "Benchmark",
  market_forecast: "Market Forecast",
  market_landscape: "Market Landscape",
  special_report: "Special Report",
  customer_study: "Customer Study",
  ecosystem: "Ecosystem",
};
const resource_urls = {
  company: "company",
  project: "project",
  benchmark: "benchmark",
  market_forecast: "market-forecast",
  market_landscape: "market-landscape",
  special_report: "special-reports",
  customer_study: "customer-studies",
  ecosystem: "ecosystems",
};

const utilizeScroll = () => {
  const elRef = React.createRef();
  const executeScroll = () =>
    elRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  return { executeScroll, elRef };
};

const searchOptionPermissions = () => {
  let permissions = decodeJWT(localStorage.getItem("token")).permissions;
  let SearchOptions = [
    {
      value: "company",
      label: "Companies",
      color: "#00B8D9",
      isDisabled: true,
    },
    { value: "project", label: "Projects", color: "#0052CC", isDisabled: true },
    {
      value: "benchmark",
      label: "Benchmarks",
      color: "#5243AA",
      isDisabled: true,
    },

    {
      value: "special_report",
      label: "Special Reports",
      color: "#FFC400",
      isDisabled: true,
    },

    {
      value: "market_forecast",
      label: "Market Forecasts",
      color: "#FF5630",
      isDisabled: true,
    },

    {
      value: "market_landscape",
      label: "Market Landscapes",
      color: "#FF8B00",
      isDisabled: true,
    },
    {
      value: "customer_study",
      label: "Customer Study",
      color: "#FF8B00",
      isDisabled: true,
    },
    {
      value: "ecosystem",
      label: "Ecosystem",
      color: "#FF8B00",
      isDisabled: true,
    },
  ];

  SearchOptions = SearchOptions.map((ele) => {
    ele.isDisabled = !permissions[ele.value].includes("show");
    return ele;
  });

  return SearchOptions;
};

const regex = /(<([^>]+)>)/gi;

class GlobalSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 10,
      searchText: "",
      data: [],
      totalCount: 0,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      shownorecords: false,
      exactsearch: "",
      optionSelected: null,
      selectedfields: [],
      selected: [],
      searchOptions: searchOptionPermissions(),
      currentElement: 0,
      currentPageLength: 0,
      searchSuggestions: [],
      currentSearchSuggestion: 0,
      searchSuggestionsLength: 0,
      showSearchSuggestions: false,
      searchHistory: [],
      searchHistroyLength: 0,
    };
    this.elScroll = utilizeScroll();
    this.dataRef = React.createRef();
    this.searchRef = React.createRef();
  }

  componentDidUpdate() {
    this.loadCompleted();
  }

  componentDidMount() {
    this.checkForSearchURL();
    this.searchRef.current.focus();
    this.setState({
      showSearchSuggestions: document.activeElement === this.searchRef.current,
    });

    document.addEventListener("click", (event) => {
      this.setState({
        showSearchSuggestions:
          document.activeElement === this.searchRef.current,
      });
    });
    document.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          if (event.target.id === "searchInput") {
            this.setState(
              {
                searchSuggestions: [],
                currentSearchSuggestion: 0,
                searchSuggestionsLength: 0,
                pageNo: 1,
              },
              () => {
                this.getTableData();
              }
            );
          } else {
            if (
              this.state.currentElement === 0 &&
              this.state.searchText.length > 2
            ) {
              this.setState(
                {
                  searchText:
                    this.state.currentSearchSuggestion != 0
                      ? this.state.searchSuggestions[
                          this.state.currentSearchSuggestion - 1
                        ]
                      : this.state.searchText,
                  pageNo: 1,
                },
                () => {
                  this.loadCompleted();
                }
              );
            } else if (this.elScroll.elRef.current?.nodeName !== "INPUT") {
              this.openInNewTab(this.state.data[this.state.currentElement - 1]);
            }
          }

          break;
        case "ArrowDown":
          event.preventDefault();
          if (
            event.target.id === "searchInput" &&
            this.state.searchSuggestionsLength > 0
          ) {
            this.setState(
              {
                currentSearchSuggestion:
                  this.state.currentSearchSuggestion ===
                  this.state.searchSuggestionsLength
                    ? 1
                    : this.state.currentSearchSuggestion + 1,
                pageNo: 1,
              },
              () => {
                this.setState({
                  searchText: this.state.searchSuggestions[
                    this.state.currentSearchSuggestion - 1
                  ].replace(regex, ""),
                });
              }
            );
          } else {
            this.setState(
              {
                currentElement:
                  this.state.currentElement === this.state.currentPageLength
                    ? 1
                    : this.state.currentElement + 1,
              },
              () => {
                this.loadCompleted();
              }
            );
          }
          break;
        case "ArrowUp":
          event.preventDefault();
          if (
            event.target.id === "searchInput" &&
            this.state.searchSuggestionsLength > 0
          ) {
            this.setState(
              {
                currentSearchSuggestion:
                  this.state.currentSearchSuggestion <= 1
                    ? this.state.searchSuggestionsLength
                    : this.state.currentSearchSuggestion - 1,
                pageNo: 1,
              },
              () => {
                this.setState({
                  searchText: this.state.searchSuggestions[
                    this.state.currentSearchSuggestion - 1
                  ].replace(regex, ""),
                });
              }
            );
          } else {
            this.setState(
              {
                currentElement:
                  this.state.currentElement <= 1
                    ? this.state.currentPageLength
                    : this.state.currentElement - 1,
              },
              () => {
                this.loadCompleted();
              }
            );
          }

          break;
        case "Escape":
          if (
            /^(?:input|textarea|select|button)$/i.test(event.target.tagName)
          ) {
            break;
          }
          this.clearStates();
          this.props.closesearch();
          this.props.openSearchBar(false);
          this.props.getSearchText("");
          break;
        default:
          break;
      }
    });
    this.getSearchHistory();
  }

  loadCompleted = debounce(() => {
    if (this.elScroll.elRef.current != null) {
      let type = this.elScroll.elRef.current?.nodeName;
      if (type === "INPUT") {
        if (this.elScroll.elRef.current) {
          this.elScroll.elRef.current.focus();
        }
      } else {
        this.elScroll.executeScroll();
      }
    }
  }, 200);

  handlePage = (input) => {
    this.setState(
      {
        pageNo: input,
      },
      () => {
        this.getTableData();
      }
    );
  };

  handlePageSize = (e) => {
    this.setState(
      {
        pageSize: e.target.value,
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  totalPages = () => {
    let pages = Math.ceil(this.state.totalCount / this.state.pageSize);
    return pages === 0 ? 1 : pages;
  };

  checkForSearchURL = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const encodedSearchURL = queryParams.get("q");
    if (encodedSearchURL) {
      const decodedSearchURL = decodeURIComponent(encodedSearchURL);
      this.setState({ searchText: decodedSearchURL }, () =>
        this.getSearchResult()
      );
    }
  };

  getSearchResult = () => {
    let selectedfields = this.state.selected.map((value) => value.value);
    let body = {
      search: this.state.searchText,
      page: this.state.pageNo,
      pageSize: this.state.pageSize,
      selectedFields: selectedfields.sort(),
    };
    if (this.state.searchText.trim() !== "") {
      OpenSearchServices.fetchSearchData(body)
        .then((res) => {
          if (!res.data.error) {
            let modifiedResponse =
              res.data.data && res.data.data.length > 0
                ? res.data.data.filter(
                    (resp) =>
                      resp?._source &&
                      resp?._source?.type &&
                      resp?._source?.type !== "project"
                  )
                : [];
            this.setState(
              {
                data: modifiedResponse,
                exactsearch: this.state.searchText,
                totalCount: res.data.meta.count,
                currentPageLength: res.data.data.length,
                showSearchSuggestions: false,
                searchSuggestions: [],
                searchSuggestionsLength: 0,
                currentElement: 0,
              },
              () => {
                if (res.data.meta.count === 0) {
                  this.setState({
                    shownorecords: true,
                  });
                } else {
                  this.searchRef.current.blur();
                }
              }
            );
          } else {
            Toast(res.data.error, "error");
          }
        })
        .catch((err) => {
          Toast(err, "error");
        });
    }
  };

  getTableData = () => {
    const { history } = this.props;
    const encodedSearchURL = encodeURIComponent(this.state.searchText);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("q", encodedSearchURL);
    history.push({ search: queryParams.toString() });
    this.getSearchResult();
  };

  clearText = () => {
    this.setState(
      {
        searchText: "",
        searchSuggestions: this.state.searchHistory,
        searchSuggestionsLength: this.state.searchHistory.length,
      },
      () => {
        this.searchRef.current.focus();
      }
    );
  };

  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };

  handleSearchText = (event) => {
    this.setState(
      {
        searchText: event.target.value,
        pageNo: 1,
      },
      () => {
        if (this.state.searchText.length > 3) {
          this.getSearchSuggestions();
        }
        if (this.state.searchText.length === 0) {
          this.setState({
            searchSuggestions: this.state.searchHistory,
            searchSuggestionsLength: this.state.searchHistory.length,
          });
        }
      }
    );
  };

  getSearchHistory = (e) => {
    OpenSearchServices.fetchSearchHistory().then((res) => {
      let data = res.data.data?.map((ele) => ele.keyword);
      data = [...new Set(data)];
      this.setState({
        searchHistory: data,
        searchHistoryLength: data.length,
        searchSuggestions: data,
        searchSuggestionsLength: data.length,
      });
    });
  };

  getSearchSuggestions = debounce(() => {
    let body = {
      search: this.state.searchText,
    };
    OpenSearchServices.fetchSearchSuggestions(body).then((res) => {
      let data = res.data.data;
      data = [
        ...new Set(data.map((ele) => ele.highlight.subcategory_title[0])),
      ];
      this.setState({
        searchSuggestions: data,
        searchSuggestionsLength: data.length,
      });
    });
  }, 200);

  openInNewTab = (row) => {
    let mapping_id =
      row?._source?.type === "company"
        ? row?._source.company_id
        : row?._source.mapping_id;
    let body = {
      resource_type: row?._source.type,
      mapping_id: mapping_id,
    };
    if (row?._source?.type === "company") {
      body.segment_id = row?._source?.segment_id;
    }
    OpenSearchServices.checkResourceAccess(body).then((res) => {
      if (res.data.has_access === true) {
        let url =
          row?._source?.type != "company"
            ? `/${resource_urls[row?._source?.type]}/${
                row?._source?.mapping_id
              }/dashboard?content=${row._id}`
            : `/${row?._source?.type}/${row?._source?.company_id}/dashboard?segment=${row?._source?.segment_id}&content=${row._id}`;
        window.open(url, "_blank", "noopener,noreferrer");
        this.props.openSearchBar(false);
      } else {
        let dailogModalHeader = (
          <>
            <h3 className="text-darkblue2 font-weight-bold">
              {constants.WARNING.CONTENT_ACCESS_DENIED_TITLE}
            </h3>
          </>
        );
        let dailogModalContent = (
          <>
            <div className="noaccess-body">
              <h2 className="font-weight-semibold mb-0">
                {constants.WARNING.CONTENT_ACCESS_DENIED_TEXT}
              </h2>
            </div>
          </>
        );
        this.setState({
          dailogModal: true,
          dailogModalHeader: dailogModalHeader,
          dailogModalContent: dailogModalContent,
          dailogModalConfig: { type: false, id: null },
          dailogModalStyleType: "noaccess-modal",
        });
      }
    });
  };

  handleEnterKey = (e) => {};
  handleSearch = () => {
    this.setState(
      {
        pageNo: 1,
      },
      () => {
        this.getTableData();
      }
    );
  };

  clearStates = () => {
    this.setState({
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      pageSize: constants.PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 10,
      searchText: "",
      data: [],
      totalCount: 0,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      shownorecords: false,
      currentElement: 0,
      selectedfields: [],
      optionSelected: [],
      selected: [],
    });
  };

  suggestionClick = (ele) => {
    this.setState(
      {
        searchText: ele.replace(regex, ""),
      },
      () => {
        this.getTableData();
      }
    );
  };

  goBackToDashboard = () => {
    const { history } = this.props;
    this.clearStates();
    this.clearText();
    this.props.openSearchBar(false);
    this.props.getSearchText("");
    history.push(
      formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE),
      {}
    );
  };

  parseContent = (content) => {
    let index = content.indexOf(this.state.exactsearch);
    if (index !== -1 && index > 50) {
      return "..." + content.substring(index - 50, index + 307).concat("...");
    }
    return content.substring(0, 360).concat("...");
  };

  render() {
    return (
      <>
        <aside className="main_content">
          <div className="globalSearch-div">
            <div className="globalSearch-section pt-0">
              <div className="search-content-section">
                <div className="search-filter position-relative">
                  <span
                    class="material-icons-outlined back_arrow"
                    onClick={this.goBackToDashboard}
                  >
                    arrow_back
                  </span>
                  <div className="d-flex gap1">
                    <div>
                      <MultiSelect
                        options={this.state.searchOptions}
                        value={this.state.selected}
                        onChange={(state) => {
                          this.setState({ selected: state }, () =>
                            this.getTableData()
                          );
                        }}
                      />
                    </div>
                    <div className="position-relative input_div">
                      <div className="position-relative">
                        <input
                          type="text"
                          id="searchInput"
                          className="w-100"
                          placeholder="Search here...."
                          ref={this.searchRef}
                          value={this.state.searchText}
                          onChange={(e) => {
                            this.handleSearchText(e);
                          }}
                          onKeyPress={(e) => {
                            this.handleEnterKey(e);
                          }}
                          autoComplete={"off"}
                        />
                        <span
                          className="material-icons-outlined search_icon"
                          onClick={this.getTableData}
                        >
                          search
                        </span>
                        {this.state.showSearchSuggestions &&
                          this.state.searchSuggestionsLength > 0 && (
                            <div className="input_div search_results">
                              <ul>
                                {this.state.searchSuggestions.map(
                                  (ele, index) => {
                                    return (
                                      <li
                                        style={{
                                          backgroundColor:
                                            index + 1 ===
                                            this.state.currentSearchSuggestion
                                              ? "#F1F4FB"
                                              : "",
                                        }}
                                        onClick={() => {
                                          this.suggestionClick(ele);
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: ele,
                                          }}
                                        />
                                        <span
                                          className="material-icons-outlined search_icon"
                                          onClick={this.getTableData}
                                        >
                                          {this.state.searchText.length > 3
                                            ? "search"
                                            : "history"}
                                        </span>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </div>
                          )}
                      </div>

                      {this.state.searchText.length >= 3 && (
                        <span
                          className="material-icons-outlined close_icon"
                          onClick={this.clearText}
                        >
                          close
                        </span>
                      )}

                      <div className="d-flex align-items-center gap1 py-2 px-4 ml-2 mb-n3">
                        <div className="d-flex align-items-center text-grey-clr">
                          <span className="material-icons mr-1 icon-fs-20">
                            swap_vert
                          </span>
                          <span>navigate</span>
                        </div>
                        <div className="d-flex align-items-center text-grey-clr">
                          <span className="material-icons-outlined mr-1 icon-fs-20">
                            keyboard_return
                          </span>
                          <span>enter</span>
                        </div>
                        <div className="d-flex align-items-center text-grey-clr">
                          <span className="font-weight-bold mr-1 icon-fs-14">
                            esc
                          </span>
                          <span>exit</span>
                        </div>
                        {this.state.totalCount !== 0 && (
                          <div className="d-flex align-items-center  text-grey ">
                            {this.state.totalCount} Results found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.data.length > 0
                ? this.state.data.map((row, index) => {
                    return (
                      <div
                        className="search-content-box"
                        style={{
                          overflow: "hidden",
                          backgroundColor:
                            this.state.currentElement === index + 1
                              ? "#F1F4FB"
                              : "",
                        }}
                        ref={
                          index + 1 === this.state.currentElement &&
                          this.elScroll.elRef
                        }
                      >
                        <span
                          className="breadcrumbs"
                          onClick={() => {
                            this.openInNewTab(row);
                            this.setState({ currentElement: index + 1 });
                          }}
                        >
                          {resources[row?._source?.type]} {" > "}{" "}
                          {row?._source?.title}
                        </span>
                        <div
                          className="text-heading"
                          onClick={() => {
                            this.openInNewTab(row);
                            this.setState({ currentElement: index + 1 });
                          }}
                        >
                          <Highlight
                            searchText={this.state.exactsearch}
                            content={row?._source?.subcategory_title}
                          />
                        </div>
                        <span
                          className="text-description"
                          onClick={() => {
                            this.openInNewTab(row);
                            this.setState({ currentElement: index + 1 });
                          }}
                        >
                          {" "}
                          <Highlight
                            searchText={this.state.exactsearch}
                            content={this.parseContent(row?._source?.content)}
                          />
                        </span>
                      </div>
                    );
                  })
                : this.state.shownorecords && (
                    <div
                      style={{ textAlign: "center", paddingTop: "1.5625rem" }}
                    >
                      No Records Found
                    </div>
                  )}
            </div>

            {this.state.totalCount > 0 && (
              <div className={"global-search-pagination"}>
                <CustomPagination
                  type={"search"}
                  className={"center-pagination custom-pagination-new"}
                  handlePage={this.handlePage}
                  totalPages={this.totalPages}
                  handlePageSize={this.handlePageSize}
                  pageSize={this.state.pageSize}
                  pageNo={this.state.pageNo}
                />
              </div>
            )}

            <DailogNew
              isOpen={this.state.dailogModal}
              accept={this.dailogModalAccept}
              decline={this.dailogModalDecline}
              header={this.state.dailogModalHeader}
              content={this.state.dailogModalContent}
              config={this.state.dailogModalConfig}
              modalStyleType={this.state.dailogModalStyleType}
            />
          </div>
        </aside>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
    getSearchText: (data) => {
      dispatch(getSearchText(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
