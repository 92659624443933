import ReactGA from "react-ga4";
import calculateTimeOnPage from './TimeOnPage';
import calculateScrollOnPage from './ScrollOnPage';
import constants from './constants';



class GAEvents {
    constructor() {
        this.moduleName = '';
        this.id = '';
        this.segmentId = '';
        this.isPublished = '';
        this.page_url = '';
    }

    init = (props) => {
      
        this.moduleName = props.moduleName;
        this.id = props.id;
        this.segmentId = props.segmentId;
        this.isPublished = props.isPublished;
        this.page_url = props.page_url;

        // Triggering Page visit.
        let customData = this.getCustomData();
        this.sendEventToGA(constants.GA.EVENTS.PAGE_VIEW, customData);

        calculateTimeOnPage.init();                              // start the timer...
        calculateScrollOnPage.init();
        window.onbeforeunload = () => {
            this.trigger();
        }
    }

    getCustomData = () => {
        let data = {
            moduleName: this.moduleName
        }
        if (this.id) {
            data.id = this.id;
        }
        if (this.segmentId) {
            data.segmentId = this.segmentId;
        }
        if (this.isPublished) {
            data.isPublished = this.isPublished;
        }
        if (this.page_url) {
            data.page_location = this.page_url;
        }
        return data;
    }

    trigger = () => {
        let customData = this.getCustomData();
        calculateTimeOnPage.sendTimeOnPageEvent(customData);              // engagement time event
        calculateScrollOnPage.sendScrollEvent(customData);                // scroll
    }

    sendEventToGA = (event, customData) => {
        switch (event) {
            case constants.GA.EVENTS.PAGE_VIEW:
            case constants.GA.CUSTOM_EVENTS.DOWNLOAD_VIEW:
            case constants.GA.CUSTOM_EVENTS.LOGIN:
            case constants.GA.CUSTOM_EVENTS.SCROLL:
            case constants.GA.CUSTOM_EVENTS.USER_ENGAGEMENT_TIME: {
                // delete customData.page_url;
                ReactGA.gtag("event", event, customData);
                break;
            }
            default: console.log('Hey, there is no such case!!!')
        }
    }

    unSetUserId = () => {
        // set user id
        ReactGA.set({
            crm_id: null
        })
    }

}

const gaEvent = new GAEvents();
export default gaEvent;