const gotoSection = (id, num = 140) => {
  if (document.querySelector(id)) {
    let top =
      document.querySelector(id).getBoundingClientRect().top + window.scrollY;
    window.scrollTo({
      top: top - num,
      behavior: "smooth",
    });
  }
};

export default gotoSection;
