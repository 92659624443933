import React, { useEffect, useRef } from "react";

const InlineSVGWithAttributes = ({ svgContent, attributesToUpdate }) => {
  const svgContainerRef = useRef(null);

  // Update SVG attributes after content is injected
  useEffect(() => {
    if (svgContainerRef.current && svgContent) {
      const svgElement = svgContainerRef.current.querySelector("svg");

      if (svgElement) {
        // Apply updates to the specified attributes
        Object.keys(attributesToUpdate).forEach((key) => {
          svgElement.setAttribute(key, attributesToUpdate[key]);
        });
      }
    }
  }, [svgContent, attributesToUpdate]);

  return (
    <div
      ref={svgContainerRef}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default InlineSVGWithAttributes;
