import React from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import { HelpServices } from "../Services/Help";

export default class HelpModal extends React.Component {
  constructor() {
    super();
    this.state = {
      boardModal: false,
      title: "",
      description: "",
      video_url: "",
    };
  }
  loadData = () => {
    let category = this.props.category;
    // Make a request with a given Category
    HelpServices.get("helpPages/getpage/" + category)
      .then((response) => {
        // handle success
        let resArray = response.data.data;
        if (resArray.length > 0) {
          this.setState({
            title: resArray[0].title,
            description: resArray[0].text,
            video_url: resArray[0].video,
          });
        }
      })
      .catch(function (error) {
        // handle error
      });
  };
  setModelIsOpen = () => {
    this.setState({
      boardModal: true,
    });
  };
  closeBoardModal = () => {
    this.setState({
      boardModal: false,
    });
  };

  render() {
    return (
      <>
        <button
          className="icon-btn-primary icon-btn d-inline-flex align-items-center justify-content-center"
          data-for="help"
          name="btnTest"
          data-tip="Help"
          onClick={this.setModelIsOpen}
          data-testid="help-button-1"
        >
          <span className="material-icons-outlined icon">help_outline</span>
          <CustomReactTooltip id="help" multiline={true} />
        </button>
        <Modal
          isOpen={this.state.boardModal}
          toggle={this.closeBoardModal}
          onOpened={this.loadData}
          className={"modal-lg modal-w-header custom-modal"}
        >
          <ModalHeader toggle={this.closeBoardModal}>Help</ModalHeader>
          <ModalBody>
            <div>
              <div>
                {this.state.title ||
                this.state.description ||
                this.state.video_url ? (
                  <div>
                    {this.state.title ? (
                      <div className="row-item">
                        <Label className="title-label mb-0">
                          <strong>Title:</strong>
                        </Label>
                        <span data-testid="help-page-title">
                          {" " + this.state.title}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.description ? (
                      <div className="row-item">
                        <Label className="title-label">
                          <strong>Description:</strong>
                        </Label>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.state.description,
                          }}
                          data-testid="help-page-description"
                          className="helpDescription"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.video_url ? (
                      <div className="row-item">
                        <Label className="d-block title-label">
                          <strong>Video:</strong>
                        </Label>
                        <video
                          controls
                          className="w-75"
                          src={this.state.video_url}
                          data-testid="help-page-url"
                        >
                          Your browser does not support HTML video.
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div data-testid="help-page-no-data-div">No Help Data</div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              data-testid="help-button-close"
              className="modal-btn"
              color="secondary"
              onClick={this.closeBoardModal}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
