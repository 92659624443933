import { home as HOME } from '../actionConstants'

const loginUserSuccess = (state = {}, action) => {
  switch (action.type) {
    case HOME.LOGIN_USER_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const loginUserFailure = (state = {}, action) => {
  switch (action.type) {
    case HOME.LOGIN_USER_FAILURE:
      return action.payload
    default:
      return state
  }
}

const segments = (state = {}, action) => {
  switch (action.type) {
    case HOME.SEGMENTS:
      return action.payload
    default:
      return state
  }
}

const activeMenuTab = (state = {}, action) => {
  switch (action.type) {
    case HOME.ACTIVE_MENU_TAB:
      return action.payload
    default:
      return state
  }
}

const displayName = (state = "", action) => {
  switch (action.type) {
    case HOME.DISPLAY_NAME:
      return action.payload
    default:
      return state
  }
}

const openSearchBar = (state = {}, action) => {
  switch (action.type) {
    case HOME.OPEN_SEARCHBAR:
      return action.payload
    default:
      return state
  }
}

const getSearchText = (state = "", action) => {
  switch (action.type) {
    case HOME.SEARCH_TEXT:
      return action.payload
    default:
      return state
  }
}



export {
  loginUserSuccess,
  loginUserFailure,
  segments,
  activeMenuTab,
  openSearchBar,
  getSearchText,
  displayName
}
