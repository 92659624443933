import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import * as echarts from "echarts";
import Toast from "../../../../Utils/Toast";
import { makeUniqueSeriesNames } from "../../../../Helpers/Helper";

const NewBarChart = React.memo(
  forwardRef((props, ref) => {
    const chartRef = useRef(null);

    useEffect(() => {
      const myChart = echarts.init(chartRef.current, null, {
        renderer: "canvas", // Use canvas renderer for better export compatibility
      });
      const uniqueSeries = makeUniqueSeriesNames(props.data.series);


      myChart.setOption({
        title: {
          text: "Bar Chart",
        },
        legend: props.data.legend,
        grid: props.data.grid,
        tooltip: {
          trigger: "item",
        },
        xAxis: {
          data: props.data.xAxis,
        },
        yAxis: {},
        series: uniqueSeries,
      });

      return () => {
        myChart.dispose();
      };
    }, [props]);

    useImperativeHandle(ref, () => ({
      downloadChart: (format) => {
        const myChart = echarts.getInstanceByDom(chartRef.current);
        const base64 = myChart.getDataURL({
          type: format.toLowerCase(),
          pixelRatio: 2,
          backgroundColor: "#fff",
        });

        const link = document.createElement("a");
        link.href = base64;
        link.download = `Bar_chart.${format.toLowerCase()}`;
        link.click();
        Toast("Bar chart downloaded successfully", "success");
      },
    }));

    return <div ref={chartRef} style={{ width: "100%", height: "500px" }} />;
  })
);

export default NewBarChart;
