const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "is_active",
    title: "Status",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segment_id",
    title: "Segment",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "companies",
    title: "Companies",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
  },
];

const tbrAllReportTable = [
  {
    id: "report_type",
    title: "Report Type",
    sortable: false,
    style: {
      textAlign: "left",
      textTransform: "capitalize",
      width: "200px",
      zIndex:9,
    },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "report_name",
    title: "Report Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segment",
    title: "Segment",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "company_list",
    title: "Company List",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const projectTable = [
  {
    id: "report_name",
    title: "Report Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "project_manager",
    title: "Project Manager",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "client",
    title: "Client",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const companyTable = [
  {
    id: "report_name",
    title: "Report Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segment",
    title: "Segment",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "company_list",
    title: "Company List",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "report_type",
    title: "Report Type",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const reportTable = [
  {
    id: "report_name",
    title: "Report Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "segment",
    title: "Segment",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "company_list",
    title: "Company List",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const boardTable = [
  {
    id: "board_name",
    title: "Board Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "pins",
    title: "Pins",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_on",
    title: "Created On",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

const visualizationTable = [
  {
    id: "visualization_name",
    title: "Visualization Name",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "chart_type",
    title: "Chart Type",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_on",
    title: "Created On",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
  {
    id: "updated_at",
    title: "Last Modified At",
    sortable: false,
    style: { textAlign: "left", textTransform: "capitalize" },
    rowStyle: { textAlign: "left", textTransform: "capitalize" },
  },
];

export const DashboardTables = {
  tableHeader,
  tbrAllReportTable,
  projectTable,
  companyTable,
  reportTable,
  boardTable,
  visualizationTable,
};

