import React, { useState } from "react";
import { Link } from "react-router-dom";
import CustomReactTooltip from "./CustomReactTooltip";
import TemplateParser from "./TemplateParser";
import BoardDetailsComments from "../Containers/BoardDetailsComments";
import ComapnyDetailsDateFormatter from "../Utils/ComapnyDetailsDateFormatter";
import Collapsible from "react-collapsible";
import Slugify from "../Utils/Slugify";
import { getMappingId } from "../Helpers/Helper";

function SubCategoryItem(props) {
  const [isOpen, setIsOpen] = useState(true);
  const {
    provided,
    subcatgory,
    isUnpinAllowed,
    isCommentAddAllowed,
    isDisplayComments,
    isCommentViewAllowed,
    isCommentDeleteAllowed,
    isCommentStatusChangeAllowed,
    isCommentEditAllowed,
    parentsProps,
    hasAccess,
    isSubItemOpen,
    showMoreHandler,
  } = props;

  const handleContentDelete = (subcatgory, content) => {
    const mapping_id = getMappingId(content, content.type);
    const content_index = JSON.parse(subcatgory?.content_index);
    const checkingData = content_index.find(
      (item) =>
        parseInt(item.content_id) === parseInt(mapping_id) &&
        parseInt(item.board_pinned_id) === parseInt(content.board_pinned_id)
    );

    parentsProps.removeContentFromSubcategory(
      "remove_subcat_piece",
      subcatgory?.id,
      subcatgory?.board_id,
      checkingData
    );
  };

  return (
    <Collapsible
      open={isOpen && isSubItemOpen}
      onTriggerClosing={() => setIsOpen(false)}
      onTriggerOpening={() => setIsOpen(true)}
      trigger={
        <div className="trigger-icon">
          {hasAccess && (
            <span
              className="material-icons-outlined icon icon-fs-24 hover-button"
              style={{
                cursor: "grab",
                position: "absolute",
                margin: "12px -6px",
                color: "#E5E5E5",
              }}
              data-for="dragtooltip"
              data-tip="Drag to Move"
            >
              drag_indicator
              <CustomReactTooltip id="dragtooltip" multiline={true} />
            </span>
          )}
          <div
            id={`${Slugify(subcatgory.name)}_${subcatgory.id}`}
            className="sub_category_heading d-flex align-items-center"
            style={{ marginLeft: "18px" }}
          >
            <div className="d-flex category-tag font-weight-semibold mb-0 align-items-center">
              {subcatgory.name}
            </div>
            <div className="d-flex">
              {hasAccess && (
                <div
                  className="board-delete-icon mr-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    parentsProps.removeCategoryConfirmation(
                      "subcat",
                      subcatgory.board_id,
                      subcatgory.id
                    );
                  }}
                >
                  <span className="material-icons-outlined icon-fs-16 col-red">
                    delete
                  </span>
                </div>
              )}
              <div className="board-collapse-add-icon board-delete-icon">
                <span class="material-icons-round add icon-fs-16 col-gray">
                  add
                </span>
                <span class="material-icons-round remove icon-fs-16 col-gray">
                  remove
                </span>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {subcatgory?.content && subcatgory?.content.length > 0 ? (
        subcatgory?.content?.map((content) => {
          const typeMapping = {
            company: "company_name",
            benchmark: "benchmark_name",
            project: "project_name",
            market_landscape: "market_landscape_name",
            market_forecast: "market_forecast_name",
            special_report: "special_report_name",
            customer_study: "customer_study_name",
            ecosystem: "ecosystem_name",
          };
          const displayNameMapping = {
            company: "Company Research",
            benchmark: "Benchmark",
            market_forecast: "Market Forecast",
            market_landscape: "Market Landscape",
            special_report: "Special Report",
            customer_study: "Customer Study",
            ecosystem: "Ecosystem",
            project: "Project",
          };

          const getContentTypeName = (type) =>
            content?.[typeMapping[type]] || "";

          const getDisplayName = (type) => displayNameMapping[type] || "Report";

          const formatTextTooltip = (text) =>
            text?.length > 40 ? `${text.substring(0, 40)}...` : text || "";

          const tooltipContent = `
          Report Type: ${formatTextTooltip(
            getDisplayName(content?.type) || ""
          )}<br>
          ${
            content?.type === "company"
              ? `Company Name : ${formatTextTooltip(
                  getContentTypeName(content?.type)
                )}`
              : `Report Name : ${formatTextTooltip(
                  getContentTypeName(content?.type)
                )}`
          }<br>
          Subcategory Name : ${formatTextTooltip(
            content?.subcategory_name || ""
          )}
          `;
          return (
            <div key={content.id}>
              {content.isBlur && (
                <div className="no-permission-content">
                  <div className="blur-text">
                    <div className="lock-icon">
                      <span
                        style={{
                          color: "#fff",
                        }}
                        className="material-icons icon-fs-18"
                      >
                        lock
                      </span>
                    </div>
                    <p>You don't have permission to access this content.</p>
                  </div>
                </div>
              )}
              <div
                className={
                  content.isBlur
                    ? "col-12 noselect board-blur"
                    : "col-12 subcategory-section"
                }
              >
                <div className="d-flex justify-content-between mb-3">
                  <div
                    style={{
                      width: "75%",
                    }}
                  >
                    {content.subcategory_title && (
                      <div
                        className="desipte_sec d-flex"
                        key={content.content_id}
                      >
                        <h4 title={content.subcategory_title}>
                          {content.subcategory_title}
                        </h4>
                      </div>
                    )}
                  </div>
                  <div className="d-flex items-center">
                    <span
                      style={{
                        marginRight: "7px",
                      }}
                      className="text-grey"
                    >
                      Date :{" "}
                      {ComapnyDetailsDateFormatter(
                        content.published_date,
                        false
                      )}
                    </span>
                    <span>
                      <Link
                        data-tip={tooltipContent}
                        data-for="info-report"
                        className="material-icons icon-fs-20 mr-2 tooltip-link-board"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                          color: "#046CD9",
                        }}
                        to={{
                          pathname: content.url,
                          state: {
                            key: `#${Slugify(content?.subcategory_name)}_${
                              content?.subcategory_id
                            }`,
                            segment: content?.segment_id,
                          },
                          search:
                            content.type === "company"
                              ? "?segment=" + content?.segment_id
                              : null,
                        }}
                      >
                        {" "}
                        info
                      </Link>
                    </span>
                    <CustomReactTooltip
                      id="info-report"
                      multiline={true}
                      place="left"
                      className="text-left"
                    />
                    {hasAccess && content.isDuplicate && (
                      <div
                        className="board-delete-icon mr-3"
                        onClick={() => handleContentDelete(subcatgory, content)}
                      >
                        <span className="material-icons-outlined icon-fs-18 col-red mb-2">
                          delete
                        </span>
                      </div>
                    )}
                    {(isUnpinAllowed ||
                      (isCommentAddAllowed && isDisplayComments)) && (
                      <div
                        className={`add_to_board boards-add-to-board ${
                          content.show_actions ? "active" : ""
                        }`}
                      >
                        <div className="add_to_board_box">
                          {isUnpinAllowed && (
                            <span
                              data-testid="testRemovedBoard"
                              className={"action"}
                              onClick={() => {
                                parentsProps.removeFromBoardConfirmation(
                                  content,
                                  subcatgory.id
                                );
                              }}
                            >
                              <span className="d-inline-flex align-items-center">
                                <span className="material-icons-round icon-fs-14">
                                  close
                                </span>{" "}
                                <span className="ml-1">Remove</span>
                              </span>
                            </span>
                          )}
                          {isCommentAddAllowed && isDisplayComments && (
                            <span
                              className={"action"}
                              onClick={() => {
                                parentsProps.toggleWriteContentCommentsHandler(
                                  content.content_id
                                );
                              }}
                            >
                              <span className="d-inline-flex align-items-center">
                                <span className="material-icons-round icon-fs-14">
                                  add
                                </span>
                                <span className="ml-1">Comment</span>
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <TemplateParser
                  key={`temp-${content.content_id}`}
                  template={content.template_content}
                  contentIsBlur={content.isBlur}
                  content={content.data}
                />
              </div>
              {!content.isBlur &&
                (parentsProps.isReactTest ||
                  (isCommentViewAllowed && isDisplayComments)) && (
                  <div className={"col-sm-12 pl-3 comment-margin"}>
                    <BoardDetailsComments
                      id={content.content_id}
                      comments={content.comments ? content.comments : []}
                      writeComments={isCommentAddAllowed}
                      openCommentsFlag={
                        content.openCommentsFlag
                          ? content.openCommentsFlag
                          : false
                      }
                      toggleCommentsHandler={
                        parentsProps.toggleContentCommentsHandler
                      }
                      writeCommentsFlag={
                        content.writeCommentsFlag
                          ? content.writeCommentsFlag
                          : false
                      }
                      toggleWriteCommentsHandler={
                        parentsProps.toggleWriteContentCommentsHandler
                      }
                      handleCommentChangeHandler={
                        parentsProps.handleContentCommentChangeHandler
                      }
                      handleCommentTypeChangeHandler={
                        parentsProps.handleContentCommentTypeChangeHandler
                      }
                      comment={content.comment}
                      is_public={content.is_public}
                      handleCommentSaveHandler={
                        parentsProps.handleContentCommentSaveHandler
                      }
                      showMore={true}
                      toggleCommentTypeDropdown={
                        parentsProps.toggleContentCommentTypeDropdown
                      }
                      toggleCommentType={parentsProps.toggleContentCommentType}
                      isCommentViewAllowed={isCommentViewAllowed}
                      isCommentAddAllowed={isCommentAddAllowed}
                      isCommentEditAllowed={isCommentEditAllowed}
                      isCommentDeleteAllowed={isCommentDeleteAllowed}
                      isCommentStatusChangeAllowed={
                        isCommentStatusChangeAllowed
                      }
                      showMoreHandler={showMoreHandler}
                      totalCount={content.comments.length}
                      type="content"
                      handleOpenEditComment={
                        parentsProps.handleOpenEditTopCommentContent
                      }
                      handleCommentChange={
                        parentsProps.handleTopCommentChangeContent
                      }
                      handleDeleteComment={
                        parentsProps.handleDeleteCommentGeneral
                      }
                      handleCloseEditComment={
                        parentsProps.handleCloseEditTopCommentContent
                      }
                      handleSaveEditComment={
                        parentsProps.handleSaveEditTopCommentContent
                      }
                    />
                  </div>
                )}
            </div>
          );
        })
      ) : (
        <div className="no-content-found">No content has found.</div>
      )}
    </Collapsible>
  );
}

export default SubCategoryItem;
