import React, { useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ReactTooltip from "react-tooltip";
import DataNotAvailable from "../Pages/SvgIcons/ReportNotFound.svg";
const chartColors = [
  "#77C3FE",
  "#011E3C",
  "#003870",
  "#09579E",
  "#1678CF",
  "#239DFF",
];

const mapToVendorType = (typeName) => {
  switch(typeName) {
    case "company_research_analytics":
        return "vendor_reports_analytics";
    case "company_profile_analytics":
        return "vendor_profile_analytics";
    case "company_snapshot_analytics":
        return "vendor_snapshot_analytics";
    default:
        return typeName; 
  }
};
const ChildPieChartDashboard2 = ({
  selectedSlice,
  data,
  setState,
  heading,
  heading2,
  setLoader2,
}) => {
  var selectedSliceIsEmpty = false;
  const [loader, setLoader] = useState(true);
  let sliceName = selectedSlice.toLowerCase().replace(" ", "_");
  const modifiedChartData = () => {
    let chartDataArray = [];
    let typeName = sliceName + "_analytics";
    let retypeName = mapToVendorType(typeName)
    Object.keys(data).forEach((item) => {
      if (item == retypeName) {
        if (data[item].length === 0) {
          selectedSliceIsEmpty = true;
        }
        data[item].forEach((dataItem, index) => {
          let chartDataObject = {};
          let name = Object.keys(dataItem);
          let value = Object.values(dataItem);
          chartDataObject["name"] = name[0];
          chartDataObject["value"] = value[0];
          chartDataObject["color"] = am4core.color(`${chartColors[index]}`);
          chartDataObject["legendColor"] = chartColors[index];
          chartDataArray.push(chartDataObject);
        });
      }
    }); 
    return chartDataArray;
  };
  let chartData = modifiedChartData();

  const initializeChartData = () => {
    let chartDiv = document.getElementById(sliceName);
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(chartDiv, am4charts.PieChart);
    chart.data = modifiedChartData();
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";

    pieSeries.slices.template.events.on("hit", function (event) {});

    pieSeries.slices.template.cursorOverStyle =
      am4core.MouseCursorStyle.pointer;
    pieSeries.ticks.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");
    pieSeries.labels.template.fontSize = 14;
    pieSeries.labels.template.fontWeight = 500;
    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.labels.template.adapter.add("radius", function (radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0;
      }
      return radius;
    });

    pieSeries.labels.template.adapter.add("fill", function (color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color("#000");
      }
      return color;
    });

    pieSeries.slices.template.events.on("over", function (event) {
      let currentSlice = event.target;
      let currentSliceName = event.target.dataItem.properties.category;
      let currentCount = event.target.dataItem.values.value.value;
      let currentPercent =
        event.target.dataItem.values.value.percent.toFixed(2);
      currentSlice.tooltipText = `${currentSliceName} | Views: [medium]${currentCount} (${currentPercent}%)`;
    });
  };
  useEffect(() => {
    initializeChartData();
    setTimeout(() => {
      setLoader(false);
    }, 300);
  }, [selectedSlice, data, loader]);

  const goBack = () => {
    setState("");
    setLoader2(true);
  };
  return (
    <div className="">
      {/* <h4 className="pie-chart-head">
        {heading} / <span className="pie-chart-head-2">{heading2}</span> /{" "}
        <span className="pie-chart-head-3">{selectedSlice}</span>
      </h4> */}
      <div className="d-flex">
        <button onClick={() => goBack()} className="dashboard-back-btn">
          <span data-tip data-for="child-info" className="material-icons mt-1">
            keyboard_double_arrow_left
          </span>
          <ReactTooltip id="child-info" place="right" effect="solid">
            Click to Drill Up the Data
          </ReactTooltip>
        </button>
        <div className="pie-info">
          <span
            data-tip
            data-for="parent-info"
            className="material-icons icon-fs-20"
          >
            info
          </span>
          <ReactTooltip id="parent-info" place="right" effect="solid">
            Do not Drill Down
          </ReactTooltip>
        </div>
      </div>
      {loader ? (
        <div class="lds-hourglass"></div>
      ) : (
        <div className="d-flex">
          {selectedSliceIsEmpty ? (
            <div className="data-not-found">
              <img src={DataNotAvailable} alt="" />
              <p>No {selectedSlice} data found yet.</p>
            </div>
          ) : (
            <div
              style={{ width: "100%", height: "380px", marginLeft: "50px" }}
              id={sliceName}
            ></div>
          )}
          {selectedSliceIsEmpty ? (
            ""
          ) : (
            <div className="d-flex flex-column justify-content-center pie-chart-report-card">
              <h3 style={{ color: "#5e5e5e", fontWeight: "600" }}>
                {selectedSlice}
              </h3>
              <div
                style={{
                  height: "0.5px",
                  background: "#727272",
                  width: "100%",
                }}
              ></div>
              {chartData?.map((chart) => (
                <div
                  key={chart.color}
                  style={{ height: "30px", marginTop: "10px" }}
                  className="d-flex align-items-center"
                >
                  <div
                    className="pie-legend-dots"
                    style={{ backgroundColor: chart.legendColor }}
                  ></div>
                  <p data-tip data-for={chart.name} className="legend-text">
                    {chart.name.length <= 22
                      ? chart.name
                      : `${chart.name.slice(0, 19)}...`}
                  </p>
                  <ReactTooltip id={chart.name} place="top" effect="solid">
                    {chart.name}
                  </ReactTooltip>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChildPieChartDashboard2;
