import { combineReducers } from 'redux'
import * as DETAILS_REF from './reducerReference/marketForecastDetailsReference'

const marketForecastCategories = DETAILS_REF.marketForecastCategories
const marketForecastDetails = DETAILS_REF.marketForecastDetails
const categories = DETAILS_REF.categories
const marketForecastContent = DETAILS_REF.marketForecastContent


const marketForecastDetailsReducer = combineReducers({
    marketForecastCategories,
    marketForecastDetails,
    categories,
    marketForecastContent
})

export default marketForecastDetailsReducer
