import constants from "../Utils/constants";

function EllipsisForModel(message, maxLength) {
  const minTextLength = maxLength || constants.ELLIPSIS.TEXT_MAX_LENGTH;
  return {
    text:
      message?.length > minTextLength
        ? `${message.substring(0, minTextLength)}...`
        : message,
    ellipsis: message?.length > minTextLength,
    fullText: message,
  };
}

export default EllipsisForModel;
