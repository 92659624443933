import React, { useRef, useEffect } from 'react'
import constants from '../Utils/constants'
import CustomReactTooltip from '../Components/CustomReactTooltip'
import Slugify from '../Utils/Slugify'

const AddBoardDetailsComment = (props) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start'
    })
    inputRef.current.focus();
  }, [])

  return (
    <>
      <div className="txtComments-wrapper" >
        <textarea
          id='TestIdComment'
          className="form-control txtComments"
          placeholder="Write your comments here..."
          rows="4"
          onChange={(e) => { props.handleCommentChangeHandler(e, props.id) }}
          value={props.comment}
          ref={inputRef}
        >
        </textarea>
      </div>
      <div className='send-comment-block'>
        <div className='d-flex align-items-center'>
          <div className="btn-group btn-group-toggle custom-btn-group-toggle d-inline-flex align-items-center" data-toggle="buttons">
            <label
              className={`btn left-btn btn-xs d-inline-flex ${(props.is_public === 1) ? 'active' : ''}`}
              data-for={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.PUBLIC_TOOLTIP)}`}
              data-tip={constants.MY_BOARDS.COMMENT.PUBLIC_TOOLTIP}
              data-iscapture="true"
            >
              <span className="material-icons icon text-white d-inline-flex icon-fs-20">
                public
              </span>
             
              <input
                type="radio"
                name="options"
                id="public"
                value={1}
                autocomplete="off"
                onChange={(e) => { props.handleCommentTypeChangeHandler(e, props.id, 1) }}
                checked={props.is_public === 1}
              />
            </label>
            <label
              className={`btn left-btn btn-xs d-inline-flex ${props.is_public === 0 ? 'active' : ''}`}
              data-for={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.PRIVATE_TOOLTIP)}`}
              data-tip={constants.MY_BOARDS.COMMENT.PRIVATE_TOOLTIP}
              data-iscapture="true"
            >
            
              <span className="material-icons-round icon text-white d-inline-flex icon-fs-20">
                lock
              </span>
              <input
                type="radio"
                name="options"
                id="private"
                value={0}
                autocomplete="off"
                onChange={(e) => { props.handleCommentTypeChangeHandler(e, props.id, 0) }}
                checked={props.is_public === 0}
              />
            </label>
          </div>
          <CustomReactTooltip
            id={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.PRIVATE_TOOLTIP)}`}
            multiline={true}
          />
          <CustomReactTooltip
            id={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.PUBLIC_TOOLTIP)}`}
            multiline={true}
          />
          <CustomReactTooltip
            id={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.POST_BUTTON_TITLE)}`}
            multiline={true}
          />
          
          <div className="d-flex align-items-center ml-auto send-btn-group cursor-pointer"
            data-for={`tooltip-${Slugify(constants.MY_BOARDS.COMMENT.POST_BUTTON_TITLE)}`}
            // data-tip={constants.MY_BOARDS.COMMENT.POST_BUTTON_TITLE}
            data-iscapture="true"
            onClick={(e) => { props.handleCommentSaveHandler(e, props.id) }}
            disabled={!(props.comment && props.comment.trim().length > 0)}
          >
          
            <h3 className="text-white mb-0 font-weight-semibold">Send</h3>
            <button
              type="button"
              className="btn btn-xs ml-1 d-inline-flex"
            >
              <span className="material-icons text-white icon">
                send
              </span>
            
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddBoardDetailsComment
