import React from "react";
import CustomReactTooltip from "../../../../Components/CustomReactTooltip";
import CustomCheckBox from "../CustomCheckBox";

function GeoVerticalSection({
  disableGroVerticalBox,
  showGeo,
  setShowGeo,
  verticalList,
  selectedVertical,
  setSelectedVertical,
  expandedVarticals,
  setExpandedVarticals,
  geoList,
  selectedGeo,
  setSelectedGeo,
  expandedGeos,
  setExpandedGeos,
  setDisableGroVerticalBox,
  setDisableMetricsBox,
  createRules,
  setGeoList,
  setVerticalList,
  paramId,
}) {
  return (
    <div
      className="col-md-3 select-metric-block"
      style={{
        backgroundColor: disableGroVerticalBox
          ? "rgba(255, 255, 255, 0.5)"
          : "rgba(243, 243, 243, 0.5)",
        cursor: disableGroVerticalBox ? "pointer" : "not-allowed",
        // opacity: disableGroVerticalBox ? 1 : 0.5,
      }}
    >
      <ul className="block-title metric-ul">
        <li>
          <span
            style={{ cursor: "pointer" }}
            data-tip="Select a Industry"
            data-for="Industry"
            className={`${
              !showGeo ? "active" : "text-black"
            } material-icons-outlined btn-metric`}
            onClick={() => {
              setShowGeo(false);
            }}
          >
            {/* insights */}
            domain
            {/* <img src={MetricSvg}/> */}
          </span>
          <CustomReactTooltip id="Industry"></CustomReactTooltip>
        </li>
        <li>
          <span
            style={{ cursor: "pointer" }}
            data-tip="Select a Geo Location"
            data-for="Geo Location"
            className={`${
              showGeo ? "active" : "text-black"
            } material-icons-outlined btn-metric`}
            onClick={() => {
              setShowGeo(true);
            }}
          >
            {/* <img src={WorldSvg} className="worldsvg"/> */}
            language
          </span>
          <CustomReactTooltip id="Geo Location"></CustomReactTooltip>
        </li>
      </ul>
      {!showGeo ? (
        <div className="segments-tree custom-checkbox-tree">
          <CustomCheckBox
            nodes={verticalList}
            checked={selectedVertical}
            is_data={true}
            onCheck={(checked) => setSelectedVertical(checked)}
            key={"Vertical"}
            expanded={expandedVarticals}
            onExpand={(expanded) => setExpandedVarticals(expanded)}
            disabled={!disableGroVerticalBox}
            width={"270px"}
          />
        </div>
      ) : (
        <div className="segments-tree custom-checkbox-tree">
          <CustomCheckBox
            nodes={geoList}
            checked={selectedGeo}
            is_data={true}
            onCheck={(checked) => setSelectedGeo(checked)}
            key={"Geos"}
            expanded={expandedGeos}
            onExpand={(expanded) => setExpandedGeos(expanded)}
            disabled={!disableGroVerticalBox}
            width={"220px"}
          />
        </div>
      )}
      {disableGroVerticalBox && (
        <div
          style={{
            // position: "absolute",
            bottom: "0%",
            height: "30px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0 10 0 10",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "#FBFBFB",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
            }}
            onClick={() => {
              if (showGeo) {
                setShowGeo(false);
                if (!paramId) {
                  setExpandedGeos([]);
                  setSelectedGeo([]);
                }
                setGeoList([]);
              } else {
                setDisableGroVerticalBox(false);
                setDisableMetricsBox(true);
                if (!paramId) {
                  setExpandedVarticals([]);
                  setSelectedVertical([]);
                }
                setVerticalList([]);
              }
            }}
          >
            <span
              className="material-icons-outlined add-icon"
              // data-testid={`checkbox-arrow-segment`}
              style={{ color: "#606B77", fontSize: "18px", width: "18px" }}
            >
              arrow_backward
            </span>
            <span
              style={{ fontSize: "14px", fontWeight: 400, color: "#606B77" }}
            >
              Back
            </span>
          </div>
          <div
            style={{
              width: "50%",
              backgroundColor: "#DCEDFF",
              height: "inherit",
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
              gap: "10px",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              showGeo ? createRules() : setShowGeo(true);
            }}
          >
            <span
              style={{ fontSize: "14px", fontWeight: 400, color: "#2E6AD2" }}
            >
              {!showGeo ? "Next" : "Add"}
            </span>
            <span
              className="material-icons-outlined add-icon"
              style={{ color: "#2E6AD2", fontSize: "18px" }}
            >
              arrow_forward
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default GeoVerticalSection;
