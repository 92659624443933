import React, { Component } from 'react'
import { connect } from 'react-redux'
import { categories } from '../Store/Actions/MarketLandscapeDetails'
import Slugify from '../Utils/Slugify'
import gotoSection from '../Utils/ScrollToTop'
import Scrollspy from 'react-scrollspy'
import Collapsible from 'react-collapsible';

class MarketLandscapeDetailsCategories extends Component {
  toggleCheckbox = (e, type, category_id, sub_category_id) => {
    let original_categories = [...this.props.market_landscape_details.categories]
    let checkbox_selection = e.target.checked
    if (type === 'category') {
      let cat = original_categories.findIndex((c) => c.id === category_id)
      original_categories[cat].is_selected = checkbox_selection
      if (original_categories[cat].subcategories) {
        original_categories[cat].subcategories.forEach((res_sub) => {
          res_sub.is_selected = checkbox_selection
        })
      }
     
    }
    else if (type === 'sub_category') {
      original_categories.forEach((res) => {
        if (res.id === category_id) {
          if (res.subcategories) {
            res.subcategories.forEach((res_sub) => {
              if (res_sub.id === sub_category_id)
                res_sub.is_selected = checkbox_selection
            })
            let total = res.subcategories.length
            let total_selected = res.subcategories.filter(row => row.is_selected === true).length
            if (total_selected === total && total > 0) {
              res.is_selected = true
            }
            else {
              res.is_selected = false
            }
          }
        }
      })
    }
    this.props.categories(original_categories)
  }

  handleCategorySelection = (name, cat_id, sub_cat_id) => {
    setTimeout(() => {
      if (sub_cat_id) {
        gotoSection(`#${Slugify(name)}_${sub_cat_id}`)
      } else {
        gotoSection(`#${Slugify(name)}`)
      }
    }, 500)

    if (cat_id) {
      let cat = this.props.market_landscape_details.categories.findIndex((c) => c.id === cat_id)
      this.props.market_landscape_details.categories[cat].is_open = true
      this.props.categories([...this.props.market_landscape_details.categories])
    }
  }


  render() {
    let marketLandscapeContent = (this.props.market_landscape_details && this.props.market_landscape_details.marketLandscapeContent) ? this.props.market_landscape_details.marketLandscapeContent : []
    return (
      this.props.market_landscape_details && this.props.market_landscape_details.categories && this.props.market_landscape_details.categories.length > 0 &&
      <aside className="inner-sidebar" >
        <div className="sidebar_nav2 d-flex flex-column h-100">
          <div className="d-flex icon-wrapper">
            <span className="material-icons-outlined icon" onClick={this.props.toggleMarketlandscapeInnerSidebar}>
              keyboard_double_arrow_left
            </span>
          </div>
          <div className="opened-innersidebar-content">
            <div className="custom-collapser mt-1 inner-sidebar-collapser">
              {
                this.props.market_landscape_details.categories.map((category, index) =>
                  marketLandscapeContent.length > 0 && marketLandscapeContent.find((cc) => cc.category_id === category.id) &&
                  <div className="mb-1" key={category.id}>
                    {
                      marketLandscapeContent.length > 0 &&
                      <Scrollspy items={[`${Slugify(category.name)}_${category.id}`]} currentClassName="is-current-parent" >
                        {marketLandscapeContent.find((cc) => cc.category_id === category.id) &&
                          <div className="custom-collapser-item">
                            <Collapsible open={true}
                              trigger={<>

                                <a style={{ display: 'none' }} href={`#${Slugify(category.name)}_${category.id}`} >{category.name}</a>
                                <div className="d-flex align-items-center ml-auto">
                                  <span className="material-icons-outlined material-icon">chevron_right</span>
                                </div>

                              </>}
                              triggerSibling={() => (
                                <div className="d-flex align-items-center trigger-block">
                                  <input className="custom-checkbox-new" type="checkbox" name={category.name} id={Slugify(category.name)} checked={category.is_selected} onChange={(e) => { e.stopPropagation(); this.toggleCheckbox(e, 'category', category.id) }} />
                                  <h5 className="font-weight-semibold mb-0 title"
                                 
                                  >
                                    <span className="ellipsis" title={category.name}>
                                      {category.name}
                                    </span>
                                  </h5>
                                </div>
                              )}
                            >

                              <div className="collapse-panel-content" key={category.id}>
                                <Scrollspy items={category.subcategories.map((s) => `${Slugify(s.name)}_${s.id}`)} currentClassName="is-current-child">
                                  {category.subcategories.map((sub_category, index_sub_cat) =>
                                    marketLandscapeContent.find((cc) => cc.subcategory_id === sub_category.id) &&
                                    <div className="item d-flex align-items-center" key={sub_category.id}>
                                      <input className="custom-checkbox-new" key={sub_category.id} type="checkbox" name={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} id={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} checked={sub_category.is_selected} onChange={(e) => { this.toggleCheckbox(e, 'sub_category', category.id, sub_category.id) }} />
                                      <label className='sub-category-label mb-0'
                                        
                                        onClick={() => this.handleCategorySelection(sub_category.name, category.id, sub_category.id)}
                                      >
                                        <span className="ellipsis">
                                          {sub_category.name}
                                        </span>
                                      </label>
                                      <a style={{ display: 'none' }} href={`#${Slugify(sub_category.name)}_${sub_category.id}`} >{sub_category.name}</a>
                                    </div>
                                  )}
                                </Scrollspy>
                              </div>

                            </Collapsible>
                          </div>
                        }
                      </Scrollspy>
                    }
                    {
                      marketLandscapeContent.length === 0 &&
                      <>
                        <li className="active">
                          <input className="custom-checkbox-new" type="checkbox" name={category.name} id={Slugify(category.name)} checked={category.is_selected} onChange={(e) => { e.stopPropagation(); this.toggleCheckbox(e, 'category', category.id) }} />
                          <label htmlFor={Slugify(category.name)}><span className='ellipsis' title={category.name}>{category.name}</span></label>
                          <a style={{ display: 'none' }} href={`#${Slugify(category.name)}_${category.id}`} >{category.name}</a>
                          <ul className="checkbox_list" key={category.id}>
                            {category.subcategories.map((sub_category, index_sub_cat) =>
                              <li key={sub_category.id}>
                                <input className="custom-checkbox-new" key={sub_category.id} type="checkbox" name={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} id={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)} checked={sub_category.is_selected} onChange={(e) => { this.toggleCheckbox(e, 'sub_category', category.id, sub_category.id) }} />
                                <label className='sub-category-label' htmlFhtmlFor={Slugify(category.name) + '_' + Slugify(sub_category.name) + '_' + (sub_category.id)}>
                                  <span className='ellipsis' title={sub_category.name}>{sub_category.name}</span>
                                </label>
                                <a style={{ display: 'none' }} href={`#${Slugify(sub_category.name)}_${sub_category.id}`} >{sub_category.name}</a>
                              </li>
                            )}
                          </ul>
                        </li>
                      </>
                    }
                  </div>
                )
              }
            </div>
          </div>
          <div className="closed-innersidebar-content d-flex flex-grow-1">
            <div className="content text-center font-weight-semibold">
              CONTENTS
            </div>
          </div>
        </div>
      </aside>
    )
  }
}

const mapStateToProps = state => {
  return {
    market_landscape_details: state.market_landscape_details
  }
}

const mapDispatchToProps = dispatch => {
  return {
    categories: (payload) => {
      dispatch(categories(payload))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketLandscapeDetailsCategories)
