import { combineReducers } from "redux";
import * as DETAILS_REF from "./reducerReference/notification";

const notifications = DETAILS_REF.fetchNotification;

const notificationReducer = combineReducers({
  notifications,
});

export default notificationReducer;
