import * as XLSX from "xlsx";

export function getFullYear(yearString) {
  // const ignoreString = isAlphabetString(yearString);
  // if (ignoreString) return;
  // // Check if the year is in a two-digit format (e.g., "20"), and if so, convert it to a full year.
  // const currentYear = new Date().getFullYear();
  // return parseInt(currentYear.toString().slice(0, 2) + yearString);

  const ignoreString = isAlphabetString(yearString);
  if (ignoreString) return;

  // Convert the two-digit year to a number
  const yearNumber = parseInt(yearString, 10);

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Determine the full year based on the cutoff year
  const cutoffYear = 30; // For example, if the cutoff year is 30, 30 and below will be interpreted as 2000s, and above 30 will be interpreted as 1900s
  return yearNumber <= cutoffYear ? 2000 + yearNumber : 1900 + yearNumber;
}

export function breakString(str) {
  const quarter = str.substring(0, 1); // Extract the first character for quarter
  const year = str.substring(1); // Extract the remaining characters for year
  return { quarter, year };
}

function isAlphabetString(inputString) {
  return /^[a-zA-Z]+$/.test(inputString);
}

export function parseCssToObj(cssText) {
  const cssObj = {};
  const rules = cssText.split(";");

  rules.forEach((rule) => {
    const [property, value] = rule.split(":");
    if (property && value) {
      cssObj[property.trim()] = value.trim();
    }
  });

  return cssObj;
}

export function generateXaxies(data) {
  let temp = [];
  for (let i in data) {
    temp.push(data[i].data.filter((ie) => ie.qy && ie.value).map((e) => e.qy));
  }

  return [...new Set(temp.flat())].sort((a, b) => {
    // Extract year and quarter
    let [aQuarter, aYear] = a.split("Q").map(Number);
    let [bQuarter, bYear] = b.split("Q").map(Number);

    // Compare years first
    if (aYear !== bYear) {
      return aYear - bYear;
    }

    // If years are the same, compare quarters
    return aQuarter - bQuarter;
  });
}

export function downloadFile(url) {
  const newWindow = window.open(url, "_self", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
}

export function sortData(data) {
  return data.sort((a, b) => {
    // Extract year and quarter
    let [aQuarter, aYear] = a.qy.split("Q").map(Number);
    let [bQuarter, bYear] = b.qy.split("Q").map(Number);

    // Compare years first
    if (aYear !== bYear) {
      return aYear - bYear;
    }

    // If years are the same, compare quarters
    return aQuarter - bQuarter;
  });
}

export function filterQYDataByRange(
  data,
  startYear,
  startQuarter,
  endYear,
  endQuarter
) {
  return data.map((dataset) => {
    return {
      ...dataset,
      data: dataset.data.filter((item) => {
        return (
          (item.year > startYear ||
            (item.year === startYear && item.quarter >= startQuarter)) &&
          (item.year < endYear ||
            (item.year === endYear && item.quarter <= endQuarter))
        );
      }),
    };
  });
}

export function filterYearDataByRange(data, startYear, endYear) {
  return data.map((dataset) => {
    return {
      ...dataset,
      data: dataset.data.filter((item) => {
        return (
          (item.year > startYear || item.year === startYear) &&
          (item.year < endYear || item.year === endYear)
        );
      }),
    };
  });
}

export function downloadExcelFile(filetitle, filename, data, columns) {
  // Create a worksheet from the filtered data
  const ws = XLSX.utils.json_to_sheet(data, {
    header: columns.map((col) => col.accessor),
  });
  // Create a new workbook and append the worksheet
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, filetitle);
  // Generate the XLSX file and trigger the download
  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export const getMappingId = (content, type) => {
  let column = "id";
  switch (type) {
    case "company":
      column = "company_segment_id";
      break;
    case "benchmark":
      column = "benchmark_id";
      break;
    case "market_forecast":
      column = "market_forecast_id";
      break;
    case "customer_study":
      column = "customer_study_id";
      break;
    case "ecosystem":
      column = "ecosystem_id";
      break;
    case "market_landscape":
      column = "market_landscape_id";
      break;
    case "project":
      column = "project_id";
      break;
    case "special_report":
      column = "special_report_id";
      break;
  }
  return content[column];
};

// Helper function to ensure unique names for chart
export const makeUniqueSeriesNames = (series) => {
  const nameCount = {};

  return series.map((item) => {
    const baseName = item.name;

    // Check if name already exists, and increment the counter
    if (nameCount[baseName]) {
      nameCount[baseName]++;
      return {
        ...item,
        name: `${baseName} (${nameCount[baseName]})`, // Make the name unique by appending a number
      };
    } else {
      nameCount[baseName] = 1;
      return item; // If no duplicates, keep the original name
    }
  });
};