import { market_landscape_details as DETAILS } from '../Reducers/actionConstants'

const marketLandscapeCategories = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_CATEGORIES,
    payload,
  }
}


const marketLandscapeDetails = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_DETAILS,
    payload,
  }
}

const categories = (payload) => {
  return {
    type: DETAILS.CATEGORIES,
    payload,
  }
}

const marketLandscapeContent = (payload) => {
  return {
    type: DETAILS.MARKET_LANDSCAPE_CONTENT,
    payload,
  }
}


export {
  marketLandscapeCategories,
  marketLandscapeDetails,
  categories,
  marketLandscapeContent
}
